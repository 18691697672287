import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthRequest } from 'src/app/modules/AuthRequest';
import { PG } from 'src/app/modules/PGModel';
import { Apiservice } from 'src/app/service/api/apiservice.service';

@Component({
  selector: 'app-create-pg',
  templateUrl: './create-pg.component.html',
  styleUrls: ['./create-pg.component.scss']
})
export class CreatePgComponent {

  constructor(
    private apiService: Apiservice,
    private router: Router,
    private toastr: ToastrService
  ) {}

  onSubmit(createPGForm: NgForm) {
    const { 
      pgName, 
      pgUserName,
      phoneNumber,
      mail,
      state,
      dist,
      pinCode,
      category,
      aboutPg 
    } = createPGForm.value;
    const createPG = new PG(pgName, pgUserName, phoneNumber, mail, state, dist, pinCode, category, aboutPg);

    this.apiService.create(createPG)
      .subscribe({
        next: (response) => this.handleLoginSuccess(response),
        error: (error) => this.handleLoginError()
      });
  }

  private handleLoginSuccess(response: any) {
    localStorage.setItem("token", response.token)
    this.router.navigate(['/login']);
  }

  private handleLoginError() {
    this.router.navigate(['/forbidden']);
  }
}
