<app-topnav></app-topnav>
<div class="spacer" style="padding: 5px;"></div>

<div class="container mt-5">
  <div class="card">
    <form #loginForm="ngForm" (ngSubmit)="onSubmit(loginForm)">
      <input type="text" ngModel name="mail" id="mail" placeholder="Enter Username/mail" class="form-control mb-3" required>
      <mat-error *ngIf="loginForm.controls['mail'].invalid && loginForm.controls['mail'].touched">
        Username/Mail is required.
      </mat-error>
    
      <input type="password" ngModel name="password" id="password" placeholder="Enter Password" class="form-control mb-3" required>
      <mat-error *ngIf="loginForm.controls['password'].invalid && loginForm.controls['password'].touched">
        Password is required.
      </mat-error>
    
      <input type="text" ngModel name="pgName" id="pgName" placeholder="Enter PG Username" class="form-control mb-3" required>
      <mat-error *ngIf="loginForm.controls['pgName'].invalid && loginForm.controls['pgName'].touched">
        PG Username is required.
      </mat-error>
    
      <div class="row">
        <div class="col">
          <input type="submit" value="Login" class="btn btn-outline-primary form-control">
        </div>
        <div class="col">
          <input type="button" value="Sign Up" routerLink="/signUp" class="btn btn-outline-secondary form-control">
        </div>
      </div>
    </form>
  </div>
</div>


<div *ngIf="isModalOpen">
  <div class="custom-snackbar">
      <mat-snack-bar-container></mat-snack-bar-container>
  <!-- <app-modal-dialog (closeModal)="closeModal()"></app-modal-dialog> -->
  </div>
</div>  