import { Component } from '@angular/core';

@Component({
  selector: 'app-disable',
  templateUrl: './disable.component.html',
  styleUrls: ['./disable.component.scss']
})
export class DisableComponent {

}
