<app-topnav></app-topnav>
<!-- <div class="loader-overlay" *ngIf="isLoading">
    <div class="loader-content">
        <mat-spinner diameter="50"></mat-spinner>
        <br>
        <h3>Please wait, we're finding Best for You...</h3>
    </div>
</div> -->

<div class="spacer"></div>

<div class="con with-background">
    
    <mat-card class="mat-card-2 mat-section effect">
        <div class="animation-text">
            <span class="capitalized">PG</span>
            <span class="capitalizedM">M</span>
            <span class="small">a</span>
            <span class="small">s</span>
            <span class="small">t</span>
            <span class="small">e</span>
            <span class="small">r</span>
        </div>

        <div class="animation-text-2">
            <span class="capitalized">MAKE YOUR SPACE SMARTER WITH PG MASTER</span>
            <div class="line-wrapper">
                <mat-progress-bar mode="buffer"></mat-progress-bar>
            </div>
        </div>
    </mat-card> 

    <div class="spacer"></div>

    <mat-card class="mat-card-1 corner-colors" *ngIf="!isLoading && !noDataAvailable">            
        
        <div class="loader-overlay" *ngIf="isLoading">
            <div class="loader-content">
                <mat-spinner diameter="50"></mat-spinner>
                <br>
                <h3>Please wait, we're finding Best for You...</h3>
            </div>
        </div>
        
        <div class="search-container">
            <!-- <mat-form-field style="width: 400px;">
                <mat-label>Let's Explore PG...</mat-label>
                <mat-icon matPrefix>search</mat-icon>
                <input class="search-bar" matInput (keyup)="applyFilterAndSort($event)" placeholder="Ex. Lakshmi PG, Pune" #input>
                <mat-hint>Search by full name for better result Ex: Lakshmi PG Pune</mat-hint>
            </mat-form-field> -->

            <mat-form-field class="search-bar" appearance="outline">
                <mat-label>Search</mat-label>
                <input matInput (keyup)="applyFilterAndSort($event)" placeholder="Ex: Lakshmi PG Pune" #input>
                <span matSuffix style="padding-right: 10px;">
                  <mat-icon>search</mat-icon>
                </span>
                <mat-hint class="search-hint">Search by full name for better result Ex: Lakshmi PG Pune</mat-hint>
            </mat-form-field>
        </div>
    
        <div class="card-grid-container" *ngIf="!isLoading && !noDataAvailable && !showNoResultsMessage">
            <mat-card *ngFor="let row of filteredResults.length > 0 ? filteredResults : dataSource.data"
                class="mat-elevation-z2 mat-card">

                <div class="scrollable-row">
                    <mat-chip-set>
                      <mat-chip *ngFor="let tag of fetchIndividualTags(row.hashTags)">{{ tag }}</mat-chip>
                    </mat-chip-set>
                </div>                  

                <mat-card-header>
                    <div class="pic">
                        <div mat-card-avatar class="user-circle">
                            <img [src]="row.photo1 + '.jpg'" alt="User Profile Picture">
                        </div>
                    </div>
                    <div class="sub">
                        <mat-card-title class="title">{{ row.pgName | uppercase }} | {{ row.category | uppercase }} </mat-card-title>
                        <mat-card-subtitle>{{ row.dist | uppercase }}, {{ row.state | uppercase }}, {{ row.pinCode
                            }}</mat-card-subtitle>
                    </div>
                </mat-card-header>
    
                <div class="icon-container" (click)="openPopup(row.id)">
                    <a [routerLink]="'/' + row.id">
                        <mat-icon class="eye-icon" style="color: gray;">visibility</mat-icon>
                    </a>
                </div>
            </mat-card>
        </div>
    
        <!-- if there is no data then show this message -->
        <div class="no-data-message" *ngIf="!isLoading && noDataAvailable">
            <p>There is No Data to be Shown!</p>
        </div>
    
        <div class="no-data-message" *ngIf="showNoResultsMessage">
            <p>There is No Data to be Shown for this!</p>
        </div>
    
        <div class="no-data-message" *ngIf="showNoResultsMessage && filteredResults.length == 0">
            <p>Search result Not Found</p>
        </div>
    </mat-card>
</div>

<app-footer *ngIf="!isLoading"></app-footer>