import { Component } from '@angular/core';

interface PanelStates {
  foodMenuPanelOpenState: boolean;
  foodTokenSummaryPanelOpenState: boolean;
  foodSummaryPanelOpenState: boolean;
}

@Component({
  selector: 'app-food-section',
  templateUrl: './food-section.component.html',
  styleUrls: ['./food-section.component.scss']
})
export class FoodSectionComponent {

  panelStates: PanelStates = {
    foodMenuPanelOpenState: false,
    foodTokenSummaryPanelOpenState: false,
    foodSummaryPanelOpenState: false,
  };

  foodMenuPanelOpenState = false;
  foodTokenSummaryPanelOpenState = false;
  foodSummaryPanelOpenState = false;

  togglePanel(panelName: string): void {
    if (panelName === 'foodPanel') {
      this.foodMenuPanelOpenState = !this.foodMenuPanelOpenState;
      this.foodTokenSummaryPanelOpenState = false;
      this.foodSummaryPanelOpenState = false;
    } else if (panelName === 'foodTokenPanel') {
      this.foodMenuPanelOpenState = false;
      this.foodTokenSummaryPanelOpenState = !this.foodTokenSummaryPanelOpenState;
      this.foodSummaryPanelOpenState = false;
    } else if (panelName === 'foodSummaryPanel') {
      this.foodMenuPanelOpenState = false;
      this.foodTokenSummaryPanelOpenState = false;
      this.foodSummaryPanelOpenState = !this.foodSummaryPanelOpenState;
    }
  }
}
