import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { interval } from 'rxjs';
import { Apiservice } from 'src/app/service/api/apiservice.service';
import { ElecBillGeneratorComponent } from '../common/elec-bill-generator/elec-bill-generator.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  constructor(
    private apiService: Apiservice,
    private router: Router,
    private toastr: ToastrService,
    public dialog: MatDialog
  ) {}

  roomsData: { roomName: string, electricityUsage: number, sharing: number }[] = [
    { roomName: 'A-1', electricityUsage: 0 , sharing: 2},
    { roomName: 'A-2', electricityUsage: 0 , sharing: 2},
    { roomName: 'A-3', electricityUsage: 0 , sharing: 2},
    { roomName: 'A-4', electricityUsage: 0 , sharing: 2},
    { roomName: 'A-5', electricityUsage: 0 , sharing: 2},
    { roomName: 'A-6', electricityUsage: 0 , sharing: 2},
    { roomName: 'A-7', electricityUsage: 0 , sharing: 2},
    { roomName: 'A-8', electricityUsage: 0 , sharing: 2},
    { roomName: 'A-9', electricityUsage: 0 , sharing: 2},
    { roomName: 'A-10', electricityUsage: 0 , sharing: 2},
    { roomName: 'A-11', electricityUsage: 0 , sharing: 2},
    { roomName: 'A-12', electricityUsage: 0 , sharing: 2},
    { roomName: 'A-13', electricityUsage: 0 , sharing: 2},
    { roomName: 'A-14', electricityUsage: 0 , sharing: 2},
    { roomName: 'A-15', electricityUsage: 0 , sharing: 2},
    { roomName: 'A-16', electricityUsage: 0 , sharing: 2},
    { roomName: 'A-17', electricityUsage: 0 , sharing: 2},
    { roomName: 'A-18', electricityUsage: 0 , sharing: 2},
    { roomName: 'A-19', electricityUsage: 0 , sharing: 2},
    { roomName: 'A-20', electricityUsage: 0 , sharing: 2},
    { roomName: 'A-21', electricityUsage: 0 , sharing: 2},
    { roomName: 'A-21', electricityUsage: 0 , sharing: 2},
    { roomName: 'A-22', electricityUsage: 0 , sharing: 2},
    { roomName: 'A-23', electricityUsage: 0 , sharing: 2},
    { roomName: 'A-24', electricityUsage: 0 , sharing: 2},
    { roomName: 'A-25', electricityUsage: 0 , sharing: 2},
    { roomName: 'A-26', electricityUsage: 0 , sharing: 2},
    { roomName: 'A-27', electricityUsage: 0 , sharing: 2},
    { roomName: 'A-28', electricityUsage: 0 , sharing: 2},
    { roomName: 'A-29', electricityUsage: 0 , sharing: 2},
    { roomName: 'A-30', electricityUsage: 0 , sharing: 2},
  ];

  ngOnInit(): void {
    interval(1000).subscribe(() => {
      this.roomsData.forEach(room => {
        room.electricityUsage = Math.floor(Math.random() * 50);
      });
    });
  }

  openDialog() {
    const dialogRef = this.dialog.open(ElecBillGeneratorComponent);
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
} 
