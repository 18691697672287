<app-topnav></app-topnav>
<div class="spacer" style="padding: 5px;"></div>

<div class="con with-background">
  
  <!-- <div class="loader-overlay" [ngClass]="{ 'hidden': !showLoader }">
    <div class="loader-content">
      <mat-spinner diameter="50"></mat-spinner>
      <br>
      <h3>Please wait.....</h3>
    </div>
  </div>   -->

  <mat-card class="mat-card-1 with-background">
    <div class="container">
      <div class="card-grid">
        <div class="card" *ngFor="let product of products">
          <img class="card-img-top" [src]="product.imageUrl" alt="Card image cap">
          <div class="card-body">
            <h3 class="card-title">{{ product.title }}</h3>
            <!-- <span class="card-text" [ngClass]="{'read-more': product.description.length > 30}">
              {{ product.description }}
              <span *ngIf="product.description.length > 50 && !product.showFullDescription" (click)="toggleDescription(product)" class="learn-more"> Learn More</span>
            </span> -->
            <!--  <span *ngIf="product.showFullDescription" class="card-text">{{ product.description }}</span>
            <ul class="key-features-list">
              <li *ngFor="let feature of product.keyFeatures">
                <i [class]="feature.iconClass"></i> {{ feature.text }}
              </li>
            </ul> -->
            <a [href]="product.url" class="btn btn-primary">Download</a>
          </div>
        </div>
      </div>
    </div>
  </mat-card>  
</div>

<app-footer></app-footer>