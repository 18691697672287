import { Component } from '@angular/core';

@Component({
  selector: 'app-nodataaviliable',
  templateUrl: './nodataaviliable.component.html',
  styleUrls: ['./nodataaviliable.component.scss']
})
export class NodataaviliableComponent {

}
