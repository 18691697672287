<div class="con">
    <mat-card>
        <div style="text-align:center">
            <div class="container">
                <div class="col-md-6 mx-auto">
                    <div class="form-area">
                        <form role="form">
                            <br style="clear:both">
                            <div class="form-group">
                                <div class="float-left form-input-wrapper">
                                    <input type="email" class="form-control" id="email" name="email" placeholder="Email"
                                        required>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="float-left form-input-wrapper">
                                    <input type="text" class="form-control" id="subject" name="subject"
                                        placeholder="Subject" required>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="float-left form-input-wrapper">
                                    <textarea class="form-control" type="description" id="description"
                                        name="description" placeholder="Description" maxlength="140"
                                        rows="7"></textarea>
                                </div>
                            </div>
                            <button type="button" id="submit" name="submit"
                                class="btn btn-primary pull-left m-3">Submit</button>
                            <button type="button" id="cancel" name="cancel"
                                class="btn btn-primary pull-left m-3">Cancel</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </mat-card>
</div>