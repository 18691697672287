import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import jsPDF from 'jspdf';
import { Customer } from 'src/app/models/Customer';
import { Apiservice } from 'src/app/service/api/apiservice.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss'],
  providers: [DatePipe],
})

export class CustomersComponent implements OnInit, AfterViewInit {

  selectedStartDate: Date | null = null;
  selectedEndDate: Date | null = null;

  displayedColumns: string[] = [
    'serialNumber',
    'createdDate',
    'name',
    'mail',
    'phoneNumber',
    'address',
    'room',
    'cardNumber',
    'paymentStatus',
    'enbDis',
    'action'
  ];

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  dataSource: MatTableDataSource<Customer>;
  isLoading: boolean = false;
  isPDFLoading: boolean = false;
  isFiltering: boolean = false;
  noDataAvailable: boolean = false;

  constructor(
    private apiService: Apiservice,
    private datePipe: DatePipe,
    private router: Router
  ) {
    this.dataSource = new MatTableDataSource<Customer>([]);
  }

  ngOnInit(): void {
    this.fetchCustomers();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  fetchCustomers() {
    this.isLoading = true;
    const minimumLoadingTime = 500;

    setTimeout(() => {
      this.apiService.fetchAllPGCustomer().subscribe(
        (response: any) => {
          const customers: Customer[] = response.recordsList;
          this.noDataAvailable = customers.length === 0;
          customers.forEach((customer) => {
            this.fetchRoomNumber(customer);
          });
          this.dataSource.data = customers;
        },
        (error) => {
          console.error('Error fetching data', error);
        },
        () => {
          this.isLoading = false;
        }
      );
    }, minimumLoadingTime);
  }

  getSerialNumber(row: any): number {
    const index = this.dataSource.data.indexOf(row);
    return index + 1;
  }

  fetchRoomNumber(customer: Customer) {
    if (customer.assignedRoom === 0) {
      customer.roomNumber = 'Not Assigned';
    } else {
      this.apiService.fetchRoomNumberByID(customer.assignedRoom).subscribe(
        (response: any) => {
          customer.roomNumber = response.roomNumber;
        },
        (error) => {
          console.error('Error fetching room number', error);
        }
      );
    }
  }

  formatDate(date: string | null): string {
    if (date) {
      const parsedDate = new Date(date);
      return this.datePipe.transform(parsedDate, 'dd-MM-yyyy') || '';
    } else {
      return '';
    }
  }

  onDateRangeChange() {
    if (this.selectedStartDate !== null && this.selectedEndDate !== null) {
      this.isFiltering = true;
      const startDate = this.selectedStartDate;
      const endDate = this.selectedEndDate;

      const filteredData = this.dataSource.data.filter((customer) => {
        const createdDate = new Date(customer.createdDate);
        return createdDate >= startDate && createdDate <= endDate;
      });

      setTimeout(() => {
        this.dataSource.data = filteredData;
        this.isFiltering = false;
      }, 500);
    }
  }

  viewForm(id: number) {
    this.router.navigate(['initial/infoForm', id]);
  }

  generatePDF(customer: number) {
    this.isPDFLoading = true;
    this.apiService.fetchAgreementData(customer).subscribe(
      (data) => {
        const blob = new Blob([data], { type: 'application/pdf' });
        saveAs(blob, customer + '.pdf');
        this.isPDFLoading = false;
      },
      (error) => {
        this.isPDFLoading = false;
        console.error('Error generating PDF:', error);
      }
    );
  }

  navigateToEdit(id: number) {
    this.router.navigate(['initial/update-customer', id]);
  }
}
