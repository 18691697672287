export class Enquire {
    name: string;
    mail: string;
    phoneNumber: string;
    message: string;

    constructor(name: string, mail: string, phoneNumber: string, message: string) {
            this.name = name;
            this.phoneNumber = phoneNumber;
            this.mail = mail;
            this.message = message;
        }
}  