<div class="con">
  <mat-form-field class="drop-down">
    <mat-label>Select Manager</mat-label>
    <mat-select [(ngModel)]="selectedUserId" name="selectedUser">
      <mat-option *ngFor="let user of users" [value]="user.id">{{ user.name }}</mat-option>
    </mat-select>
  </mat-form-field>

  <div class="access-control-container">
    <div *ngFor="let access of accessControls" class="access-control">
      <mat-slide-toggle [(ngModel)]="access.value">{{ access.label }}</mat-slide-toggle>
    </div>
  </div>

  <button mat-raised-button class="sbt-btn" color="primary" (click)="submitForm()">Give Access!</button>
</div>