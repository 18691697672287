import { DatePipe } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AccessCard } from 'src/app/models/accessCard/AccessCard';
import { Apiservice } from 'src/app/service/api/apiservice.service';

@Component({
  selector: 'app-access-card',
  templateUrl: './access-card.component.html',
  styleUrls: ['./access-card.component.scss'],
  providers: [DatePipe],
})
export class AccessCardComponent {

  selectedStartDate: Date | null = null;
  selectedEndDate: Date | null = null;

  displayedColumns: string[] = [
    'serialNumber',
    'createdDate',
    'name',  
    'phoneNumber',
    'cardNumber',
    'enbDis' 
  ];

  dataSource: MatTableDataSource<AccessCard>;
  isLoading: boolean = false;
  isFiltering: boolean = false;
  noDataAvailable: boolean = false;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(private apiService: Apiservice, private datePipe: DatePipe) {
    this.dataSource = new MatTableDataSource<AccessCard>([]);
  } 

  ngOnInit(): void {
    this.fetchCardData();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getSerialNumber(row: any): number {
    const index = this.dataSource.data.indexOf(row);
    return index + 1;
  }

  fetchCardData() {
    this.isLoading = true;
    const minimumLoadingTime = 500;  
    setTimeout(() => {
      this.apiService.fetchAllCardDetailsForPG().subscribe(
        (response: any) => {
          const card: AccessCard[] = response.recordsList;
          this.dataSource.data = card;
        },
        (error) => {
          console.error('Error fetching data', error);
        },
        () => {
          this.isLoading = false; 
        }
      );
    }, minimumLoadingTime);
  } 

  getFormattedDate(timestamp: number): string {
    const slash = '/';
    const date = new Date(timestamp);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();

    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const amPm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');
    // return `${day}${slash}${month}${slash}${year} ${formattedHours}${':'}${minutes} ${amPm}`;
    return `${day}${slash}${month}${slash}${year}`;
  }

  formatDate(date: string | null): string {
    if (date) {
      const parsedDate = new Date(date);
      return this.datePipe.transform(parsedDate, 'dd-MM-yyyy') || '';
    } else {
      return '';
    }
  }  

  onDateRangeChange() {
    if (this.selectedStartDate !== null && this.selectedEndDate !== null) {
      this.isFiltering = true;
      const startDate = this.selectedStartDate;
      const endDate = this.selectedEndDate;
  
      const filteredData = this.dataSource.data.filter((customer) => {
        const createdDate = new Date(customer.createdDate);
        return createdDate >= startDate && createdDate <= endDate;
      });
  
      setTimeout(() => {
        this.dataSource.data = filteredData;
        this.isFiltering = false;
      }, 500);
    }
  }
}
