import { INavbarData } from "./helper";

export const navBarData: INavbarData[] = [

// export const navBarData = [
    {
        routeLink: 'dashboard',
        icon: 'fa fa-home',
        label: 'Dashboard'
    },
    {
        routeLink: 'customers',
        icon: 'fa fa-users',
        label: 'Customer\'s',
        items: [
            {
            routeLink: 'customers/collections',
            label: 'Collection\'s'
        },
        {
            routeLink: 'customers/due',
            label: 'Due\'s'
        }    
    ]
    },
    {
        routeLink: 'unpaidCustomers',
        icon: '	fa fa-user-times',
        label: 'Unpaid'
    },
    {
        routeLink: 'accounts',
        icon: 'fa fa-bank',
        label: 'Account'
    },
    {
        routeLink: 'customerLedger',
        icon: 'fa fa-rupee-sign',
        label: 'Customer Ledger'
    },
    {
        routeLink: 'empLedger', 
        icon: 'fa fa-child',
        label: 'Employee Ledger'
    },
    {
        routeLink: 'aggrements', 
        icon: 'fas fa-file-contract',
        label: 'Aggrement\'s'
    },
    {
        routeLink: 'access',
        icon: 'fa fa-check-square-o', 
        label: 'Access'
    },
    {
        routeLink: 'accessCard',
        icon: 'fa fa-credit-card', 
        label: 'Card'
    },
    {
        routeLink: 'food-menu',
        icon: 'fa fa-hashtag', 
        label: 'Food Menu'
    },
    {
        routeLink: 'downloads',
        icon: 'fa fa-download', 
        label: 'Download'
    },
    {
        routeLink: 'settings',
        icon: 'fal fa-cog',
        label: 'Setting\'s'
    },
    {
        routeLink: 'subscription',
        icon: 'fas fa-hand-holding-heart',
        label: 'Subscription'
    },
    {
        routeLink: 'ticket',
        icon: 'fal fa-tags',
        label: 'Raise Ticket'
    },
    {
        routeLink: '',
        icon: '',
        label: ''
    }
];