export class TermsAndCondition {
    id: number;
    numberOf: number;
    first: string;
    second: string;
    third: string;
    fourth: string;
    fifth: string;
    sixth: string;
    seven: string;
    eight: string;
    nine: string;
    ten: string;
    eleven: string;
    twelve: string;
    thirteen: string;
    fourteen: string;
    fifteen: string;
    sixteen: string;
    seventeen: string;
    eighteen: string;
    nineteen: string;
    twenty: string;
  
    constructor(
      id: number,
      numberOf: number,
      first: string,
      second: string,
      third: string,
      fourth: string,
      fifth: string,
      sixth: string,
      seven: string,
      eight: string,
      nine: string,
      ten: string,
      eleven: string,
      twelve: string,
      thirteen: string,
      fourteen: string,
      fifteen: string,
      sixteen: string,
      seventeen: string,
      eighteen: string,
      nineteen: string,
      twenty: string
    ) {
      this.id = id;
      this.numberOf = numberOf;
      this.first = first;
      this.second = second;
      this.third = third;
      this.fourth = fourth;
      this.fifth = fifth;
      this.sixth = sixth;
      this.seven = seven;
      this.eight = eight;
      this.nine = nine;
      this.ten = ten;
      this.eleven = eleven;
      this.twelve = twelve;
      this.thirteen = thirteen;
      this.fourteen = fourteen;
      this.fifteen = fifteen;
      this.sixteen = sixteen;
      this.seventeen = seventeen;
      this.eighteen = eighteen;
      this.nineteen = nineteen;
      this.twenty = twenty;
    }
  }
  