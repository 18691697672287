<app-topnav></app-topnav>
<div class="spacer" style="padding: 5px;"></div>
<div class="container mt-6">
    <div class="card">
      <form #createPGForm="ngForm" (ngSubmit)="onSubmit(createPGForm)">
        <div class="row">
            <div class="col">
                <input type="text" ngModel name="pgName" id="pgName" placeholder="Enter PG Name" class="form-control mb-3">
            </div>
            <div class="col">
                <input type="text" ngModel name="pgUserName" id="pgUserName" placeholder="Enter PG User Name" class="form-control mb-3">
            </div>
        </div>

        <div class="row">
            <div class="col">
                <input type="text" ngModel name="phoneNumber" id="phoneNumber" placeholder="Enter Phone Number" class="form-control mb-3">
            </div>
            <div class="col">
                <input type="email" ngModel name="mail" id="mail" placeholder="Enter PG Mail Address" class="form-control mb-3">
            </div>
        </div>

        <div class="row">
            <div class="col">
                <input type="text" ngModel name="state" id="state" placeholder="Select State" class="form-control mb-3">
            </div>
            <div class="col">
                <input type="text" ngModel name="dist" id="dist" placeholder="Select District" class="form-control mb-3">
            </div>
        </div>

        <div class="row">
            <div class="col">
                <input type="number" ngModel name="pinCode" id="pinCode" placeholder="Enter PIN Code" class="form-control mb-3">
            </div>
            <div class="col">
                <input type="text" ngModel name="category" id="category" placeholder="Select Category" class="form-control mb-3">
            </div>
        </div>

        <div class="row">
            <div class="col">
                <textarea type="text" ngModel name="aboutPg" id="aboutPg" placeholder="Abount PG" class="form-control mb-3"></textarea>
            </div>
        </div>
  
        <div class="row">
          <div class="col">
            <input type="submit" value="Create" class="btn btn-outline-primary form-control">
          </div>
          <div class="col">
            <input type="button" value="Cancel" routerLink="/login" class="btn btn-outline-secondary form-control">
          </div>
        </div>
      
       </form>
    </div>
</div>