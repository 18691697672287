export const userItems = [
    {
        icon: 'far fa-user',
        label: 'Profile'
    },
    {
        icon: 'far fa-cog',
        label: 'Settings'
    },
    {
        icon: 'far fa-unlock-alt',
        label: 'Lock Screen'
    },
    {
        icon: 'far fa-power-off',
        label: 'Logout'
    }
]