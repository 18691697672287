<div class="con">
  <div class="loader-overlay" *ngIf="isLoading">
    <div class="loader-content">
      <mat-spinner diameter="50"></mat-spinner>
      <br>
      <h3>Please wait, we're fetching customer transaction details..</h3>
    </div>
  </div>
  
  <div class="loader-overlay" *ngIf="isFiltering">
    <div class="loader-content">
      <mat-spinner diameter="50"></mat-spinner>
      <br>
      <h3>Please wait, we're filtering customer transaction details..</h3>
    </div>
  </div>
    
  <mat-card class="mat-card">
    <div class="search-and-date-container" *ngIf="!isLoading">
      <mat-form-field class="search-bar" appearance="outline">
        <mat-label>Search</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Customer Name" #input>
        <span matSuffix style="padding-right: 10px;">
          <mat-icon>search</mat-icon>
        </span>
      </mat-form-field>
    
      <mat-form-field class="date-range-field" appearance="outline">
        <mat-label>Select start & end date</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate placeholder="Start date" [(ngModel)]="selectedStartDate" (ngModelChange)="onDateRangeChange()">
          <input matEndDate placeholder="End date" [(ngModel)]="selectedEndDate" (ngModelChange)="onDateRangeChange()">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </div>
  </mat-card>

  <div class="spacer"></div>
    
    <!-- *ngIf="!isLoading && !noDataAvailable" because of this pagination is affecting so removed from line -->
    <div class="mat-elevation-z8 table-container">
      <table mat-table [dataSource]="dataSource" matSort>
    
        <ng-container matColumnDef="serialNumber">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-align"> No. </th>
          <td mat-cell *matCellDef="let row" class="center-align">{{ getSerialNumber(row) }}.</td>
        </ng-container>
        
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-align"> Customer Name </th>
          <td mat-cell *matCellDef="let row" class="center-align">
            {{ (row.payeeName).toUpperCase() }}
          </td>
        </ng-container>
  
        <ng-container matColumnDef="transactionId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Transaction ID </th>
          <td mat-cell *matCellDef="let row">
            {{ (row.transactionNumber).toUpperCase() }}
          </td>
        </ng-container>      
    
        <ng-container matColumnDef="paidAmount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Paid Amount </th>
          <td mat-cell *matCellDef="let row"> 
              <span class="amount">
                  {{ row.paidAmount }} <i class="fas fa-rupee-sign"></i>
              </span>
          </td>
        </ng-container> 
    
        <ng-container matColumnDef="paidDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Paid Date </th>
          <td mat-cell *matCellDef="let row"> {{getFormattedDate(row.createdDate)}} </td>
        </ng-container>
  
        <ng-container matColumnDef="nextPayDate"> 
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Next Payment</th>
          <td mat-cell *matCellDef="let row"> {{ getFormattedDate(row.nextPayment) }} </td>
        </ng-container>
  
        <!-- <ng-container matColumnDef="daysLeft"> 
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Days Left</th>
          <td mat-cell *matCellDef="let row"> {{ getFormattedDate(row.nextPayment) }} </td>
        </ng-container> -->
  
        <ng-container matColumnDef="daysLeft">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Days Left</th>
          <td mat-cell *matCellDef="let row" [ngStyle]="{ 'color': isTextRed(row.nextPayment) ? 'red' : 'green' }">
            {{ getDaysUntilNextPayment(row.nextPayment) }} Days
          </td>
        </ng-container>         
    
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
          <td mat-cell *matCellDef="let row">
            <ng-container *ngIf="row.paymentStatus == 1;">
              <span class="paid-amount">
                  <i class="fas fa-check"></i> PAID
              </span>
            </ng-container>
          </td>
        </ng-container>  
  
        <ng-container matColumnDef="upi">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Received On </th>
          <td mat-cell *matCellDef="let row"> {{row.receivedOn}} </td>
        </ng-container>

        <!-- <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Actions </th>
          <td mat-cell *matCellDef="let row">
            <button mat-icon-button (click)="generateReceipt(row.transactionNumber)" [matTooltip]="'Click to Download Receipt'" matTooltipPosition="after" matTooltipShowDelay="500">
              <mat-icon>receipt</mat-icon>
            </button>
          </td>
        </ng-container> -->
        
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
      </table>
      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>
    </div>
    
    <!-- if there is no data then show this message -->
  <div class="no-data-message" *ngIf="!isLoading && noDataAvailable">
    <p>There is No Data to be Shown!</p>
  </div>
</div>