<div class="con">
    <!-- <div class="loader-overlay">
      <div class="loader-content">
        <mat-spinner diameter="50"></mat-spinner>
        <br>
        <h3>Please wait, we're fetching details..</h3>
      </div>
    </div> -->

    <mat-card class="hover-for-panel">
          <div class="left-icons">
              <button mat-icon-button [routerLink]="['/initial/food-menu-create']"
                  [matTooltip]="'Create Menu Card'" class="rotate-on-hover">
                  <mat-icon>add</mat-icon>
              </button>

              <button mat-icon-button [routerLink]="['/route-here']" [matTooltip]="'Update Menu'"
                  class="rotate-on-hover">
                  <mat-icon>update</mat-icon>
              </button>

              <button mat-icon-button [routerLink]="['/your-route-here']" [matTooltip]="'List of Custom Access'"
                  class="dont-rotate-on-hover">
                  <mat-icon>list</mat-icon>
              </button>
          </div>

          <div class="spacer"></div>

          <!--For Food Manu-->
          <mat-expansion-panel (opened)="togglePanel('foodPanel')" [expanded]="foodMenuPanelOpenState" class="hover-for-panel">
            <mat-expansion-panel-header>
              <mat-panel-title> Open to see food menu </mat-panel-title>
            </mat-expansion-panel-header>
              <p>I'm visible because I am open</p>
          </mat-expansion-panel>

          <div class="spacer"></div>

          <!--For Token-->
          <mat-expansion-panel (opened)="togglePanel('foodTokenPanel')" [expanded]="foodTokenSummaryPanelOpenState" class="hover-for-panel">
            <mat-expansion-panel-header>
              <mat-panel-title> Token Summary </mat-panel-title>
            </mat-expansion-panel-header>
              <p>I'm visible because I am open</p>
          </mat-expansion-panel>

          <div class="spacer"></div>
          
          <!--For Food Summary-->
          <mat-expansion-panel (opened)="togglePanel('foodSummaryPanel')" [expanded]="foodSummaryPanelOpenState" class="hover-for-panel">
            <mat-expansion-panel-header>
              <mat-panel-title> Food Summary </mat-panel-title>
            </mat-expansion-panel-header>
              <p>I'm visible because I am open</p>
          </mat-expansion-panel>
    </mat-card>
</div>