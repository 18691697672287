<div class="con">
  <div class="loader-overlay" *ngIf="isLoading">
    <div class="loader-content">
      <mat-spinner diameter="50"></mat-spinner>
      <br>
      <h3>Please wait, we're fetching all customer card details..</h3>
    </div>
  </div>
    
  <div class="loader-overlay" *ngIf="isFiltering">
    <div class="loader-content">
      <mat-spinner diameter="50"></mat-spinner>
      <br>
      <h3>Please wait, we're filtering customer transaction details..</h3>
    </div>
  </div>
  
  <mat-card class="mat-card">
    <div class="search-and-date-container" *ngIf="!isLoading">
      <mat-form-field class="search-bar" appearance="outline">
        <mat-label>Search</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Customer Name" #input>
        <span matSuffix style="padding-right: 10px;">
          <mat-icon>search</mat-icon>
        </span>
      </mat-form-field>
    
      <mat-form-field class="date-range-field" appearance="outline">
        <mat-label>Select start & end date</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate placeholder="Start date" [(ngModel)]="selectedStartDate" (ngModelChange)="onDateRangeChange()">
          <input matEndDate placeholder="End date" [(ngModel)]="selectedEndDate" (ngModelChange)="onDateRangeChange()">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </div>
  </mat-card>

  <div class="spacer"></div>
    
    <div class="mat-elevation-z8 table-container">
      <table mat-table [dataSource]="dataSource" matSort>
    
        <ng-container matColumnDef="serialNumber">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> No. </th>
          <td mat-cell *matCellDef="let row">{{ getSerialNumber(row) }}.</td>
        </ng-container>
  
        <ng-container matColumnDef="createdDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-align"> Date </th>
          <td mat-cell *matCellDef="let row" class="center-align">{{ formatDate(row.createdDate) }}</td>
        </ng-container>
        
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer Name </th>
          <td mat-cell *matCellDef="let row">
            {{ (row.customerName).toUpperCase() }}
          </td>
        </ng-container>
  
        <ng-container matColumnDef="phoneNumber">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Contact </th>
          <td mat-cell *matCellDef="let row">
            {{ row.phone }}
          </td>
        </ng-container>      
    
        <ng-container matColumnDef="cardNumber">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Access Card </th>
          <td mat-cell *matCellDef="let row"> 
              {{ row.cardNumber }}
          </td>
        </ng-container> 
    
        <ng-container matColumnDef="enbDis">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Enable/Disable </th>
          <td mat-cell *matCellDef="let row">
            <ng-container *ngIf="row.enbDis === 0">
              <button type="button" class="button-enable">ENABLE</button>
            </ng-container>
            <ng-container *ngIf="row.enbDis === 1">
              <span class="button-disable">DISABLE</span>
            </ng-container>
          </td>
        </ng-container>
        
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="5">There is No Data to be Shown!!</td>
        </tr>
        <!-- <app-nodataaviliable *ngIf="!isLoading && dataSource.data.length == 0"></app-nodataaviliable> -->
      </table>
      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>
    </div>
     
      <!-- if there is no data then show this message -->
  <div class="no-data-message" *ngIf="!isLoading && noDataAvailable">
    <p>There is No Data to be Shown!</p>
  </div>
</div>