import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Apiservice } from 'src/app/service/api/apiservice.service';
import { ToastrService } from 'ngx-toastr';

import { AuthRequest } from 'src/app/modules/AuthRequest';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  isModalOpen: boolean = false;

  constructor(
    private apiService: Apiservice,
    private router: Router,
    private toastr: ToastrService,
    private snack: MatSnackBar
  ) {}

  onSubmit(loginForm: NgForm) {
    const { mail, password, pgName } = loginForm.value;
    const authRequest = new AuthRequest(mail, password, pgName);
    if(loginForm.invalid) {
      this.snack.open('Fill All The Details for Login!', 'Close', {
        duration: 5000,
        panelClass: 'custom-snackbar'
      });
    } else {
      this.apiService.loginRequest(authRequest)
      .subscribe({
        next: (response) => this.handleLoginSuccess(response),
        error: (error) => this.handleLoginError()
      });
    }
  }

  private handleLoginSuccess(response: any) {
    localStorage.setItem("token", response.token)
    this.router.navigate(['/initial/dashboard']);
  } 

  private handleLoginError() {
    this.snack.open('Wrong credencials Please Try Again!', 'Close', {
      duration: 5000,
      panelClass: 'custom-snackbar'
    });
  }
}
