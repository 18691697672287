import { Component } from '@angular/core';

@Component({
  selector: 'app-enable',
  templateUrl: './enable.component.html',
  styleUrls: ['./enable.component.scss']
})
export class EnableComponent {

}
