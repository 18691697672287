<div class="con">
    <div class="loader-overlay" *ngIf="isLoading">
      <div class="loader-content">
        <mat-spinner diameter="50"></mat-spinner>
        <br>
        <h3>Please wait, we're fetching customer details..
        </h3>
      </div>
    </div>
    
    <mat-card class="mat-card" *ngIf="customer">
        <div class="customer-details">
            <mat-form-field appearance="outline">
                <mat-label>Outline form field</mat-label>
                <input matInput placeholder="Placeholder">
                <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
                <mat-hint>Hint</mat-hint>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Outline form field</mat-label>
                <input matInput placeholder="Placeholder">
                <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
                <mat-hint>Hint</mat-hint>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Outline form field</mat-label>
                <input matInput placeholder="Placeholder">
                <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
                <mat-hint>Hint</mat-hint>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Outline form field</mat-label>
                <input matInput placeholder="Placeholder">
                <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
                <mat-hint>Hint</mat-hint>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Outline form field</mat-label>
                <input matInput placeholder="Placeholder">
                <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
                <mat-hint>Hint</mat-hint>
              </mat-form-field>
        </div>
    </mat-card>
    
  </div>