import { Component } from '@angular/core';
import { FormBuilder, NgForm, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr'
import { SignUp } from 'src/app/modules/SignUp';
import { Apiservice } from 'src/app/service/api/apiservice.service';
import { UUIDGenerator } from 'src/app/service/common/UUID';
import { CommonComponent } from '../../errors/common/common.component';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent {

  isLoading: boolean = false;
  signUpModel: SignUp = new SignUp('', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '');
  
  constructor(
    private apiService: Apiservice,
    private router: Router,
    private toastr: ToastrService,
    public dialog: MatDialog
  ) {}

  checkFormValidity(form: NgForm): void {
    if (form.valid && !form.dirty) {
      this.onSubmit(form);
    } else {
      this.openDialog();
    }
  }

  onSubmit(signUpData: NgForm) {
    const uuid = UUIDGenerator.generateUniqueCode();
      const { 
              firstName, lastName, phoneNumber, 
              mail, password, profession, gender, 
              pgName, userName, pgMail, 
              pgPhoneNumber, 
              state, dist, 
              pinCode, category, 
              aboutPg 
            } = signUpData.value;
      
    const signupRequest = new SignUp(firstName, lastName, phoneNumber, mail, password, profession, 
      gender, uuid, pgName, userName, pgMail, pgPhoneNumber, state, dist, pinCode, category, aboutPg);

    this.apiService.signUpRequest(signupRequest)
      .subscribe({
        next: (response) => this.handleLoginSuccess(response),
        error: (error) => this.handleLoginError()
      });
  }

  private handleLoginSuccess(response: any) {
    this.isLoading = true;
    localStorage.setItem("token", response.token)
    this.router.navigate(['/']);
    this.isLoading = false;
  }

  private handleLoginError() {
    this.router.navigate(['/forbidden']);
  }

  openDialog() {
    const dialogRef = this.dialog.open(CommonComponent);
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
}
