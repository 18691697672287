import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';

interface PanelStates {
  createBrakfastPanelOpenState: boolean;
  createLunchPanelOpenState: boolean;
  createDinnerPanelOpenState: boolean;
}

@Component({
  selector: 'app-create-food-menu',
  templateUrl: './create-food-menu.component.html',
  styleUrls: ['./create-food-menu.component.scss']
})
export class CreateFoodMenuComponent implements AfterViewInit {
  scrollableContent = false;
  @ViewChild('panelContent') panelContent?: ElementRef<HTMLDivElement>;

  ngAfterViewInit(): void {
    this.checkPanelHeight();
  }

  panelStates: PanelStates = {
    createBrakfastPanelOpenState: false,
    createLunchPanelOpenState: false,
    createDinnerPanelOpenState: false,
  };

  createBrakfastPanelOpenState = false;
  createLunchPanelOpenState = false;
  createDinnerPanelOpenState = false;

  togglePanel(panelName: string): void {
    if (panelName === 'createBrakfast') {
      this.createBrakfastPanelOpenState = !this.createBrakfastPanelOpenState;
      this.createLunchPanelOpenState = false;
      this.createDinnerPanelOpenState = false;
    } else if (panelName === 'createLunch') {
      this.createBrakfastPanelOpenState = false;
      this.createLunchPanelOpenState = !this.createLunchPanelOpenState;
      this.createDinnerPanelOpenState = false;
    } else if (panelName === 'createDinner') {
      this.createBrakfastPanelOpenState = false;
      this.createLunchPanelOpenState = false;
      this.createDinnerPanelOpenState = !this.createDinnerPanelOpenState;
    }
  }

  checkPanelHeight(): void {
    if (this.panelContent) {
      const contentHeight = this.panelContent.nativeElement.scrollHeight;
      const maxHeight = 400;
      this.scrollableContent = contentHeight > maxHeight;
    }
  }
}
