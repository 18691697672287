import { AfterViewInit, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PG } from 'src/app/models/PG';
import { Apiservice } from 'src/app/service/api/apiservice.service';
import { PopupComponent } from '../popup/popup.component';

@Component({
  selector: 'app-search-pg',
  templateUrl: './search-pg.component.html',
  styleUrls: ['./search-pg.component.scss']
})
export class SearchPgComponent implements OnInit, AfterViewInit {

  url ='/assets/3_sharing.jpg';

  displayedColumns: string[] = [
    'serialNumber',
    'profile',
    'pgName',
    'pgState',
    'pgDist',
    'pgPINCode',
    'category'
  ];

  dataSource: MatTableDataSource<PG>;
  isLoading: boolean = false;
  noDataAvailable: boolean = false;
  filteredResults: PG[] = [];
  isScrolled: boolean = false;
  showNoResultsMessage = false;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  individualTags: string[] = [];

  constructor(private apiService: Apiservice, public dialog: MatDialog) {
    this.dataSource = new MatTableDataSource<PG>([]);
  }

  ngOnInit(): void {
    this.fetchPGs();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  fetchPGs() {
    this.isLoading = true;
    const minimumLoadingTime = 500;

    setTimeout(() => {
      this.apiService.searcgPG().subscribe(
        (response: any) => {
          const pg: PG[] = response.recordsList;
          this.dataSource.data = pg;
          this.noDataAvailable = pg.length === 0;
          this.isLoading = false;
        },
        (error) => {
          console.error('Error fetching data', error);
          this.isLoading = false;
        }
      );
    }, minimumLoadingTime);

    setTimeout(() => {
      if (this.isLoading && this.noDataAvailable) {
        console.log('Try again after some time.');
      }
    }, minimumLoadingTime * 5);
  }

  getSerialNumber(row: any): number {
    const index = this.dataSource.data.indexOf(row);
    return index + 1;
  }

  applyFilterAndSort(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.trim().toUpperCase();
    this.dataSource.filter = filterValue;

    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'pgName':
          return item.pgName;
        case 'pgState':
          return item.state;
        default:
          return item[property as keyof PG];
      }
    };
    this.dataSource.sort = this.sort;

    if (this.dataSource.filteredData.length === 0) {
      this.showNoResultsMessage = true;
    } else {
      this.showNoResultsMessage = false;
      this.filteredResults = this.dataSource.filteredData.slice();
    }
  }

  sortData(event: any) {
    this.dataSource.sort = this.sort;
    this.dataSource.sort.sort(event);
  }

  openPopup(id: string) {
    this.dialog.open(PopupComponent, {
      data: { id },
    });
  }

  fetchIndividualTags(hashTags: string): string[] {
    if (!hashTags || hashTags.trim() === '') {
      return ['#HashTags'];
    }
    return hashTags.split(',').map(tag => tag.trim());
  }  
}

