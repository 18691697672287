import { Component } from '@angular/core';

@Component({
  selector: 'app-pg-master-washing-ctrl',
  templateUrl: './pg-master-washing-ctrl.component.html',
  styleUrls: ['./pg-master-washing-ctrl.component.scss']
})
export class PgMasterWashingCtrlComponent {

}
