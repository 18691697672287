import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TermsAndCondition } from 'src/app/models/tAndC/TermsAndCondition';
import { Apiservice } from 'src/app/service/api/apiservice.service';
import { CommonComponent } from '../../errors/common/common.component';
import { MatDialog } from '@angular/material/dialog';
import { JwtResolverService } from 'src/app/service/jwt/jwt-resolver.service';
import { UpdateTandC } from 'src/app/models/tAndC/UpdateTAndC';
import { UpdateChargesOrPenalty } from 'src/app/models/tAndC/UpdateChargesOrPenalty';
import { ChargesOrPenalty } from 'src/app/models/tAndC/ChargesOrPenatly';

interface PanelStates {
  createAggreementPanelOpenState: boolean;
  createTermsAndConditionsPanelOpenState: boolean;
}

@Component({
  selector: 'app-create-aggrements',
  templateUrl: './create-aggrements.component.html',
  styleUrls: ['./create-aggrements.component.scss']
})
export class CreateAggrementsComponent implements AfterViewInit, OnInit {

  termsArray: string[] = [];
  scrollableContent = false;
  showSaveButton = false;
  numberOfTerms: number = 0;
  numberOfPenlty: number = 5;
  showSaveButtonForPenlty = false;
  @ViewChild('panelContent') panelContent?: ElementRef<HTMLDivElement>;

  id: string | undefined;
  tAndC: TermsAndCondition | null = null;
  penalty: ChargesOrPenalty | null = null;
  isLoading: boolean = false;

  constructor(
    private apiService: Apiservice,
    private router: Router,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private resolver: JwtResolverService
  ) { }

  ngOnInit(): void {
    const token = localStorage.getItem('token');
    if(token) {
      this.id = this.resolver.getPGID(token);
    }

    this.route.params.subscribe(params => {
      this.apiService.fetchTAndCData(this.id!).subscribe(
        (response: any) => {
          this.tAndC = response;
          this.isLoading = false;
        },
        error => {
          this.isLoading = false;
        }
      );
    });

    this.route.params.subscribe(params => {
      this.apiService.fetchPenaltyData(this.id!).subscribe(
        (response: any) => {
          this.penalty = response;
          this.isLoading = false;
        },
        error => {
          this.isLoading = false;
        }
      );
    });
  }

  ngAfterViewInit(): void {
    this.checkPanelHeight();
  }

  panelStates: PanelStates = {
    createAggreementPanelOpenState: false,
    createTermsAndConditionsPanelOpenState: false
  };

  createAggreementPanelOpenState = false;
  createTermsAndConditionsPanelOpenState = false;

  togglePanel(panelName: string): void {
    if (panelName === 'createAggreement') {
      this.createAggreementPanelOpenState = !this.createAggreementPanelOpenState;
      this.createTermsAndConditionsPanelOpenState = false;
    } else if (panelName === 'createTermsAndConditions') {
      this.createTermsAndConditionsPanelOpenState = !this.createTermsAndConditionsPanelOpenState;
      this.createAggreementPanelOpenState = false;
    }
  }

  checkPanelHeight(): void {
    if (this.panelContent) {
      const contentHeight = this.panelContent.nativeElement.scrollHeight;
      const maxHeight = 400;
      this.scrollableContent = contentHeight > maxHeight;
    }
  }

  setNumberOfTerms(num: number) {
    this.numberOfTerms = num;
    this.termsArray = new Array(num).fill('');
    this.showSaveButton = true;
  }

  setNumberOfPenlty(num: number) {
    this.numberOfPenlty = num;
    this.showSaveButtonForPenlty = true;
  }

  submitForm(form: NgForm, numb: number) : void {
    if (form != null) {
      this.onSubmit(form, numb);
    } else {
      this.openDialog();
    }
  } 
  
  updateForm(form: NgForm, id: number) : void {
    if (form != null) {
      this.onUpdateSubmit(form, id);
    } else {
      this.openDialog();
    }
  } 

  submitPenaltyForm(form: NgForm, numb: number) : void {
    if (form != null) {
      this.onPenaltyFormSubmit(form, numb);
    } else {
      this.openDialog();
    }
  }
  
  submitUpdatePenaltyForm(form: NgForm, id: number) : void {
    if (form != null) {
      this.onPenaltyUpdateSubmit(form, id);
    } else {
      this.openDialog();
    }
  }

  onSubmit(tAndCData: NgForm, numbers: number) {
    const { numberOf, first, second, third, fourth, fifth, sixth, seven, eight, nine, ten, eleven, twelve, thirteen, fourteen, fifteen, sixteen, seventeen, eighteen, nineteen, twenty } = tAndCData.value;  
    const createTandC = new TermsAndCondition(0, numbers , first, second, third, fourth, fifth, sixth, seven, eight, nine, ten, eleven, twelve, thirteen, fourteen, fifteen, sixteen, seventeen, eighteen, nineteen, twenty);
    this.apiService.createTAndC(createTandC)
      .subscribe({
        next: (response) => this.handleSuccess(response),
        error: (error) => this.handleError()
    });
  }

  onUpdateSubmit(tAndCData: NgForm, id: number) {
    const { first, second, third, fourth, fifth, sixth, seven, eight, nine, ten, eleven, twelve, thirteen, fourteen, fifteen, sixteen, seventeen, eighteen, nineteen, twenty } = tAndCData.value;  
    const createTandC = new UpdateTandC(id, first, second, third, fourth, fifth, sixth, seven, eight, nine, ten, eleven, twelve, thirteen, fourteen, fifteen, sixteen, seventeen, eighteen, nineteen, twenty);
    this.apiService.updateTAndC(createTandC, id)
      .subscribe({
        next: (response) => this.handleSuccess(response),
        error: (error) => this.handleError()
    });
  }

  onPenaltyFormSubmit(penaltyDate: NgForm, numbers: number) {
    const { chargeName1, chargeAmount1, chargeName2, chargeAmount2, chargeName3, chargeAmount3, chargeName4, chargeAmount4, chargeName5, chargeAmount5, chargeName6, chargeAmount6, chargeName7, chargeAmount7 } = penaltyDate.value;  
    const createPenalty = new ChargesOrPenalty(0, numbers , chargeName1, chargeAmount1, chargeName2, chargeAmount2, chargeName3, chargeAmount3, chargeName4, chargeAmount4, chargeName5, chargeAmount5, chargeName6, chargeAmount6, chargeName7, chargeAmount7);
    this.apiService.createPenaltyOrCharges(createPenalty)
      .subscribe({
        next: (response) => this.handleSuccess(response),
        error: (error) => this.handleError()
    });
  }

  onPenaltyUpdateSubmit(penaltyDate: NgForm, id: number) {
    const { chargeName1, chargeAmount1, chargeName2, chargeAmount2, chargeName3, chargeAmount3, chargeName4, chargeAmount4, chargeName5, chargeAmount5, chargeName6, chargeAmount6, chargeName7, chargeAmount7 } = penaltyDate.value;  
    const updatePenalty = new UpdateChargesOrPenalty(id, chargeName1, chargeAmount1, chargeName2, chargeAmount2, chargeName3, chargeAmount3, chargeName4, chargeAmount4, chargeName5, chargeAmount5, chargeName6, chargeAmount6, chargeName7, chargeAmount7);
    this.apiService.updatePenalty(updatePenalty, id)
      .subscribe({
        next: (response) => this.handleSuccess(response),
        error: (error) => this.handleError()
    });
  }

  private handleSuccess(response: any) {
    this.isLoading = false;
    this.openDialog;
  }

  private handleError() {
    this.isLoading = false;
    this.openDialog;
  }

  openDialog() {
    this.isLoading = false;
    const dialogRef = this.dialog.open(CommonComponent);
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
}
