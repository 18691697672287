<div class="con">
  <div class="loader-overlay" *ngIf="isLoading">
    <div class="loader-content">
      <mat-spinner diameter="50"></mat-spinner>
      <br>
      <h3>Please wait, we're fetching all employee details..</h3>
    </div>
  </div>
  
  <div class="loader-overlay" *ngIf="isFiltering">
    <div class="loader-content">
      <mat-spinner diameter="50"></mat-spinner>
      <br>
      <h3>Please wait, we're filtering customer transaction details..</h3>
    </div>
  </div>
    
<mat-card class="mat-card">
  <div class="search-and-date-container" *ngIf="!isLoading">
    <mat-form-field class="search-bar" appearance="outline">
      <mat-label>Search</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Customer Name" #input>
      <span matSuffix style="padding-right: 10px;">
        <mat-icon>search</mat-icon>
      </span>
    </mat-form-field>
  
    <mat-form-field class="date-range-field" appearance="outline">
      <mat-label>Select start & end date</mat-label>
      <mat-date-range-input [rangePicker]="picker">
        <input matStartDate placeholder="Start date" [(ngModel)]="selectedStartDate" (ngModelChange)="onDateRangeChange()">
        <input matEndDate placeholder="End date" [(ngModel)]="selectedEndDate" (ngModelChange)="onDateRangeChange()">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
  </div>
</mat-card>

    <div class="spacer"></div>

    <div class="mat-elevation-z8 table-container">
      <table mat-table [dataSource]="dataSource" matSort>
    
        <ng-container matColumnDef="serialNumber">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-align"> No. </th>
          <td mat-cell *matCellDef="let row" class="center-align">{{ getSerialNumber(row) }}.</td>
        </ng-container>
  
        <ng-container matColumnDef="createdDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-align"> Date </th>
          <td mat-cell *matCellDef="let row" class="center-align">{{ formatDate(row.createdDate) }}</td>
        </ng-container>
        
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-align"> Name </th>
          <td mat-cell *matCellDef="let row" class="center-align">
            {{ (row.firstName + ' ' + row.lastName).toUpperCase() }}
          </td>
        </ng-container>
  
        <ng-container matColumnDef="mobile">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Phone </th>
          <td mat-cell *matCellDef="let row">
            {{ (row.phoneNumber).toUpperCase() }}
          </td>
        </ng-container>      
    
        <ng-container matColumnDef="mail">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Mail </th>
          <td mat-cell *matCellDef="let row"> 
              {{ row.mail }}
          </td>
        </ng-container> 
  
        <ng-container matColumnDef="address">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Address </th>
          <td mat-cell *matCellDef="let row"> 
              {{ (row.state + ' ' + row.dist + ' ' + row.pinCode).toUpperCase() }}
          </td>
        </ng-container> 
    
        <ng-container matColumnDef="joinDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Joining Date </th>
          <td mat-cell *matCellDef="let row"> {{getFormattedDate(row.createdDate)}} </td>
        </ng-container>
  
        <ng-container matColumnDef="birth">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Age/Birth Date </th>
          <td mat-cell *matCellDef="let row"> {{row.age}} </td>
        </ng-container>
  
        <ng-container matColumnDef="occupation">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Profession</th>
          <td mat-cell *matCellDef="let row"> {{ (row.profession).toUpperCase() }} </td>
        </ng-container>
          
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="5">There is No Data to be Shown!!</td>
        </tr>
        <!-- <app-nodataaviliable *ngIf="!isLoading && dataSource.data.length == 0"></app-nodataaviliable> -->
      </table>
      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>
    </div>  
</div>