export class SignUp {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    mail: string;
    password: string;
    profession: string;
    gender: string;
    uuid: string;
    pgName: string;
    userName: string;
    pgMail: string;
    pgPhoneNumber: string;
    state: string;
    dist: string;
    pinCode: string;
    category: string;
    aboutPg: string;

    constructor(firstname: string, lastName: string, phoneNumber: string, 
        mail: string, password: string, profession: string, gender: string, uuid: string, 
        pgName: string, userName: string, pgMail: string, pgPhoneNumber: string, state: string, dist: string,
        pinCode: string, category: string, aboutPg: string
        ) {
            this.firstName = firstname;
            this.lastName = lastName;
            this.phoneNumber = phoneNumber;
            this.mail = mail;
            this.password = password;
            this.profession = profession;
            this.gender = gender;
            this.uuid = uuid;
            this.pgName = pgName;
            this.userName = userName;
            this.pgMail = pgMail;
            this.pgPhoneNumber = pgPhoneNumber;
            this.state = state;
            this.dist = dist;
            this.pinCode = pinCode;
            this.category = category;
            this.aboutPg = aboutPg;
        }
}