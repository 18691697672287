import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { CdkMenuModule } from '@angular/cdk/menu';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ProductsComponent } from './components/products/products.component';
import { AccessComponent } from './components/access/access.component';
import { SubscriptionComponent } from './components/subscription/subscription.component';
import { TicketComponent } from './components/ticket/ticket.component';
import { SettingsComponent } from './components/settings/settings.component';
import { ProfileComponent } from './components/profile/profile.component';
import { ReportsComponent } from './components/reports/reports.component';
import { LoginComponent } from './components/common/login/login.component';
import { SignupComponent } from './components/common/signup/signup.component';
import { InitialDesktopComponent } from './components/common/initial-desktop/initial-desktop.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './service/material-module.service';
import { MatToolbarModule } from '@angular/material/toolbar'; 
import { FooterComponent } from './components/common/footer/footer.component';
import { HeaderComponent } from './components/common/header/header.component';
import { BodyComponent } from './components/common/body/body.component';
import { SidenavComponent } from './components/common/sidenav/sidenav.component';
import { TopnavComponent } from './components/common/topnav/topnav.component';
import { AboutComponent } from './components/common/about/about.component';
import { ContactComponent } from './components/common/contact/contact.component';
import { ProductComponent } from './components/common/product/product.component';
import { ForbiddenComponent } from './components/errors/forbidden/forbidden.component';
import { ToastrModule } from 'ngx-toastr';
import { SpinnerComponent } from './components/common/spinner/spinner.component';
import { CarouselComponent } from './components/common/carousel/carousel.component';
import { CarouselModule } from './carousel/carousel.module';
import { NotfoundComponent } from './components/errors/notfound/notfound.component';
import { InternalServerComponent } from './components/errors/internal-server/internal-server.component';
import { CommonComponent } from './components/errors/common/common.component';
import { CustomersComponent } from './components/customers/customers.component';
import { AddComponent } from './components/add/add.component';
import { UpdateComponent } from './components/update/update.component';
import { DeleteComponent } from './components/delete/delete.component';
import { EnableComponent } from './components/enable/enable.component';
import { DisableComponent } from './components/disable/disable.component';
import { AssignComponent } from './components/assign/assign.component';
import { CheckOutComponent } from './components/check-out/check-out.component';
import { NgToastModule } from 'ng-angular-popup';
import { NgConfirmModule } from 'ng-confirm-box';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NodataaviliableComponent } from './components/errors/nodataaviliable/nodataaviliable.component';
import { CollectionsComponent } from './components/common/collections/collections.component';
import { DueComponent } from './components/common/due/due.component';
import { SublevelMenuComponent } from './components/common/sidenav/sublevel-menu.component';
import { AllcustomersComponent } from './components/allcustomers/allcustomers.component';
import { SearchPgComponent } from './components/common/search-pg/search-pg.component';
import { CardsComponent } from './components/cards/cards.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { AcCustomersComponent } from './components/Account/ac-customers/ac-customers.component';
import { AcEmployeesComponent } from './components/Account/ac-employees/ac-employees.component';
import { AccountsComponent } from './components/Account/accounts/accounts.component';
import { CreatePgComponent } from './components/create-pg/create-pg.component';
import { AccessCardComponent } from './components/common/access-card/access-card.component';
import { DownloadsComponent } from './components/common/downloads/downloads.component';
import { ComplaintBoxComponent } from './components/common/complaint-box/complaint-box.component';
import { CustomerFormComponent } from './customer-form/customer-form.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ModalDialogComponent } from './components/common/modal-dialog/modal-dialog.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { PopupComponent } from './components/common/popup/popup.component';
import { ServiceComponent } from './components/common/service/service.component';
import { StompService } from './service/webSocket/stomp.service';
import { FoodSectionComponent } from './components/common/food-section/food-section.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { CreateFoodMenuComponent } from './components/common/create-food-menu/create-food-menu.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { DateRangePickerModule } from '@syncfusion/ej2-angular-calendars';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { ConfigAggrementsComponent } from './components/common/config-aggrements/config-aggrements.component';
import { CreateAggrementsComponent } from './components/common/create-aggrements/create-aggrements.component';
import { ElecBillGeneratorComponent } from './components/common/elec-bill-generator/elec-bill-generator.component';
import { EditCustomerDataComponent } from './components/customer/edit-customer-data/edit-customer-data.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PgMasterPowerFlowComponent } from './components/products-components/pg-master-power-flow/pg-master-power-flow.component';
import { PgMasterGuardComponent } from './components/products-components/pg-master-guard/pg-master-guard.component';
import { PgMasterWashingCtrlComponent } from './components/products-components/pg-master-washing-ctrl/pg-master-washing-ctrl.component';

@NgModule({
  declarations: [
    AppComponent,
    BodyComponent,
    SidenavComponent,
    DashboardComponent,
    ProductsComponent,
    CustomersComponent,
    AccountsComponent,
    AccessComponent,
    SubscriptionComponent,
    TicketComponent,
    SettingsComponent,
    ProfileComponent,
    HeaderComponent,
    AddComponent,
    UpdateComponent,
    DeleteComponent,
    EnableComponent,
    DisableComponent,
    AssignComponent,
    CheckOutComponent,
    ReportsComponent,
    LoginComponent,
    SignupComponent,
    InitialDesktopComponent,
    FooterComponent,
    TopnavComponent,
    AboutComponent,
    ContactComponent,
    ProductComponent,
    ForbiddenComponent,
    SpinnerComponent,
    CarouselComponent,
    NotfoundComponent,
    InternalServerComponent,
    CommonComponent,
    NodataaviliableComponent,
    CollectionsComponent,
    DueComponent,
    SublevelMenuComponent,
    AllcustomersComponent,
    SearchPgComponent,
    CardsComponent,
    NotificationsComponent,
    AcCustomersComponent,
    AcEmployeesComponent,
    CreatePgComponent,
    AccessCardComponent,
    DownloadsComponent,
    ComplaintBoxComponent,
    CustomerFormComponent,
    ModalDialogComponent,
    PopupComponent,
    ServiceComponent,
    FoodSectionComponent,
    CreateFoodMenuComponent,
    ConfigAggrementsComponent,
    CreateAggrementsComponent,
    ElecBillGeneratorComponent,
    EditCustomerDataComponent,
    PgMasterPowerFlowComponent,
    PgMasterGuardComponent,
    PgMasterWashingCtrlComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    OverlayModule,
    CdkMenuModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    ToastrModule.forRoot(),
    CarouselModule,
    MatToolbarModule,
    NgToastModule,
    NgConfirmModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatPaginatorModule,
    MatGridListModule,
    NgxMaterialTimepickerModule,
    DateRangePickerModule,
    MatProgressBarModule,
    FlexLayoutModule
  ],
  providers: [StompService],
  bootstrap: [AppComponent]
})
export class AppModule { }
