import { Component } from '@angular/core';

interface PanelStates {
  notificationPanelOpenState: boolean;
  languagePanelOpenState: boolean;
  themePanelOpenState: boolean;
}

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent {

  accessControls = [
    { label: 'Allow Add Notification', value: false },
    { label: 'Allow Update Notification', value: false },
    { label: 'Allow Delete Notification', value: false },
    { label: 'Allow Enable Notification', value: false },
    { label: 'Allow Disable Notification', value: false },
    { label: 'Don\'t Allow Any Notification', value: false },
  ];

  languageControls = [
    { label: 'Marathi', value: false },
    { label: 'Hindi', value: false },
    { label: 'English', value: false },
    { label: 'Kannada', value: false }
  ];

  themeControls = [
    { label: 'Dark', value: false },
    { label: 'Default', value: false }
  ];

  generalSettingsControls = [
    { label: 'Enable Aggrements', value: false },
    { label: 'Enable SMS', value: false }
  ];

  panelStates: PanelStates = {
    notificationPanelOpenState: false,
    languagePanelOpenState: false,
    themePanelOpenState: false,
  };

  notificationPanelOpenState = false;
  languagePanelOpenState = false;
  themesPanelOpenState = false;
  generalSettingsPanelOpenState = false;

  togglePanel(panelName: string): void {
    if (panelName === 'notification') {
      this.notificationPanelOpenState = !this.notificationPanelOpenState;
      this.languagePanelOpenState = false;
      this.themesPanelOpenState = false;
      this.generalSettingsPanelOpenState = false;
    } else if (panelName === 'language') {
      this.notificationPanelOpenState = false;
      this.languagePanelOpenState = !this.languagePanelOpenState;
      this.themesPanelOpenState = false;
      this.generalSettingsPanelOpenState = false;
    } else if (panelName === 'theme') {
      this.notificationPanelOpenState = false;
      this.languagePanelOpenState = false;
      this.themesPanelOpenState = !this.themesPanelOpenState;
      this.generalSettingsPanelOpenState = false;
    } else if (panelName === 'generalSettings') {
      this.notificationPanelOpenState = false;
      this.languagePanelOpenState = false;
      this.themesPanelOpenState = false;
      this.generalSettingsPanelOpenState = !this.generalSettingsPanelOpenState;
    }
  }
}
