export class Constants {

  // base url
  static readonly BASE_URI: string = 'https://pgmaster-production.up.railway.app';
  // static readonly BASE_URI: string = 'http://localhost:8011';
 
  // end points
  static readonly AUTH: string = '/v1/api/auth';
  static readonly SIGN_UP: string = '/v1/api/pg-admin';
  static readonly FETCH_ALL_CUSTOMERS: string = '/v1/api/pg-user/assigned/users';
  static readonly FETCH_UNPAID_CUSTOMERS: string = '/v1/api/pg-user/list';
  static readonly FETCH_ROOM_NUMBER: string = '/v1/api/custom';
  static readonly FETCH_ALL_PG: string = '/v1/api/search/pg';
  static readonly CREATE_PG: string = '';
  static readonly GET_ALL_TRANSACTION: string = '/v1/api/payment/tran/list';
  static readonly GET_ALL_EMP: string = '/v1/api/pg-employee/list';
  static readonly GET_ALL_CARDS: string = '/v1/api/custom/cards';
  static readonly GET_ALL_ACCOUNT_DATA: string = '';
  static readonly GET_CUST_BY_ID: string = '/v1/api/pg-user';
  static readonly CREATE_ENQURIE: string = '/v1/api/praj-tech';
  static readonly FETCH_ALL_ROOMS: string = '/v1/api/pg-room/list';
  static readonly FETCH_NOTIFICATION_COUNT: string = '/v1/api/notification/count';
  static readonly FETCH_TRANSACTION_DATA: string = '/v1/api/payment/download';
  static readonly AGREEMENT_DATA: string = '/v1/api/pdf/agreement';
  static readonly CREATE_TANDC: string = '/v1/api/term-conditions';
  static readonly FETCH_TANDC: string = '/v1/api/term-conditions';
  static readonly UPDATE_TANDC: string = '/v1/api/term-conditions/update-t&c';
  static readonly CREATE_PENALTY: string = '/v1/api/term-conditions/charges-penalty';
  static readonly FETCH_PENALTY: string = '/v1/api/term-conditions/charges-penalty';
  static readonly UPDATE_PENALTY: string = '/v1/api/term-conditions/charges-penalty/update';
  static readonly DOWNLOAD_TRX: string = '/v1/api/pdf/transaction-rec';
}