import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';

interface PanelStates {
  createAggreementPanelOpenState: boolean;
}

@Component({
  selector: 'app-config-aggrements',
  templateUrl: './config-aggrements.component.html',
  styleUrls: ['./config-aggrements.component.scss']
})
export class ConfigAggrementsComponent implements AfterViewInit {

  scrollableContent = false;
  @ViewChild('panelContent') panelContent?: ElementRef<HTMLDivElement>;

  ngAfterViewInit(): void {
    this.checkPanelHeight();
  }

  panelStates: PanelStates = {
    createAggreementPanelOpenState: false,
  };

  createAggreementPanelOpenState = false;

  togglePanel(panelName: string): void {
    if (panelName === 'createAggreement') {
      this.createAggreementPanelOpenState = !this.createAggreementPanelOpenState;
    }
  }

  checkPanelHeight(): void {
    if (this.panelContent) {
      const contentHeight = this.panelContent.nativeElement.scrollHeight;
      const maxHeight = 400;
      this.scrollableContent = contentHeight > maxHeight;
    }
  }
}
