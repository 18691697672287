import { Component } from '@angular/core';

@Component({
  selector: 'app-pg-master-power-flow',
  templateUrl: './pg-master-power-flow.component.html',
  styleUrls: ['./pg-master-power-flow.component.scss']
})
export class PgMasterPowerFlowComponent {

}
