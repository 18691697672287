import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import jsPDF from 'jspdf';
import { Customer } from 'src/app/models/Customer';
import { UserTransaction } from 'src/app/models/UserTransaction';
import { Transaction } from 'src/app/models/payments/Transaction';
import { Apiservice } from 'src/app/service/api/apiservice.service';

@Component({
  selector: 'app-ac-customers',
  templateUrl: './ac-customers.component.html',
  styleUrls: ['./ac-customers.component.scss'],
  providers: [DatePipe],
})
export class AcCustomersComponent implements OnInit, AfterViewInit {

  selectedStartDate: Date | null = null;
  selectedEndDate: Date | null = null;

  displayedColumns: string[] = [ 
    'serialNumber',
    'name',
    'transactionId', 
    'paidAmount',
    'paidDate',
    'nextPayDate',
    'daysLeft',
    'status',
    'upi'
  ];

  dataSource: MatTableDataSource<Transaction>;
  isLoading: boolean = false;
  isFiltering: boolean = false;
  noDataAvailable: boolean = false;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(private apiService: Apiservice, private datePipe: DatePipe) {
    this.dataSource = new MatTableDataSource<Transaction>([]);
  } 

  ngOnInit(): void {
    this.fetchTransactionHistory();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  fetchTransactionHistory() {
    this.isLoading = true;
    const minimumLoadingTime = 500;
  
    setTimeout(() => {
      this.apiService.fetchAllPGCustomerTransactions().subscribe(
        (response: any) => {
          console.log(response);
          const transaction: Transaction[] = response.recordsList;
          this.dataSource.data = transaction;
          this.noDataAvailable = transaction.length === 0;
        },
        (error) => {
          console.error('Error fetching data', error);
        },
        () => {
          this.isLoading = false;
        }
      );
    }, minimumLoadingTime);
  }

  getSerialNumber(row: any): number {
    const index = this.dataSource.data.indexOf(row);
    return index + 1;
  }

  getDaysUntilNextPayment(nextPayment: any): number {
    if (!nextPayment) {
      return 0;
    }
    
    const nextPaymentDate = new Date(nextPayment);  
    if (isNaN(nextPaymentDate.getTime())) {
      return 0;
    }
  
    const currentDate = new Date();
    const timeDifference = nextPaymentDate.getTime() - currentDate.getTime();
    return Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  }  

  isTextRed(nextPayment: any): boolean {
    return this.getDaysUntilNextPayment(nextPayment) <= 5;
  }

  getFormattedDate(timestamp: number): string {
    const slash = '/';
    const date = new Date(timestamp);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();

    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const amPm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');
    // return `${day}${slash}${month}${slash}${year} ${formattedHours}${':'}${minutes} ${amPm}`;
    return `${month}${slash}${day}${slash}${year}`;
  }

  formatDate(date: string | null): string {
    if (date) {
      const parsedDate = new Date(date);
      return this.datePipe.transform(parsedDate, 'dd-MM-yyyy') || '';
    } else {
      return '';
    }
  }  

  onDateRangeChange() {
    if (this.selectedStartDate !== null && this.selectedEndDate !== null) {
      this.isFiltering = true;
      const startDate = this.selectedStartDate;
      const endDate = this.selectedEndDate;
  
      const filteredData = this.dataSource.data.filter((customer) => {
        const createdDate = new Date(customer.createdDate);
        return createdDate >= startDate && createdDate <= endDate;
      });
  
      setTimeout(() => {
        this.dataSource.data = filteredData;
        this.isFiltering = false;
      }, 500);
    }
  }

  generateReceipt(transactionId: String) {
    const doc = new jsPDF();
  
    this.apiService.fetchTransactionDetails(transactionId).subscribe(
      (response: any) => {
        console.log(response);
  
        if (response) {
          const transaction: UserTransaction = response;
  
          // Set up the black border for the entire document
          const borderWidth = 10;
          doc.setDrawColor(0); // black
          doc.rect(borderWidth, borderWidth, doc.internal.pageSize.width - 2 * borderWidth, doc.internal.pageSize.height - 2 * borderWidth);
  
          // Add PG Name at the top center
          doc.setFontSize(20);
          const pgName = transaction.pgName;
          const pgNameWidth = doc.getTextWidth(pgName);
          const pgNameXPosition = (doc.internal.pageSize.width - pgNameWidth) / 2;
          doc.text(pgName, pgNameXPosition, 19 + borderWidth);
  
          // Add PG Address below PG Name with reduced space
          doc.setFontSize(12);
          const pgAddress = transaction.pgAddress;
          const pgAddressWidth = doc.getTextWidth(pgAddress);
          const pgAddressXPosition = (doc.internal.pageSize.width - pgAddressWidth) / 2;
          doc.text(pgAddress, pgAddressXPosition, 25 + borderWidth);
  
          // Draw a line below PG Address
          const lineYPosition1 = 31 + borderWidth;
          doc.setLineWidth(0.3);
          doc.line(borderWidth, lineYPosition1, doc.internal.pageSize.width - borderWidth, lineYPosition1);
  
          // Add PG Owner and Manager Details
          const pgDetailsYPosition = lineYPosition1 + 10;
          const pgDetailsSpacing = 12; // Adjust this value to achieve equal spacing
          doc.setFontSize(14);
          doc.text('PG Details', 20, pgDetailsYPosition);
          doc.setFontSize(12);
          doc.text(`Mail: ${transaction.pgMail}`, 20, pgDetailsYPosition + pgDetailsSpacing);
          doc.text(`Phone: ${transaction.pgPhone}`, 20, pgDetailsYPosition + 2 * pgDetailsSpacing);
          doc.text(`Manager: ${transaction.payeeName}`, 20, pgDetailsYPosition + 3 * pgDetailsSpacing);
          doc.text(`Owner: ${transaction.payeeName}`, 20, pgDetailsYPosition + 4 * pgDetailsSpacing);
  
          // Draw a line below PG Details
          const lineYPosition2 = pgDetailsYPosition + 5 * pgDetailsSpacing;
          doc.setLineWidth(0.3);
          doc.line(borderWidth, lineYPosition2, doc.internal.pageSize.width - borderWidth, lineYPosition2);
  
          // Add User Details between two lines
          const userSectionYPosition = lineYPosition2 + 10;
          const userSectionSpacing = 12; // Adjust this value to achieve equal spacing
          doc.setFontSize(14);
          doc.text('User Details', 20, userSectionYPosition);
          doc.setFontSize(12);
          const userDetailsLine1 = `Name: ${transaction.firstName} ${transaction.lastName}    Email: ${transaction.mail} Room Number: ${transaction.roomNumber}`;
          const userDetailsLine2 = `Phone Number: ${transaction.phoneNumber}`;
          doc.text(userDetailsLine1, 20, userSectionYPosition + userSectionSpacing);
          doc.text(userDetailsLine2, 20, userSectionYPosition + 2 * userSectionSpacing);
  
          // Draw a line below User Details
          const lineYPosition3 = userSectionYPosition + 3 * userSectionSpacing;
          doc.setLineWidth(0.3);
          doc.line(borderWidth, lineYPosition3, doc.internal.pageSize.width - borderWidth, lineYPosition3);
  
          // Add Transaction Details between two lines
          const transactionSectionYPosition = lineYPosition3 + 10;
          const transactionSectionSpacing = 12; // Adjust this value to achieve equal spacing
          doc.setFontSize(14);
          doc.text('Transaction Details', 20, transactionSectionYPosition);
          doc.setFontSize(12);
          doc.text(`Transaction ID: ${transaction.transactionNumber}`, 20, transactionSectionYPosition + transactionSectionSpacing);
          doc.text(`Paid Amount: ${transaction.paidAmount}`, 20, transactionSectionYPosition + 2 * transactionSectionSpacing);
          doc.text(`Paid Date: ${this.formatDate(transaction.paidDate)}`, 20, transactionSectionYPosition + 3 * transactionSectionSpacing);
          doc.text(`Payment Status: ${transaction.paymentStatus}`, 20, transactionSectionYPosition + 4 * transactionSectionSpacing);
          doc.text(`Next Payment: ${this.formatDate(transaction.nextPayment)}`, 20, transactionSectionYPosition + 5 * transactionSectionSpacing);
  
          // Draw a line below Transaction Details
          const lineYPosition5 = transactionSectionYPosition + 6 * transactionSectionSpacing;
          doc.setLineWidth(0.3);
          doc.line(borderWidth, lineYPosition5, doc.internal.pageSize.width - borderWidth, lineYPosition5);
  
        } else {
          console.error('Invalid response or missing data');
        }
      },
      (error) => {
        console.error('Error fetching data', error);
      },
      () => {
        this.isLoading = false;
  
        // Save the PDF after processing
        doc.save(`${transactionId}_receipt.pdf`);
      }
    );
  }  
}
