<div class="con">
    <div class="loader-overlay" *ngIf="isLoading">
      <div class="loader-content">
        <mat-spinner diameter="50"></mat-spinner>
        <br>
        <h3>Please wait, we're fetching details..</h3>
      </div>
    </div>

    <mat-card class="hover-for-panel">
        <!--For Panelty/Charges-->
        <mat-expansion-panel (opened)="togglePanel('createAggreement')" [expanded]="createAggreementPanelOpenState" class="hover-for-panel">
            <mat-expansion-panel-header>
              <mat-panel-title> Mention Your Panelty/Charges (Optional) </mat-panel-title>
            </mat-expansion-panel-header>
            
            <form #penaltyForm="ngForm" *ngIf="penalty">
              
              <div class="row">
                <div class="col">
                  <input ngModel gModel name="numberOf" hidden value="5">
                    <mat-form-field appearance="outline" class="mat-fields">
                        <mat-label>Panelty/Charges Name</mat-label>
                        <input matInput placeholder="Enter Your Panelty/Charges Name Here.." #input ngModel [(ngModel)]="penalty.chargeName1" name="chargeName1">
                        <span matSuffix style="padding-right: 10px;">
                          <mat-icon>menu</mat-icon>
                        </span>
                    </mat-form-field>
              </div>

              <div class="col">
                    <mat-form-field appearance="outline">
                        <mat-label> Amount</mat-label>
                        <input matInput placeholder="Enter Amount Here.." #input ngModel [(ngModel)]="penalty.chargeAmount1" name="chargeAmount1">
                        <span matSuffix style="padding-right: 10px;">
                          <mat-icon>menu</mat-icon>
                        </span>
                    </mat-form-field>
                </div>
              </div>

              <div class="row">
                <div class="col">
                    <mat-form-field appearance="outline" class="mat-fields">
                        <mat-label>Panelty/Charges Name</mat-label>
                        <input matInput placeholder="Enter Your Panelty/Charges Name Here.." #input ngModel [(ngModel)]="penalty.chargeName2" name="chargeName2">
                        <span matSuffix style="padding-right: 10px;">
                          <mat-icon>menu</mat-icon>
                        </span>
                    </mat-form-field>
                </div>

                <div class="col">
                    <mat-form-field appearance="outline">
                        <mat-label> Amount</mat-label>
                        <input matInput placeholder="Enter Amount Here" #input ngModel [(ngModel)]="penalty.chargeAmount2" name="chargeAmount2">
                        <span matSuffix style="padding-right: 10px;">
                          <mat-icon>menu</mat-icon>
                        </span>
                    </mat-form-field>
                </div>
              </div>

              <div class="row">
                <div class="col">
                    <mat-form-field appearance="outline" class="mat-fields">
                        <mat-label>Panelty/Charges Name</mat-label>
                        <input matInput placeholder="Enter Your Panelty/Charges Name Here.." #input ngModel [(ngModel)]="penalty.chargeName3" name="chargeName3">
                        <span matSuffix style="padding-right: 10px;">
                          <mat-icon>menu</mat-icon>
                        </span>
                    </mat-form-field>
                </div>

                <div class="col">
                    <mat-form-field appearance="outline">
                        <mat-label> Amount</mat-label>
                        <input matInput placeholder="Enter Amount Here" #input ngModel [(ngModel)]="penalty.chargeAmount3" name="chargeAmount3">
                        <span matSuffix style="padding-right: 10px;">
                          <mat-icon>menu</mat-icon>
                        </span>
                    </mat-form-field>
                </div>
              </div>

              <div class="row">
                <div class="col">
                    <mat-form-field appearance="outline" class="mat-fields">
                        <mat-label>Panelty/Charges Name</mat-label>
                        <input matInput placeholder="Enter Your Panelty/Charges Name Here.." #input ngModel [(ngModel)]="penalty.chargeName4" name="chargeName4">
                        <span matSuffix style="padding-right: 10px;">
                          <mat-icon>menu</mat-icon>
                        </span>
                    </mat-form-field>
                </div>

                <div class="col">
                    <mat-form-field appearance="outline">
                        <mat-label> Amount</mat-label>
                        <input matInput placeholder="Enter Amount Here" #input ngModel [(ngModel)]="penalty.chargeAmount4" name="chargeAmount4">
                        <span matSuffix style="padding-right: 10px;">
                          <mat-icon>menu</mat-icon>
                        </span>
                    </mat-form-field>
                </div>
              </div>

              <div class="row">
                <div class="col">
                    <mat-form-field appearance="outline" class="mat-fields">
                        <mat-label>Panelty/Charges Name</mat-label>
                        <input matInput placeholder="Enter Your Panelty/Charges Name Here.." #input ngModel [(ngModel)]="penalty.chargeName5" name="chargeName5">
                        <span matSuffix style="padding-right: 10px;">
                          <mat-icon>menu</mat-icon>
                        </span>
                    </mat-form-field>
                </div>

                <div class="col">
                    <mat-form-field appearance="outline">
                        <mat-label> Amount</mat-label>
                        <input matInput placeholder="Enter Amount Here" #input ngModel [(ngModel)]="penalty.chargeAmount5" name="chargeAmount5">
                        <span matSuffix style="padding-right: 10px;">
                          <mat-icon>menu</mat-icon>
                        </span>
                    </mat-form-field>
                </div>
              </div>

            <button *ngIf="numberOfPenlty == 5 && penalty?.id == null" class="save-button" mat-raised-button color="accent" type="submit" (click)="submitPenaltyForm(penaltyForm, 5)">Save Term/Condition</button>
            <button *ngIf="numberOfPenlty == 5 && (penalty.id != null && penalty?.numberOf == 5)" class="save-button" mat-raised-button color="accent" type="submit" (click)="submitUpdatePenaltyForm(penaltyForm, penalty.id)">Update Penalty/Charges</button>
            </form>

            <form #penaltyForm="ngForm" *ngIf="!penalty">
              
              <div class="row">
                <div class="col">
                  <input ngModel gModel name="numberOf" hidden value="5">
                    <mat-form-field appearance="outline" class="mat-fields">
                        <mat-label>Panelty/Charges Name</mat-label>
                        <input matInput placeholder="Enter Your Panelty/Charges Name Here.." #input ngModel name="chargeName1">
                        <span matSuffix style="padding-right: 10px;">
                          <mat-icon>menu</mat-icon>
                        </span>
                    </mat-form-field>
              </div>

              <div class="col">
                    <mat-form-field appearance="outline">
                        <mat-label> Amount</mat-label>
                        <input matInput placeholder="Enter Amount Here.." #input ngModel name="chargeAmount1">
                        <span matSuffix style="padding-right: 10px;">
                          <mat-icon>menu</mat-icon>
                        </span>
                    </mat-form-field>
                </div>
              </div>

              <div class="row">
                <div class="col">
                    <mat-form-field appearance="outline" class="mat-fields">
                        <mat-label>Panelty/Charges Name</mat-label>
                        <input matInput placeholder="Enter Your Panelty/Charges Name Here.." #input ngModel name="chargeName2">
                        <span matSuffix style="padding-right: 10px;">
                          <mat-icon>menu</mat-icon>
                        </span>
                    </mat-form-field>
                </div>

                <div class="col">
                    <mat-form-field appearance="outline">
                        <mat-label> Amount</mat-label>
                        <input matInput placeholder="Enter Amount Here" #input ngModel name="chargeAmount2">
                        <span matSuffix style="padding-right: 10px;">
                          <mat-icon>menu</mat-icon>
                        </span>
                    </mat-form-field>
                </div>
              </div>

              <div class="row">
                <div class="col">
                    <mat-form-field appearance="outline" class="mat-fields">
                        <mat-label>Panelty/Charges Name</mat-label>
                        <input matInput placeholder="Enter Your Panelty/Charges Name Here.." #input ngModel name="chargeName3">
                        <span matSuffix style="padding-right: 10px;">
                          <mat-icon>menu</mat-icon>
                        </span>
                    </mat-form-field>
                </div>

                <div class="col">
                    <mat-form-field appearance="outline">
                        <mat-label> Amount</mat-label>
                        <input matInput placeholder="Enter Amount Here" #input ngModel name="chargeAmount3">
                        <span matSuffix style="padding-right: 10px;">
                          <mat-icon>menu</mat-icon>
                        </span>
                    </mat-form-field>
                </div>
              </div>

              <div class="row">
                <div class="col">
                    <mat-form-field appearance="outline" class="mat-fields">
                        <mat-label>Panelty/Charges Name</mat-label>
                        <input matInput placeholder="Enter Your Panelty/Charges Name Here.." #input ngModel name="chargeName4">
                        <span matSuffix style="padding-right: 10px;">
                          <mat-icon>menu</mat-icon>
                        </span>
                    </mat-form-field>
                </div>

                <div class="col">
                    <mat-form-field appearance="outline">
                        <mat-label> Amount</mat-label>
                        <input matInput placeholder="Enter Amount Here" #input ngModel name="chargeAmount4">
                        <span matSuffix style="padding-right: 10px;">
                          <mat-icon>menu</mat-icon>
                        </span>
                    </mat-form-field>
                </div>
              </div>

              <div class="row">
                <div class="col">
                    <mat-form-field appearance="outline" class="mat-fields">
                        <mat-label>Panelty/Charges Name</mat-label>
                        <input matInput placeholder="Enter Your Panelty/Charges Name Here.." #input ngModel name="chargeName5">
                        <span matSuffix style="padding-right: 10px;">
                          <mat-icon>menu</mat-icon>
                        </span>
                    </mat-form-field>
                </div>

                <div class="col">
                    <mat-form-field appearance="outline">
                        <mat-label> Amount</mat-label>
                        <input matInput placeholder="Enter Amount Here" #input ngModel name="chargeAmount5">
                        <span matSuffix style="padding-right: 10px;">
                          <mat-icon>menu</mat-icon>
                        </span>
                    </mat-form-field>
                </div>
              </div>

            <button *ngIf="numberOfPenlty == 5" class="save-button" mat-raised-button color="accent" type="submit" (click)="submitPenaltyForm(penaltyForm, 5)">Save Term/Condition</button>
            </form>

            <!-- <div class="toggles" style="padding: 20px;">
              <button mat-button (click)="setNumberOfPenlty(5)" name="numberOf">5</button>
            </div> -->
          </mat-expansion-panel>

          <div class="spacer"></div>

          <!--For Terms/Conditions-->
          <mat-expansion-panel (opened)="togglePanel('createTermsAndConditions')" [expanded]="createTermsAndConditionsPanelOpenState" class="hover-for-panel">
            <mat-expansion-panel-header>
              <mat-panel-title> Create Your Terms/Conditions </mat-panel-title>
            </mat-expansion-panel-header>

            <form #tAndCForm="ngForm" *ngIf="tAndC">
              <div class="row" *ngIf="numberOfTerms == 5">
                <input ngModel gModel *ngIf="tAndC.id == 0" name="numberOf" hidden value="5">
                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition First</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel [(ngModel)]="tAndC.first" name="first">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Second</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel [(ngModel)]="tAndC.second" name="second">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Third</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel [(ngModel)]="tAndC.third" name="third">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Fourth</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel [(ngModel)]="tAndC.fourth" name="fourth">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Fifth</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel [(ngModel)]="tAndC.fifth" name="fifth">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>
              </div>

              <button *ngIf="numberOfTerms == 5 && tAndC.id == null" class="save-button" mat-raised-button color="accent" type="submit" (click)="submitForm(tAndCForm, 5)">Save Term/Condition</button>
              <button *ngIf="numberOfTerms == 5 && (tAndC.id != null && tAndC.numberOf == 5)" class="save-button" mat-raised-button color="accent" type="submit" (click)="updateForm(tAndCForm, tAndC.id)">Update Term/Condition</button>
            </form>

            <form #tAndCForm="ngForm" *ngIf="tAndC">
              <div class="row" *ngIf="(numberOfTerms === 10)">
                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition First</mat-label>
                  <input ngModel name="numberOf" *ngIf="tAndC.id == 0" hidden value="10">
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel [(ngModel)]="tAndC.first" name="first">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Second</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel [(ngModel)]="tAndC.second" name="second">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Third</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel [(ngModel)]="tAndC.third" name="third">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Fourth</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel [(ngModel)]="tAndC.fourth" name="fourth">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Fifth</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel [(ngModel)]="tAndC.fifth" name="fifth">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Sixth</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel [(ngModel)]="tAndC.sixth" name="sixth">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Seven</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel [(ngModel)]="tAndC.seven" name="seven">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Eight</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel [(ngModel)]="tAndC.eight" name="eight">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Nine</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel [(ngModel)]="tAndC.nine" name="nine">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Ten</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel [(ngModel)]="tAndC.ten" name="ten">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>
              </div>

              <button *ngIf="numberOfTerms == 10 && tAndC.id == null" class="save-button" mat-raised-button color="accent" type="submit" (click)="submitForm(tAndCForm, 10)">Save Term/Condition</button>
              <button *ngIf="numberOfTerms == 10 && (tAndC.id != null && tAndC.numberOf == 5)" class="save-button" mat-raised-button color="accent" type="submit" (click)="updateForm(tAndCForm, tAndC.id)">Update Term/Condition</button>
            </form>

            <form #tAndCForm="ngForm" *ngIf="tAndC">
              <div class="row" *ngIf="numberOfTerms == 15">
                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition First</mat-label>
                  <input ngModel name="numberOf" hidden *ngIf="tAndC.id == 0" value="15">
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel [(ngModel)]="tAndC.first" name="first">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Second</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel [(ngModel)]="tAndC.second" name="second">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Third</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel [(ngModel)]="tAndC.third" name="third">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Fourth</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel [(ngModel)]="tAndC.fourth" name="fourth">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Fifth</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel [(ngModel)]="tAndC.fifth" name="fifth">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Sixth</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel [(ngModel)]="tAndC.sixth" name="sixth">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Seven</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel [(ngModel)]="tAndC.seven" name="seven">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Eight</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel [(ngModel)]="tAndC.eight" name="eight">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Nine</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel [(ngModel)]="tAndC.nine" name="nine">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Ten</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel [(ngModel)]="tAndC.ten" name="ten">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Eleven</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel [(ngModel)]="tAndC.eleven" name="eleven">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Twelve</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel [(ngModel)]="tAndC.twelve" name="twelve">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Thirteen</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel [(ngModel)]="tAndC.thirteen" name="thirteen">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Fourteen</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel [(ngModel)]="tAndC.fourteen" name="fourteen">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Fifteen</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel [(ngModel)]="tAndC.fifteen" name="fifteen">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>
              </div>

              <button *ngIf="tAndC.numberOf == 15 && tAndC.id == null" class="save-button" mat-raised-button color="accent" type="submit" (click)="submitForm(tAndCForm, 15)">Save Term/Condition</button>
              <button *ngIf="numberOfTerms == 15 && (tAndC.id != null && tAndC.numberOf == 5)" class="save-button" mat-raised-button color="accent" type="submit" (click)="updateForm(tAndCForm, tAndC.id)">Update Term/Condition</button>
            </form>

            <form #tAndCForm="ngForm" *ngIf="tAndC">
              <div class="row" *ngIf="numberOfTerms == 20">
                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition First</mat-label>
                  <input ngModel *ngIf="tAndC.id == 0" name="numberOf" hidden value="20">
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel [(ngModel)]="tAndC.first" name="first">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Second</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel [(ngModel)]="tAndC.second" name="second">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Third</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel [(ngModel)]="tAndC.third" name="third">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Fourth</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel [(ngModel)]="tAndC.fourth" name="fourth">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Fifth</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel [(ngModel)]="tAndC.fifth" name="fifth">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Sixth</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel [(ngModel)]="tAndC.sixth" name="sixth">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Seven</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel [(ngModel)]="tAndC.seven" name="seven">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Eight</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel [(ngModel)]="tAndC.eight" name="eight">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Nine</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel [(ngModel)]="tAndC.nine" name="nine">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Ten</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel [(ngModel)]="tAndC.ten" name="ten">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Eleven</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel [(ngModel)]="tAndC.eleven" name="eleven">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Twelve</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel [(ngModel)]="tAndC.twelve" name="twelve">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Thirteen</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel [(ngModel)]="tAndC.thirteen" name="thirteen">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Fourteen</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel [(ngModel)]="tAndC.fourteen" name="fourteen">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Fifteen</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel [(ngModel)]="tAndC.fifteen" name="fifteen">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Sixteen</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel [(ngModel)]="tAndC.sixteen" name="sixteen">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Seventeen</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel [(ngModel)]="tAndC.seventeen" name="seventeen">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Eighten</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel [(ngModel)]="tAndC.eighteen" name="eighteen">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Nineteen</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel [(ngModel)]="tAndC.nineteen" name="nineteen">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Twenty</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel [(ngModel)]="tAndC.twenty" name="twenty">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>
              </div>
              <button *ngIf="tAndC.numberOf == 20 && tAndC.id == null" class="save-button" mat-raised-button color="accent" type="submit" (click)="submitForm(tAndCForm, 20)">Save Term/Condition</button>
              <button *ngIf="numberOfTerms == 20 && (tAndC.id != null && tAndC.numberOf == 5)" class="save-button" mat-raised-button color="accent" type="submit" (click)="updateForm(tAndCForm, tAndC.id)">Update Term/Condition</button>
            </form>

            <form #tAndCForm="ngForm" *ngIf="!tAndC">
              <div class="row" *ngIf="numberOfTerms == 5">
                <input ngModel gModel name="numberOf" hidden value="5">
                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition First</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel name="first">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Second</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel name="second">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Third</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel name="third">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Fourth</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel name="fourth">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Fifth</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel name="fifth">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>
              </div>

              <button *ngIf="numberOfTerms == 5" class="save-button" mat-raised-button color="accent" type="submit" (click)="submitForm(tAndCForm, 5)">Save Term/Condition</button>
            </form>

            <form #tAndCForm="ngForm" *ngIf="!tAndC">
              <div class="row" *ngIf="(numberOfTerms === 10)">
                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition First</mat-label>
                  <input ngModel name="numberOf" hidden value="10">
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel name="first">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Second</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel name="second">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Third</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel name="third">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Fourth</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel name="fourth">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Fifth</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel name="fifth">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Sixth</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel name="sixth">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Seven</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel name="seven">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Eight</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel name="eight">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Nine</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel name="nine">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Ten</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel name="ten">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>
              </div>

              <button *ngIf="numberOfTerms == 10" class="save-button" mat-raised-button color="accent" type="submit" (click)="submitForm(tAndCForm, 10)">Save Term/Condition</button>
            </form>

            <form #tAndCForm="ngForm" *ngIf="!tAndC">
              <div class="row" *ngIf="numberOfTerms == 15">
                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition First</mat-label>
                  <input ngModel name="numberOf" hidden value="15">
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel name="first">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Second</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel name="second">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Third</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel name="third">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Fourth</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel name="fourth">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Fifth</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel name="fifth">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Sixth</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel name="sixth">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Seven</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel name="seven">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Eight</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel name="eight">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Nine</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel name="nine">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Ten</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel name="ten">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Eleven</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel name="eleven">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Twelve</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel name="twelve">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Thirteen</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel name="thirteen">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Fourteen</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel name="fourteen">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Fifteen</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel name="fifteen">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>
              </div>

              <button *ngIf="numberOfTerms == 15" class="save-button" mat-raised-button color="accent" type="submit" (click)="submitForm(tAndCForm, 15)">Save Term/Condition</button>
            </form>

            <form #tAndCForm="ngForm" *ngIf="!tAndC">
              <div class="row" *ngIf="numberOfTerms == 20">
                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition First</mat-label>
                  <input ngModel name="numberOf" hidden value="20">
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel name="first">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Second</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel name="second">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Third</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel name="third">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Fourth</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel name="fourth">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Fifth</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel name="fifth">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Sixth</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel name="sixth">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Seven</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel name="seven">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Eight</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel name="eight">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Nine</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel name="nine">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Ten</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel name="ten">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Eleven</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel name="eleven">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Twelve</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel name="twelve">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Thirteen</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel name="thirteen">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Fourteen</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel name="fourteen">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Fifteen</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel name="fifteen">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Sixteen</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel name="sixteen">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Seventeen</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel name="seventeen">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Eighten</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel name="eighteen">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Nineteen</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel name="nineteen">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Term/Condition Twenty</mat-label>
                  <input matInput placeholder="Enter Your Term/Condition Here" ngModel name="twenty">
                  <span matSuffix style="padding-right: 10px;">
                    <mat-icon>menu</mat-icon>
                  </span>
                </mat-form-field>
              </div>
              <button *ngIf="numberOfTerms == 20" class="save-button" mat-raised-button color="accent" type="submit" (click)="submitForm(tAndCForm, 20)">Save Term/Condition</button>
            </form>

            <div class="toggles" style="padding: 20px;">
              <button mat-button (click)="setNumberOfTerms(5)" name="numberOf">5</button>
              <button mat-button (click)="setNumberOfTerms(10)" name="numberOf">10</button>
              <button mat-button (click)="setNumberOfTerms(15)" name="numberOf">15</button>
              <button mat-button (click)="setNumberOfTerms(20)" name="numberOf">20</button>
            </div>

          </mat-expansion-panel>
                              
    </mat-card>
</div>