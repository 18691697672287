<div class="con">
  <div class="loader-overlay" *ngIf="loading">
    <div class="loader-content">
      <mat-spinner diameter="50"></mat-spinner>
      <br>
      <h3>Please wait, we're fetching your subscription details..</h3>
    </div>
</div>  

<div class="row">
    <div class="col-sm-12 col-md-14">
      <div class="card">
        <div class="card-body">
          <h1 class="card-title"> My Subscription Details</h1>
          <ul class="list-group list-group-light list-group-small m-3">
            <li class="list-item">
              <i class="fas fa-user"></i> <b>&nbsp;&nbsp;&nbsp;Account Holder:</b> {{ firstName }}
            </li>
            <li class="list-item">
              <i class="fas fa-building"></i> <b>&nbsp;&nbsp;&nbsp;PG Name:</b> {{ pgName }}
            </li>
            <li class="list-item">
              <i class="fas fa-map-marker-alt"></i> <b>&nbsp;&nbsp;&nbsp;Address:</b> {{ subscriptionData.address }}
            </li>
            <li class="list-item">
              <i class="fas fa-phone"></i> <b>&nbsp;&nbsp;&nbsp;PG Phone:</b> {{ pgPhone }}
            </li>
            <li class="list-item">
              <i class="fas fa-envelope"></i> <b>&nbsp;&nbsp;&nbsp;PG Mail:</b> {{ pgMail }}
            </li>
            <li class="list-item">
              <i class="fas fa-award"></i><b>&nbsp;&nbsp;&nbsp;Current Subscription(Type):</b> {{ subscriptionData.currentSubscription }}
            </li>
            <li class="list-item">
              <i class="fas fa-calendar"></i> <b>&nbsp;&nbsp;&nbsp;Subscription Taken Date:</b> {{ subscriptionData.subscriptionTakenDate }}
            </li>
            <li class="list-item">
              <i class="fas fa-calendar-alt"></i> <b>&nbsp;&nbsp;&nbsp;Subscription Renewal Date:</b> {{ subscriptionData.nextSubscriptionDate }}
            </li>
            <li class="list-item">
              <i class="fas fa-money-bill-wave"></i> <b>&nbsp;&nbsp;&nbsp;Paid Amount:</b> {{ subscriptionData.paidAmount }}
            </li>
          </ul>
          <p class="text-center">
            <a href="#" class="btn btn-primary">
              <i class="fas fa-sync-alt"></i> Renew Subscription
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
<app-cards></app-cards>
</div>