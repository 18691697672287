import { Component } from '@angular/core';

@Component({
  selector: 'app-pg-master-guard',
  templateUrl: './pg-master-guard.component.html',
  styleUrls: ['./pg-master-guard.component.scss']
})
export class PgMasterGuardComponent {

}
