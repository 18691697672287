<div class="container mt-2">
    <div class="card-grid row">
      <div class="card col-md-3 m-2" *ngFor="let product of products">
        <mat-radio-group [(ngModel)]="selectedProduct">
            <mat-radio-button [value]="product" class="radio-button"></mat-radio-button>
        </mat-radio-group>

        <div class="card-body">
          <h2 class="card-title">{{ product.title }}</h2>
          <span class="card-text" [ngClass]="{'read-more': product.description.length > 30}">
            {{ product.description }}
            <span *ngIf="product.description.length > 30 && !product.showFullDescription" (click)="toggleDescription(product)" class="learn-more"> Learn More</span>
          </span>
          <span *ngIf="product.showFullDescription" class="card-text">{{ product.description }}</span>
          <ul class="key-features-list">
            <li *ngFor="let feature of product.keyFeatures">
              <i [class]="feature.iconClass"></i> {{ feature.text }}
            </li>
          </ul>
          <div class="row">
            <div class="col">
                <button mat-raised-button color="primary">Enquiry</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  