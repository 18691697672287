import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Apiservice } from 'src/app/service/api/apiservice.service';
import { JwtResolverService } from 'src/app/service/jwt/jwt-resolver.service';
import { StompService } from 'src/app/service/webSocket/stomp.service';
import { Topics } from 'src/app/service/webSocket/topics/Topics';

@Component({
  selector: 'app-downloads',
  templateUrl: './downloads.component.html',
  styleUrls: ['./downloads.component.scss']
})

export class DownloadsComponent implements OnInit {

  notificationCount = 0;
  stompClient: any;
  pgId: string | undefined;
  pgName: string | undefined;

  constructor(
    private router: Router, 
    private stompService: StompService, 
    private http: HttpClient,
    private apiService: Apiservice,
    private resolver: JwtResolverService
  ) {}

  ngOnInit(): void {
    const token = localStorage.getItem('token');
    if(token) {
      this.pgName = this.resolver.getPGName(token);
      this.pgId = this.resolver.getPGID(token);
    }
    
    this.stompService.subscribe(`/card-assign-topic/assigned-card/${this.pgId}`, (message: any): any => {
      console.log('Enable or Disable:', message);
      this.updateFromWebSocket(message);
    });

    this.stompService.subscribe(`/private/enable-disable/${this.pgId}`, (message: any): any => {
      console.log('Enable or Disable:', message);
      this.updateFromWebSocket(message);
    });
  }

  // Update from WebSocket message
  updateFromWebSocket(message: any): void {
    this.notificationCount = message;
    console.log('Enable or Disable:', this.notificationCount);
  }
}
