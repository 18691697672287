import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-complaint-box',
  templateUrl: './complaint-box.component.html',
  styleUrls: ['./complaint-box.component.scss']
})
export class ComplaintBoxComponent implements OnInit {

  isLoading: boolean = true;
  
  constructor() {}
  
  ngOnInit(): void {
    this.notifications;
    setTimeout(() => {
      this.isLoading = false;
    }, 1000); 
  }

  notifications = [
    {
      title: 'Food Not Good',
      customer: 'Kiran Jagtap From A-01',
      desc: 'From 2 Days Dinner is not coming Good',
      time: 'time_stamp',
      code: 1
    }
  ];

  getBackgroundColorAndIcon(code: number): { backgroundColor: string } {
    switch (code) {
      case 1:
      case 7:
        return { backgroundColor: '#90EE90' };
      case 2:
        return { backgroundColor: '#FFA07A' };
      case 3:
        return { backgroundColor: '#F0D330' };
      case 4:
        return { backgroundColor: '#90EE90' };
      case 5:
        return { backgroundColor: '#5CEC5C' };
      case 6:
        return { backgroundColor: '#FFA07A' };
      case 8:
        return { backgroundColor: '#FFA07A' };
      case 9:
        return { backgroundColor: '#5DC9EC' };
      case 10:
        return { backgroundColor: '#FFA07A' };
      default:
        return { backgroundColor: '#D3D3D3' };
    }
  }  
}
