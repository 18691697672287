import { Component } from '@angular/core';

@Component({
  selector: 'app-due',
  templateUrl: './due.component.html',
  styleUrls: ['./due.component.scss']
})
export class DueComponent {

}
