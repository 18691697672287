import { Component, OnInit } from '@angular/core';
import { JwtResolverService } from 'src/app/service/jwt/jwt-resolver.service';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit {

  firstName: string | undefined;
  lastName: string | undefined;
  email: string | undefined;
  phoneNumber: string | undefined;
  pgName: string | undefined;
  pgPhone: string | undefined;
  pgMail: string | undefined;
  userRoles: string | undefined;
  loading = true;
  dataFetched = false;
  
  constructor(private jwtResolverService: JwtResolverService) {}

  subscriptionData = {
    address: 'at Pune',
    currentSubscription: 'FREE TRIAL',
    subscriptionTakenDate: '01 September 2023',
    nextSubscriptionDate: '02 Octomber 2023',
    paidAmount: '6000 Rs.'
  };

  ngOnInit() {
    const token = localStorage.getItem('token');

    if (token) {
      this.firstName = this.jwtResolverService.getFirstName(token);
      this.lastName = this.jwtResolverService.getLastName(token);
      this.email = this.jwtResolverService.getEmail(token);
      this.phoneNumber = this.jwtResolverService.getPhoneNumber(token);
      this.pgName = this.jwtResolverService.getPGName(token);
      this.pgPhone = this.jwtResolverService.getPGNumber(token);
      this.pgMail = this.jwtResolverService.getPGMail(token);
      this.userRoles = this.jwtResolverService.getRole(token);

      setTimeout(() => {
        this.loading = false;
      }, 1000);
    } else {
      this.loading = false;
    }
  }
}
