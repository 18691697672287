<app-topnav></app-topnav>
<div class="spacer" style="padding: 5px;"></div>

<div class="con with-background">

    <mat-card class="mat-card-1 with-background">
        
      <div class="contact-form hover-contact-form">
        <div class="row">
          <mat-form-field appearance="outline">
            <mat-label>Entre Mail Address</mat-label>
            <input matInput placeholder="Entre Mail Address">
            <mat-icon matSuffix>mail</mat-icon>
          </mat-form-field>
        </div>

        <div class="row">
          <mat-form-field appearance="outline">
            <mat-label>Entre Your Mobile Number</mat-label>
            <input matInput placeholder="Entre Your Mobile Number">
            <mat-icon matSuffix>phone</mat-icon>
          </mat-form-field>
        </div>

        <div class="row">
          <mat-form-field appearance="outline">
            <mat-label>Describe Your Message here</mat-label>
            <textarea  matInput placeholder="Describe Your Message here"></textarea>
            <mat-icon matSuffix>description</mat-icon>
          </mat-form-field>
        </div>

        <button mat-raised-button color="accent">
          <mat-icon>contacts</mat-icon> Contact Us
        </button>        
      </div>
    </mat-card>

</div>

<app-footer></app-footer>