import { Component, HostListener, Input, OnInit } from '@angular/core';
import { userItems } from './header-dummy-data';
import { Router } from '@angular/router';
import { StompService } from 'src/app/service/webSocket/stomp.service';
import { HttpClient } from '@angular/common/http';
import { Apiservice } from 'src/app/service/api/apiservice.service';
import { Topics } from 'src/app/service/webSocket/topics/Topics';
import { JwtHelperService } from '@auth0/angular-jwt';
import { JwtResolverService } from 'src/app/service/jwt/jwt-resolver.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
  
  @Input() collapsed = false;
  @Input() screenWidth = 0;
  notificationCount = 0;
  canShowSearchAsOverlay = false;
  userItems = userItems;
  stompClient: any;
  pgId: string | undefined;
  pgName: string | undefined;

  constructor(
    private router: Router, 
    private stompService: StompService, 
    private http: HttpClient,
    private apiService: Apiservice,
    private resolver: JwtResolverService
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkCanShowSearchAsOverlay(window.innerWidth);
  }
  
  ngOnInit(): void {
    const token = localStorage.getItem('token');
    if(token) {
      this.pgName = this.resolver.getPGName(token);
      this.pgId = this.resolver.getPGID(token);
    }
    this.checkCanShowSearchAsOverlay(window.innerWidth);
    this.stompService.subscribe('/private-notification-count/' + Topics.PVT_NOTIFICATION_COUNT + '/' + this.pgId, (message: any): any => {
      this.updateNotificationCountFromWebSocket(message);
    });

    // Fetch notification count from API
    this.apiService.fetchNotificationCount().subscribe((count: any) => {
      this.updateNotificationCountFromAPI(count);
    });
  }

  // Update notification count from WebSocket message
  updateNotificationCountFromWebSocket(message: any): void {
    const countFromWebSocket = parseInt(message, 10);
    this.notificationCount = countFromWebSocket;
  }

  // Update notification count from API response
  updateNotificationCountFromAPI(count: number): void {
    this.notificationCount = count;
  }

  getHeadClass(): string {
    let styleClass = '';
    if(this.collapsed && this.screenWidth > 768) {
      styleClass = 'head-trimmed';
    } else {
      styleClass = 'head-md-screen';
    }
    return styleClass;
  }

  checkCanShowSearchAsOverlay(innerWidth: number): void {
    if(innerWidth < 185) {
      this.canShowSearchAsOverlay = true;
    } else {
      this.canShowSearchAsOverlay = false;
    }
  }

  signOut() {
    localStorage.removeItem('token');
    this.router.navigate(['']);
  }

  notification() {
    this.router.navigate(['/initial/notifications']);
  }

  profile() {
    this.router.navigate(['/initial/profile']);
  }

  complaintBox() {
    this.router.navigate(['/initial/complaintBox']);
  }
}
