<nav class="navbar navbar-expand-lg bg-white navbar-light p-3 shadow-sm fixed-top">
  
  <a class="navbar-brand" routerLink="/"><i class="fa-solid fa-shop me-2"></i>
    <strong>PG Master</strong>
  </a>
  
  <div class="navbar-wrapper">
    <button class="navbar-toggler" type="button" (click)="toggleChildOptions()">
      <span class="navbar-toggler-icon"></span>
    </button>
  
    <div class="navbar-child-options" *ngIf="showChildOptions">
      <p>Child Option 1</p>
      <p>Child Option 2</p>
    </div>
  </div>

  <div class="collapse navbar-collapse">
    <ul class="navbar-nav ms-auto ">
      <li><a class="nav-link mx-2 text-uppercase active" aria-current="page" routerLink="/">Home</a></li>
      <li><a class="nav-link mx-2 text-uppercase">About</a></li>
      <li><a class="nav-link mx-2 text-uppercase" routerLink="/service">Service</a></li>
      <li><a class="nav-link mx-2 text-uppercase" routerLink="/product">Products</a></li>
      <li><a class="nav-link mx-2 text-uppercase" routerLink="/contact">Contact Us</a></li>
      <li><a class="nav-link mx-2 text-uppercase" routerLink="#">Career</a></li>      
    </ul>
    <ul class="navbar-nav ms-auto flex-row">
      <li class="nav-item">
        <a class="nav-link mx-2 text-uppercase" routerLink="/login">
          <button mat-stroked-button color="accent">
            <mat-icon>login</mat-icon>Login
          </button>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link mx-2 text-uppercase" routerLink="/signup">
          <button mat-stroked-button color="primary">
            <mat-icon>person</mat-icon>Sign Up
          </button>
        </a>
      </li>
    </ul>        
  </div>

</nav>