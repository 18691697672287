<div class="loader-overlay" *ngIf="isLoading">
  <div class="loader-content">
    <mat-spinner diameter="50"></mat-spinner>
    <br>
    <h3>Please wait, we're fetching all the Notifications....</h3>
  </div>
</div>

<div class="notification-grid">
  <div *ngFor="let notification of notifications" class="notification-card" [style.background-color]="getBackgroundColorAndIcon(notification.code).backgroundColor">
    <div class="notification-content">
      <div class="icon-container">
        <i class="fa notification-icon mb-2 white-icon" [ngClass]="getBackgroundColorAndIcon(notification.code).iconClass"></i>
      </div>
      <div class="notification-text">
        <div class="notification-title">{{ notification.title }}</div>
        <div class="notification-message">{{ notification.message }}</div>
        <div class="notification-time">
          {{ notification.time }}
        </div>
      </div>
    </div>
    <button type="button" class="fa fa-trash btn btn-outline-danger delete-button"></button>
  </div>
</div>
