import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Customer } from 'src/app/models/Customer';
import { Apiservice } from 'src/app/service/api/apiservice.service';

@Component({
  selector: 'app-edit-customer-data',
  templateUrl: './edit-customer-data.component.html',
  styleUrls: ['./edit-customer-data.component.scss'],
  providers: [DatePipe]
})
export class EditCustomerDataComponent implements OnInit {

  id: number = 0;
  customer: Customer | null = null;
  isLoading: boolean = false;
  isStudy: boolean = false;
  isWorking: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private apiService: Apiservice,
    private datePipe: DatePipe,
  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.route.params.subscribe(params => {
      this.id = +params['id'];
      this.apiService.fetchCustomerById(this.id).subscribe(
        (response: any) => {
          this.customer = response;
          this.isLoading = false;
        },
        error => {
          this.isLoading = false;
        }
      );
    });
  }

  fetchRoomNumber(customer: number) {
    this.apiService.fetchRoomNumberByID(customer).subscribe(
      (response: any) => {
        customer = response.roomNumber;
      },
      (error) => {
        console.error('Error fetching room number', error);
      }
    );
  }

  formatDate(date: string | null): string {
    if (date) {
      const parsedDate = new Date(date);
      return this.datePipe.transform(parsedDate, 'dd-MM-yyyy MM:SS a') || '';
    } else {
      return '';
    }
  }
}
