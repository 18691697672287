import { Component } from '@angular/core';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.scss']
})
export class ServiceComponent {
  
  selectedProduct: any;

  products = [
    {
      title: 'Billing System',
      description: 'Track rents, manage customers, assign managers, and enable direct payments for seamless financial management.',
      keyFeatures: [
        { text: '', iconClass: '' },
        { text: 'Customer Management: Add/edit customers, register multiple PGs.', iconClass: 'fa fa-check text-success' },
        { text: 'Manager Assignment: Assign managers to PGs for efficient oversight.', iconClass: 'fa fa-check text-success' },
        { text: 'Rent Tracking: Receive daily notifications, SMS for rent', iconClass: 'fa fa-check text-success' },
        { text: 'Tracking Capabilities: Monitor managers, PGs, and customers.', iconClass: 'fa fa-check text-success' },
        { text: 'Direct Transfers: Facilitate account transfers directly for ease of payment.', iconClass: 'fa fa-check text-success' },
      ],
      imageUrl: '../../../../assets/flags/user3.jpg',
      showFullDescription: false,
      selected: false,
    },
    { 
      title: 'Automation',
      description: '"Control devices, monitor electricity, track movements, and set alarms via our app interface."',
      keyFeatures: [
        { text: '', iconClass: '' },
        { text: 'Remote Control: Manage room devices via our App (on/off).', iconClass: 'fa fa-check text-success' },
        { text: 'Energy Monitoring: Track room electricity consumption for efficiency.', iconClass: 'fa fa-check text-success' },
        { text: 'Motion Tracking: Monitor room movements for security.', iconClass: 'fa fa-check text-success' },
        { text: 'Alarm Setting: Set alarms on specific devices for convenience.', iconClass: 'fa fa-check text-success' }
      ],
      imageUrl: '../../../../assets/flags/user3.jpg',
      showFullDescription: false,
      selected: false,
    },
    { 
      title: 'Auth Machine',
      description: 'Customized access control, manage permissions; restrict access automatically on overdue payments for security',
      keyFeatures: [
        { text: '', iconClass: '' },
        { text: 'Customized Access Control: Provide tailored punching machines.', iconClass: 'fa fa-check text-success' },
        { text: 'Access Management: Enable/disable access for customers/employees.', iconClass: 'fa fa-check text-success' },
        { text: 'Automated Restrictions: Auto-disable access on rent overdue for security and compliance.', iconClass: 'fa fa-check text-success' },
        { text: 'Streamlines access for customers/employees with personalized authorization settings.', iconClass: 'fa fa-check text-success' },
      ],
      imageUrl: '../../../../assets/flags/user3.jpg',
      showFullDescription: false,
      selected: false,
    },
    { 
      title: 'Auth Machine',
      description: 'Customized access control, manage permissions; restrict access automatically on overdue payments for security',
      keyFeatures: [
        { text: '', iconClass: '' },
        { text: 'Customized Access Control: Provide tailored punching machines.', iconClass: 'fa fa-check text-success' },
        { text: 'Access Management: Enable/disable access for customers/employees.', iconClass: 'fa fa-check text-success' },
        { text: 'Automated Restrictions: Auto-disable access on rent overdue for security and compliance.', iconClass: 'fa fa-check text-success' },
        { text: 'Streamlines access for customers/employees with personalized authorization settings.', iconClass: 'fa fa-check text-success' },
      ],
      imageUrl: '../../../../assets/flags/user3.jpg',
      showFullDescription: false,
      selected: false,
    }
  ];

  toggleDescription(product: any) {
    product.showFullDescription = !product.showFullDescription;
  }

  selectCard(selectedProduct: any) {
    this.selectedProduct = selectedProduct; // Update selectedProduct on card click
  }

  isExpanded = false;

  toggleExpand() {
    this.isExpanded = !this.isExpanded;
  }
}
