import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Constants } from '../common/Constants';
import { Customer } from 'src/app/models/Customer';
import { Transaction } from 'src/app/models/payments/Transaction';
import { Employees } from 'src/app/models/users/Employees';
import { AccessCard } from 'src/app/models/accessCard/AccessCard';
import { Enquire } from 'src/app/models/enq/Enquire';
import { UserTransaction } from 'src/app/models/UserTransaction';
import { TermsAndCondition } from 'src/app/models/tAndC/TermsAndCondition';
import { ChargesOrPenalty } from 'src/app/models/tAndC/ChargesOrPenatly';

@Injectable({
  providedIn: 'root',
})
export class Apiservice {

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer'
    }),
  };

  constructor(
    private httpClient: HttpClient
  ) {}

  private getToken(): string | null {
    return localStorage.getItem('jwtToken');
  }

  // // Add the JWT token to the headers
  // private getHeaders(): HttpHeaders {
  //   // const token = this.getToken();
  //   const token = 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJrQGdtYWlsLmNvbSIsImlhdCI6MTY5NTk2NTY0MiwiZXhwIjoxNjk2MDUyMDQyfQ.Yr3oVSt6ttpVX91dcsp5ylo4gUcv6ulVRjKm8hoLeus';
  //   if (token) {
  //     return new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Authorization': `Bearer ${token}`,
  //     });
  //   } else {
  //     return new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Authorization': `Bearer ${token}`,
  //     });
  //   }
  // }

  // LOGIN REQUEST
  public loginRequest(loginData: object): Observable<Object> {
    const url = `${Constants.BASE_URI}${Constants.AUTH}`;
    return this.httpClient
      .post(url, loginData, this.httpOptions)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = 'Something went wrong; please try again later.';
          if (error.error instanceof ErrorEvent) {
            errorMessage = `Client Error: ${error.error.message}`;
          } else if (error.status >= 400 && error.status < 500) {
            errorMessage = `HTTP Client Error (Status ${error.status}): ${error.message}`;
          } else {
            errorMessage = `HTTP Server Error (Status ${error.status}): ${error.message}`;
          }
          console.error(errorMessage);
          throw new Error(errorMessage);
        })
      );
  }

  // SIGNUP REQUEST
  public signUpRequest(signUpData: object): Observable<Object> {
    const url = `${Constants.BASE_URI}${Constants.SIGN_UP}`;
    return this.httpClient
      .post(url, signUpData, this.httpOptions)
      .pipe(catchError((error: HttpErrorResponse) => { 
        let errorMessage = 'Something went wrong; please try again later.';
        if (error.error instanceof ErrorEvent) {
          errorMessage = `Client Error: ${error.error.message}`;
        } else if (error.status >= 400 && error.status < 500) {
          errorMessage = `HTTP Client Error (Status ${error.status}): ${error.message}`;
        } else {
          errorMessage = `HTTP Server Error (Status ${error.status}): ${error.message}`;
        }
        console.error(errorMessage);
        throw new Error(errorMessage);
      })
      );
  }

  // FETCH PAID + ROOM ASSIGNED CUSTOMERS
  public fetchAllPGCustomer(): Observable<Customer[]> {
    const url = `${Constants.BASE_URI}${Constants.FETCH_ALL_CUSTOMERS}`;
    let headers = new HttpHeaders()
    .set("Authorization", `Bearer ${localStorage.getItem('token')}`)
    return this.httpClient.get<Customer[]>(url, { headers });
  }

  // FETCH UN PAID ASSIGNED CUSTOMERS
  public fetchUnpaidAllPGCustomer(): Observable<Customer[]> {
    const url = `${Constants.BASE_URI}${Constants.FETCH_UNPAID_CUSTOMERS}`;
    let headers = new HttpHeaders()
    .set("Authorization", `Bearer ${localStorage.getItem('token')}`)
    return this.httpClient.get<Customer[]>(url, { headers });
  }

  // GET ROOM NUMBER REQUEST
  public fetchRoomNumberByID(roomID: number): Observable<Object> {
    const url = `${Constants.BASE_URI}${Constants.FETCH_ROOM_NUMBER}/${roomID}`;
    let headers = new HttpHeaders()
    .set("Authorization", `Bearer ${localStorage.getItem('token')}`)
    return this.httpClient.get<Customer[]>(url, { headers });
  }

  // GET ALL REQUEST
  public searcgPG(): Observable<Object> {
    const url = `${Constants.BASE_URI}${Constants.FETCH_ALL_PG}`;
    return this.httpClient.get<Customer[]>(url);
  }

  // CREATE PG REQUEST
  public create(PGData: object): Observable<Object> {
    const url = `${Constants.BASE_URI}${Constants.CREATE_PG}`;
    let headers = new HttpHeaders()
    .set("Authorization", `Bearer ${localStorage.getItem('token')}`)
    return this.httpClient
      .post(url, PGData, { headers })
      .pipe(catchError((error: HttpErrorResponse) => { 
        let errorMessage = 'Something went wrong; please try again later.';
        if (error.error instanceof ErrorEvent) {
          errorMessage = `Client Error: ${error.error.message}`;
        } else if (error.status >= 400 && error.status < 500) {
          errorMessage = `HTTP Client Error (Status ${error.status}): ${error.message}`;
        } else {
          errorMessage = `HTTP Server Error (Status ${error.status}): ${error.message}`;
        }
        console.error(errorMessage);
        throw new Error(errorMessage);
      })
      );
  }

  // FETCH ALL CUSTOMERS TRANSACTION FOR PG
  public fetchAllPGCustomerTransactions(): Observable<Transaction[]> {
    const url = `${Constants.BASE_URI}${Constants.GET_ALL_TRANSACTION}`;
    let headers = new HttpHeaders()
    .set("Authorization", `Bearer ${localStorage.getItem('token')}`)
    return this.httpClient.get<Transaction[]>(url, { headers });
  }

  // FETCH ALL EMPLOYEES FOR PG
  public fetchAllPGEmployees(): Observable<Employees[]> {
    const url = `${Constants.BASE_URI}${Constants.GET_ALL_EMP}`;
    let headers = new HttpHeaders()
    .set("Authorization", `Bearer ${localStorage.getItem('token')}`)
    return this.httpClient.get<Employees[]>(url, { headers });
  }

  // FETCH ALL EMPLOYEES FOR PG
  public fetchAllCardDetailsForPG(): Observable<AccessCard[]> {
    const url = `${Constants.BASE_URI}${Constants.GET_ALL_CARDS}`;
    let headers = new HttpHeaders()
    .set("Authorization", `Bearer ${localStorage.getItem('token')}`)
    return this.httpClient.get<AccessCard[]>(url, { headers });
  }

  // FETCH ACCOUNT DETAILS FOR PG
  public fetchAccountDetails(): Observable<AccessCard[]> {
    const url = `${Constants.BASE_URI}${Constants.GET_ALL_ACCOUNT_DATA}`;
    let headers = new HttpHeaders()
    .set("Authorization", `Bearer ${localStorage.getItem('token')}`)
    return this.httpClient.get<AccessCard[]>(url, { headers });
  }

  // FETCH CUSTOMER DETAILS BY ID
  public fetchCustomerById(id: number): Observable<Customer[]> {
    const url = `${Constants.BASE_URI}${Constants.GET_CUST_BY_ID}/${id}`;
    let headers = new HttpHeaders()
    .set("Authorization", `Bearer ${localStorage.getItem('token')}`)
    return this.httpClient.get<Customer[]>(url, { headers });
  }

  // CREATE ENQUIRE
  public enqurie(enqurie: Object): Observable<Object> {
    const url = `${Constants.BASE_URI}${Constants.CREATE_ENQURIE}`;
    return this.httpClient
      .post(url, enqurie)
      .pipe(catchError((error: HttpErrorResponse) => { 
        let errorMessage = 'Something went wrong; please try again later.';
        if (error.error instanceof ErrorEvent) {
          errorMessage = `Client Error: ${error.error.message}`;
        } else if (error.status >= 400 && error.status < 500) {
          errorMessage = `HTTP Client Error (Status ${error.status}): ${error.message}`;
        } else {
          errorMessage = `HTTP Server Error (Status ${error.status}): ${error.message}`;
        }
        throw new Error(errorMessage);
      })
      );
  } 

    // FETCH ACCOUNT DETAILS FOR PG
  public fetchNotificationCount(): Observable<object> {
    const url = `${Constants.BASE_URI}${Constants.FETCH_NOTIFICATION_COUNT}`;
    let headers = new HttpHeaders()
    .set("Authorization", `Bearer ${localStorage.getItem('token')}`)
    return this.httpClient.get<AccessCard[]>(url, { headers });
  }

  // FETCH TRANSACTION DETAILS BY TRANSACTION ID
  public fetchTransactionDetails(transactionId: String): Observable<UserTransaction[]> {
    const url = `${Constants.BASE_URI}${Constants.FETCH_TRANSACTION_DATA}/${transactionId}`;
    let headers = new HttpHeaders()
    .set("Authorization", `Bearer ${localStorage.getItem('token')}`)
    return this.httpClient.get<UserTransaction[]>(url, { headers });
  }

  // FETCH AGREEMENT DATA
  public fetchAgreementData(customerId: number): Observable<any> {
    const url = `${Constants.BASE_URI}${Constants.AGREEMENT_DATA}/${customerId}`;

    let headers = new HttpHeaders()
      .set('Authorization', `Bearer ${localStorage.getItem('token')}`)
      .set('Accept', 'application/pdf')
      .set('Content-Type', 'text/plain')
      .set('Content-Disposition', 'attachment');

      return this.httpClient.get(url, { headers, responseType: 'arraybuffer' })
      .pipe(
        catchError((error: any) => {
          // Handle errors if needed
          console.error('Error fetching agreement data:', error);
          throw error;
        })
      );
  }

  // CREATE TERMS AND CONDITONS
  public createTAndC(TAndC: Object): Observable<Object> {
    const url = `${Constants.BASE_URI}${Constants.CREATE_TANDC}`;
    let myHeaders = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Authorization', `Bearer ${localStorage.getItem('token')}`);
    
    return this.httpClient
      .post(url, TAndC, { headers: myHeaders })
      .pipe(
        catchError((error: HttpErrorResponse) => { 
          let errorMessage = 'Something went wrong; please try again later.';
          if (error.error instanceof ErrorEvent) {
            errorMessage = `Client Error: ${error.error.message}`;
          } else if (error.status >= 400 && error.status < 500) {
            errorMessage = `HTTP Client Error (Status ${error.status}): ${error.message}`;
          } else {
            errorMessage = `HTTP Server Error (Status ${error.status}): ${error.message}`;
          }
          console.log();
        throw new Error(errorMessage);
      })
    );
  } 

   // FETCH T & C DETAILS BY ID
   public fetchTAndCData(id: string): Observable<TermsAndCondition[]> {
    const url = `${Constants.BASE_URI}${Constants.FETCH_TANDC}/${id}`;
    let headers = new HttpHeaders()
    .set("Authorization", `Bearer ${localStorage.getItem('token')}`)
    return this.httpClient.get<TermsAndCondition[]>(url, { headers });
  }

  // CREATE TERMS AND CONDITONS
  public updateTAndC(TAndC: Object, id: number): Observable<Object> {
    const url = `${Constants.BASE_URI}${Constants.UPDATE_TANDC}/${id}`;
    let myHeaders = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Authorization', `Bearer ${localStorage.getItem('token')}`);
    
    return this.httpClient
      .put(url, TAndC, { headers: myHeaders })
      .pipe(
        catchError((error: HttpErrorResponse) => { 
          let errorMessage = 'Something went wrong; please try again later.';
          if (error.error instanceof ErrorEvent) {
            errorMessage = `Client Error: ${error.error.message}`;
          } else if (error.status >= 400 && error.status < 500) {
            errorMessage = `HTTP Client Error (Status ${error.status}): ${error.message}`;
          } else {
            errorMessage = `HTTP Server Error (Status ${error.status}): ${error.message}`;
          }
          console.log();
        throw new Error(errorMessage);
      })
    );
  }

  // CREATE TERMS AND CONDITONS
  public createPenaltyOrCharges(penalty: Object): Observable<Object> {
    const url = `${Constants.BASE_URI}${Constants.CREATE_PENALTY}`;
    let myHeaders = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Authorization', `Bearer ${localStorage.getItem('token')}`);
    
    return this.httpClient
      .post(url, penalty, { headers: myHeaders })
      .pipe(
        catchError((error: HttpErrorResponse) => { 
          let errorMessage = 'Something went wrong; please try again later.';
          if (error.error instanceof ErrorEvent) {
            errorMessage = `Client Error: ${error.error.message}`;
          } else if (error.status >= 400 && error.status < 500) {
            errorMessage = `HTTP Client Error (Status ${error.status}): ${error.message}`;
          } else {
            errorMessage = `HTTP Server Error (Status ${error.status}): ${error.message}`;
          }
          console.log();
        throw new Error(errorMessage);
      })
    );
  } 

   // FETCH PENALTY DETAILS BY ID
   public fetchPenaltyData(id: string): Observable<ChargesOrPenalty[]> {
    const url = `${Constants.BASE_URI}${Constants.FETCH_PENALTY}/${id}`;
    let headers = new HttpHeaders()
    .set("Authorization", `Bearer ${localStorage.getItem('token')}`)
    return this.httpClient.get<ChargesOrPenalty[]>(url, { headers });
  }

  // CREATE CHARGES OR PENALTY
  public updatePenalty(penalty: Object, id: number): Observable<Object> {
    const url = `${Constants.BASE_URI}${Constants.UPDATE_PENALTY}`;
    let myHeaders = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Authorization', `Bearer ${localStorage.getItem('token')}`);
    
    return this.httpClient
      .put(url, penalty, { headers: myHeaders })
      .pipe(
        catchError((error: HttpErrorResponse) => { 
          let errorMessage = 'Something went wrong; please try again later.';
          if (error.error instanceof ErrorEvent) {
            errorMessage = `Client Error: ${error.error.message}`;
          } else if (error.status >= 400 && error.status < 500) {
            errorMessage = `HTTP Client Error (Status ${error.status}): ${error.message}`;
          } else {
            errorMessage = `HTTP Server Error (Status ${error.status}): ${error.message}`;
          }
          console.log();
        throw new Error(errorMessage);
      })
    );
  }

  // FETCH TRANSACTION DATA
  public fetchTransactionData(transaction: string): Observable<any> {
    const url = `${Constants.BASE_URI}${Constants.DOWNLOAD_TRX}/${transaction}`;

    let headers = new HttpHeaders()
      .set('Authorization', `Bearer ${localStorage.getItem('token')}`)
      .set('Accept', 'application/pdf')
      .set('Content-Type', 'text/plain')
      .set('Content-Disposition', 'attachment');

      return this.httpClient.get(url, { headers, responseType: 'arraybuffer' })
      .pipe(
        catchError((error: any) => {
          console.error('Error fetching trx data:', error);
          throw error;
        })
      );
  }
}
