<div class="con">
    <mat-card class="mat-card">

        <!--For Brakefast-->
        <mat-expansion-panel (opened)="togglePanel('createBrakfast')" [expanded]="createBrakfastPanelOpenState" class="hover-for-panel">
            <mat-expansion-panel-header>
              <mat-panel-title> Create Your Brakfast Menu Here </mat-panel-title>
            </mat-expansion-panel-header>
            <form action="create" method="post" class="form">
              <mat-form-field appearance="outline">
                <mat-label>Menu</mat-label>
                <input matInput placeholder="Ex: Dosa" #input>
                <span matSuffix style="padding-right: 10px;">
                  <mat-icon>menu</mat-icon>
                </span>
              </mat-form-field>
            </form>            
        </mat-expansion-panel>

        <div class="spacer"></div>

        <!--For Lunch-->
        <mat-expansion-panel (opened)="togglePanel('createLunch')" [expanded]="createLunchPanelOpenState" class="hover-for-panel">
            <mat-expansion-panel-header>
              <mat-panel-title> Create Your Lunch Menu Here </mat-panel-title>
            </mat-expansion-panel-header>
            <form action="create" method="post" class="form">
                <mat-form-field appearance="outline">
                    <mat-label>Menu</mat-label>
                    <input matInput placeholder="Ex: Dosa" #input>
                    <span matSuffix style="padding-right: 10px;">
                      <mat-icon>menu</mat-icon>
                    </span>
                </mat-form-field>
            </form>
        </mat-expansion-panel>

        <div class="spacer"></div>

        <!--For Dinner-->
        <mat-expansion-panel (opened)="togglePanel('createDinner')" [expanded]="createDinnerPanelOpenState" class="hover-for-panel">
            <mat-expansion-panel-header>
              <mat-panel-title> Create Your Dinner Menu Here </mat-panel-title>
            </mat-expansion-panel-header>
            <form action="create" method="post" class="form">
                <mat-form-field appearance="outline">
                    <mat-label>Menu</mat-label>
                    <input matInput placeholder="Ex: Dosa" #input>
                    <span matSuffix style="padding-right: 10px;">
                      <mat-icon>menu</mat-icon>
                    </span>
                </mat-form-field>
            </form>
        </mat-expansion-panel>
    </mat-card>
</div>