import { Injectable } from '@angular/core';
import { Stomp } from '@stomp/stompjs';
import * as SockJS from 'sockjs-client';
import { Apiservice } from '../api/apiservice.service';
import { Topics } from './topics/Topics';
import { Constants } from '../common/Constants';

@Injectable({
  providedIn: 'root'
})
export class StompService {

  constructor(private apiService: Apiservice) {}

  socket = new SockJS(Constants.BASE_URI+Topics.END_POINT);
  stompClient = Stomp.over(this.socket);

  subscribe(topic: string, callback: any): void {
    const connected: boolean = this.stompClient.connected;
    if (connected) {
      this.subscribeToTopic(topic, callback);
      return;
    }

    this.stompClient.connect({}, (): any => {
      this.subscribeToTopic(topic, callback);
    })
  }

  subscribeToTopic(topic: string, callback: any): void {
    this.stompClient.subscribe(topic, (message: any): any => {
      const messageBody = message.body;
      console.log('Received message:', messageBody);
      callback(messageBody);
    });
  }
}
