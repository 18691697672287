import { Component, OnInit } from '@angular/core';
import { JwtResolverService } from 'src/app/service/jwt/jwt-resolver.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
}) 
export class ProfileComponent implements OnInit {
  firstName: string | undefined;
  lastName: string | undefined;
  email: string | undefined;
  phoneNumber: string | undefined;
  pgName: string | undefined;
  pgPhone: string | undefined;
  pgMail: string | undefined;
  userRoles: string | undefined;
  loading = true;

  constructor(private jwtResolverService: JwtResolverService) {}

  ngOnInit() {
    const token = localStorage.getItem('token');
    console.log(token);

    if (token) {
      this.firstName = this.jwtResolverService.getFirstName(token);
      this.lastName = this.jwtResolverService.getLastName(token);
      this.email = this.jwtResolverService.getEmail(token);
      this.phoneNumber = this.jwtResolverService.getPhoneNumber(token);
      this.pgName = this.jwtResolverService.getPGName(token);
      this.pgPhone = this.jwtResolverService.getPGNumber(token);
      this.pgMail = this.jwtResolverService.getPGMail(token);
      this.userRoles = this.jwtResolverService.getRole(token);

      setTimeout(() => {
        this.loading = false;
      }, 1000);
    } else {
      this.loading = false;
    }
  }
}
