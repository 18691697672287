<div class="head" [ngClass]="getHeadClass()">
    <div class="head-content">
        <div class="head-content-left">
            <div class="search-container">
                <!-- <div *ngIf="!canShowSearchAsOverlay" class="normal-search">
                    <input type="search" 
                        class="search" name="" id=""
                        placeholder="Search...">
                    <i class="far fa fa-search search-icon"></i>
                </div> -->
                <!-- <div *ngIf="canShowSearchAsOverlay">
                    <button
                        [cdkMenuTriggerFor] = "searchOverlay"
                        class="search-menu-btn ripple">
                        <input type="search" 
                        class="search" name="" id=""
                        placeholder="Search...">
                    <i class="far fa-search search-icon"></i>
                    </button>
                </div> -->
            </div>
        </div>
        <div class="head-content-right">
            <div class="head-menu">
                <!-- <div class="head-menu-item">
                    <button class="head-manu-item-btn ripple">
                        <img src="" alt="flag" class="flag">
                    </button>
                </div> -->
                <!-- <div class="head-menu-item">
                    <button class="head-manu-item-btn ripple">
                        <i class="far fa-expand menu-icon"></i>
                    </button>
                </div> -->
                <div class="head-menu-item">
                    <button class="head-manu-item-btn ripple" 
                    (click)="signOut()"
                    [matTooltip]="'Sign Out'">
                        <i class="far fa-sign-out menu-icon"></i>
                    </button>
                </div>            
                <div class="head-menu-item">
                    <button class="head-manu-item-btn ripple"
                    [matTooltip]="'Complaint Box'" 
                    (click)="complaintBox()">
                        <i class="far fa-inbox menu-icon complaint-icon"></i>
                    </button>
                </div>

                <div class="head-menu-item">
                    <button class="head-manu-item-btn ripple" [matTooltip]="'Notification'" (click)="notification()">
                        <i class="far fa-bell menu-icon"></i>
                        <span class="notification-badge">
                            <span class="notification-count">{{ notificationCount }}</span>
                        </span>
                    </button>
                </div> 
                <!-- <div class="head-menu-item">
                    <button class="head-manu-item-btn ripple"
                    [matTooltip]="'Messages'">
                        <i class="far fa-comment-alt menu-icon"></i>
                    </button>
                </div> -->
            </div>

            <div class="head-user-avtar-container">
                <div class="head-user-avatar">
                    <img src="../../../../assets/flags/default-user.png" alt="user" class="user-avatar" [matTooltip]="'Profile'" (click)="profile()">
                    <!-- <i class="far fa-user menu-icon"></i> -->
                    <div class="circle-indicator pulse"></div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <ng-template #searchOverlay>
    <div class="overlay overlay-search overlay-fall" cdkMenu>
        <input type="search" name="" id="" class="search" placeholder="Search..">
        <button class="search-btn">
            <i class="far fa-search search-icon"></i>
        </button>
    </div>
</ng-template> -->

<!-- <ng-template #notificationOverlay>
    <div class="overlay overlay-notification-overlay-fall" cdkMenu>
        <ul>
            <li class="notification-head-container">
                <div class="notification-head">Notification</div>
                <span class="view-all-link">
                    <a href="#">View All</a>
                </span>
            </li>    
                <li class="notification-message-container" *ngFor="let notification of notification" cdkMenu> 
                    
                </li>
        </ul>
    </div>
</ng-template> -->

<!-- <ng-template #userOverlay>
    <div class="overlay overlay-user overlay-fall" cdkMenu>
        <u>
            <li *ngIf="let item of userItems">
                <a>
                    <i class="icon" [ngClass]="item.icon"></i>
                    <span>{{item.label}}</span>
                </a>
            </li>
        </u>
    </div>
</ng-template> -->