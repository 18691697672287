<div class="popup-background"></div>

<div class="popup-wrapper">
    <div class="popup-content">
        <h3>Displayed ID: {{ id }}</h3>
        <div class="details">
            <p>PG Mail: example@example.com</p>
            <p>Contact Number: +1234567890</p>
            <p>Owner: John Doe</p>
        </div>
        <button mat-button class="close-button" (click)="closePopup()">Close</button>
    </div>
</div>