import { Component } from '@angular/core';

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss']
})
export class CardsComponent {

  selectedProduct: any;

  products = [
    {
      title: 'Free Trial',
      description: 'This Free Trial for 1 month',
      keyFeatures: [
        { text: '', iconClass: '' },
        { text: 'Efficient staff and manager tracking', iconClass: 'fa fa-check text-success' },
        { text: 'Payment monitoring and management', iconClass: 'fa fa-check text-success' },
        { text: 'Real-time insights into room and member activities', iconClass: 'fa fa-check text-success' },
        { text: 'Enhanced administrative control', iconClass: 'fa fa-check text-success' },
        { text: 'Real-time Reporting and Full Administrative Control', iconClass: 'fa fa-check text-success' },
      ],
      imageUrl: '../../../../assets/flags/user3.jpg',
      showFullDescription: false,
      selected: false,
    },
    { 
      title: 'Silver Subscription',
      description: 'This is Silver Subscription for 3 Months',
      keyFeatures: [
        { text: '', iconClass: '' },
        { text: 'Convenient bill payment functionality', iconClass: 'fa fa-check text-success' },
        { text: 'Control over room conditions', iconClass: 'fa fa-check text-success' },
        { text: 'Rating system for PGs', iconClass: 'fa fa-check text-success' },
        { text: 'User Convenience', iconClass: 'fa fa-check text-success' },
        { text: 'Room Conditions at Your Fingertips', iconClass: 'fa fa-check text-success' },
        { text: 'Unparalleled User Convenience', iconClass: 'fa fa-check text-success' },
      ],
      imageUrl: '../../../../assets/flags/user3.jpg',
      showFullDescription: false,
      selected: false,
    },
    { 
      title: 'Pro Subscription',
      description: 'This is Pro Subscription for 3 Months',
      keyFeatures: [
        { text: '', iconClass: '' },
        { text: 'Convenient bill payment functionality', iconClass: 'fa fa-check text-success' },
        { text: 'Control over room conditions', iconClass: 'fa fa-check text-success' },
        { text: 'Rating system for PGs', iconClass: 'fa fa-check text-success' },
        { text: 'User Convenience', iconClass: 'fa fa-check text-success' },
        { text: 'Room Conditions at Your Fingertips', iconClass: 'fa fa-check text-success' },
        { text: 'Unparalleled User Convenience', iconClass: 'fa fa-check text-success' },
      ],
      imageUrl: '../../../../assets/flags/user3.jpg',
      showFullDescription: false,
      selected: false,
    },
    { 
      title: 'Platinum Subscription',
      description: 'This is Platinum Subscription for 6 Months',
      keyFeatures: [
        { text: '', iconClass: '' },
        { text: 'Convenient bill payment functionality', iconClass: 'fa fa-check text-success' },
        { text: 'Control over room conditions', iconClass: 'fa fa-check text-success' },
        { text: 'Rating system for PGs', iconClass: 'fa fa-check text-success' },
        { text: 'User Convenience', iconClass: 'fa fa-check text-success' },
        { text: 'Room Conditions at Your Fingertips', iconClass: 'fa fa-check text-success' },
        { text: 'Unparalleled User Convenience', iconClass: 'fa fa-check text-success' },
      ],
      imageUrl: '../../../../assets/flags/user3.jpg',
      showFullDescription: false,
      selected: false,
    },
    { 
      title: 'Golden Subscription',
      description: 'This is Golden Subscription for 13 Months',
      keyFeatures: [
        { text: '', iconClass: '' },
        { text: 'Convenient bill payment functionality', iconClass: 'fa fa-check text-success' },
        { text: 'Control over room conditions', iconClass: 'fa fa-check text-success' },
        { text: 'Rating system for PGs', iconClass: 'fa fa-check text-success' },
        { text: 'User Convenience', iconClass: 'fa fa-check text-success' },
        { text: 'Room Conditions at Your Fingertips', iconClass: 'fa fa-check text-success' },
        { text: 'Unparalleled User Convenience', iconClass: 'fa fa-check text-success' },
      ],
      imageUrl: '../../../../assets/flags/user3.jpg',
      showFullDescription: false,
      selected: false,
    }
  ];

  toggleDescription(product: any) {
    product.showFullDescription = !product.showFullDescription;
  }

  selectCard(selectedProduct: any) {
    this.selectedProduct = selectedProduct; // Update selectedProduct on card click
  }
}
