<app-topnav></app-topnav>
<div class="spacer" style="padding: 5px;"></div>

<div class="con with-background">
  <mat-card class="mat-card-1 with-background">
    
    <div class="row">
      <article class="card" style="margin-right: 10px;">
          <header class="card__thumb">
              <a ><img src="https://firebasestorage.googleapis.com/v0/b/pgmasterui.appspot.com/o/rfid-machine.png?alt=media&token=f1249776-2bb5-4d39-b6f7-4d62e4e1bf65.jpeg"/></a>
          </header>
          
          <div class="card__body">
              <!-- <div class="card__category"><a >pet</a></div> -->
              <h2 class="card__title"><a >PG Master Guard</a></h2>
              <div class="card__subtitle">"Smarter way to secure your space"</div>
              <p class="card__description">
                This is an advanced access control solution revolutionizing 
                security in PG accommodations. With remote access management, door lock integration, 
                and payment-based access control.</p>
          </div>

          <footer class="card__footer">
              <div class="row">
                <div class="col">
                  <button class="btn" mat-button color="primary" style="margin-right: 15px;">Call
                    <mat-icon>call</mat-icon>
                  </button>
                
                  <button class="btn" mat-button color="accent" style="margin-right: 15px;">Cart
                    <mat-icon>shopping_cart</mat-icon>
                  </button>

                  <button class="btn" mat-button color="accent">PDF
                    <mat-icon>download</mat-icon>
                  </button>
                </div>
              </div>
          </footer>
      </article>

      <article class="card">
        <header class="card__thumb">
            <a ><img src="https://firebasestorage.googleapis.com/v0/b/pgmasterui.appspot.com/o/wasmachine_2-removebg-preview.png?alt=media&token=efe51258-10a8-4501-8a44-a30d1c2e26f2.jpeg"/></a>
        </header>
        
        <div class="card__body">
            <!-- <div class="card__category"><a >pet</a></div> -->
            <h2 class="card__title_cust"><a >PG Master Washing Controller</a></h2>
            <div class="card__subtitle">"Control at your fingertips, anytime, anywhere."</div>
            <p class="card__description">
              Enable remote washing machine control and secure access through PG Master Guard. 
              This innovation allows personalized usage time allocation, 
              revolutionizing laundry management for shared living spaces.
            </p>
        </div>
        <footer class="card__footer">
          <div class="row">
            <div class="col">
              <button class="btn" mat-button color="primary" style="margin-right: 15px;">Call
                <mat-icon>call</mat-icon>
              </button>
            
              <button class="btn" mat-button color="accent" style="margin-right: 15px;">Cart
                <mat-icon>shopping_cart</mat-icon>
              </button>

              <button class="btn" mat-button color="accent">PDF
                <mat-icon>download</mat-icon>
              </button>
            </div>
          </div>
        </footer>
      </article>

      <article class="card">
        <header class="card__thumb">
            <a><img class="img" src="../../../../assets/3d_img.jpg"/></a>
        </header>
        
        <div class="card__body">
            <!-- <div class="card__category"><a >pet</a></div> -->
            <h2 class="card__title"><a>PG Master Power Flow</a></h2>
            <div class="card__subtitle">"Empower your space, track and save."</div>
            <p class="card__description">
              Revolutionizes electricity management in PGs, 
              providing live consumption updates and peak usage alerts. 
              With room-specific monitoring and billing capabilities, it 
              enables precise tracking and fair billing per room.
            </p>
        </div>
        <footer class="card__footer">
          <div class="row">
            <div class="col">
              <button class="btn" mat-button color="primary" style="margin-right: 15px;">Call
                <mat-icon>call</mat-icon>
              </button>
            
              <button class="btn" mat-button color="accent" style="margin-right: 15px;">Cart
                <mat-icon>shopping_cart</mat-icon>
              </button>

              <button class="btn" mat-button color="accent">PDF
                <mat-icon>download</mat-icon>
              </button>
            </div>
          </div>
        </footer>
      </article>
    </div>

  </mat-card>
</div>

<app-footer></app-footer>