import { DatePipe } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as saveAs from 'file-saver';
import { Customer } from 'src/app/models/Customer';
import { Transaction } from 'src/app/models/payments/Transaction';
import { Apiservice } from 'src/app/service/api/apiservice.service';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss'],
  providers: [DatePipe],
})
export class AccountsComponent {

  currentTime: string = '';
  selectedStartDate: Date | null = null;
  selectedEndDate: Date | null = null;
  panelOpenState = false;
  isPDFLoading: boolean = false;

  displayedColumns: string[] = [ 
    'serialNumber',
    'name',
    'transactionId', 
    'paidAmount',
    'paidDate',
    'nextPayDate',
    'daysLeft',
    'status',
    'upi',
    'action'
  ];

  updateCurrentTime() {
    const now = new Date();
    this.currentTime = now.toLocaleString();
  }

  dataSource: MatTableDataSource<Transaction>;
  isLoading: boolean = false;
  isFiltering: boolean = false;
  noDataAvailable: boolean = false;
  totalPaidAmount: number = 0;
  numberOfTransactions: number = 0;
  mostUsedUPI: string = '';

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(private apiService: Apiservice, private datePipe: DatePipe) {
    this.dataSource = new MatTableDataSource<Transaction>([]);
  } 

  ngOnInit(): void {
    this.fetchTransactionHistory();
    this.calculateMostUsedUPI();
    this.updateCurrentTime();
    setInterval(() => this.updateCurrentTime(), 60000);
  }  

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  fetchTransactionHistory() {
    this.isLoading = true;
    const minimumLoadingTime = 500;
  
    setTimeout(() => {
      this.apiService.fetchAllPGCustomerTransactions().subscribe(
        (response: any) => {
          const transaction: Transaction[] = response.recordsList;
          this.dataSource.data = transaction;
          this.noDataAvailable = transaction.length === 0;
        },
        (error) => {
          console.error('Error fetching data', error);
        },
        () => {
          this.isLoading = false;
        }
      );
    }, minimumLoadingTime);
  }

  getSerialNumber(row: any): number {
    const index = this.dataSource.data.indexOf(row);
    return index + 1;
  }

  getDaysUntilNextPayment(nextPayment: any): number {
    if (!nextPayment) {
      return 0;
    }
    
    const nextPaymentDate = new Date(nextPayment);  
    if (isNaN(nextPaymentDate.getTime())) {
      return 0;
    }
  
    const currentDate = new Date();
    const timeDifference = nextPaymentDate.getTime() - currentDate.getTime();
    return Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  }  

  isTextRed(nextPayment: any): boolean {
    return this.getDaysUntilNextPayment(nextPayment) <= 5;
  }

  getFormattedDate(timestamp: number): string {
    const slash = '/';
    const date = new Date(timestamp);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();

    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const amPm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');
    // return `${day}${slash}${month}${slash}${year} ${formattedHours}${':'}${minutes} ${amPm}`;
    return `${month}${slash}${day}${slash}${year}`;
  }

  formatDate(date: string | null): string {
    if (date) {
      const parsedDate = new Date(date);
      return this.datePipe.transform(parsedDate, 'dd-MM-yyyy') || '';
    } else {
      return '';
    }
  }  

  // onDateRangeChange() {
  //   if (this.selectedStartDate !== null && this.selectedEndDate !== null) {
  //     this.isFiltering = true;
  //     const startDate = this.selectedStartDate;
  //     const endDate = this.selectedEndDate;
  
  //     const filteredData = this.dataSource.data.filter((customer) => {
  //       const createdDate = new Date(customer.createdDate);
  //       return createdDate >= startDate && createdDate <= endDate;
  //     });
  
  //     setTimeout(() => {
  //       this.dataSource.data = filteredData;
  //       this.isFiltering = false;
  //     }, 500);
  //   }

  //   this.calculateTotalPaidAmount();
  // }

  // calculateTotalPaidAmount(): void {
  //   if (this.selectedStartDate && this.selectedEndDate) {
  //     const startDate = this.selectedStartDate;
  //     const endDate = this.selectedEndDate;
  
  //     const filteredRecords = this.dataSource.data.filter((record) => {
  //       const createdDate = new Date(record.createdDate);
  //       return createdDate >= startDate && createdDate <= endDate;
  //     });
  
  //     this.totalPaidAmount = filteredRecords.reduce((total, record) => {
  //       return total + record.paidAmount;
  //     }, 0);
  
  //     this.numberOfTransactions = filteredRecords.length;
  //   } else {
  //     this.totalPaidAmount = this.dataSource.data.reduce((total, record) => {
  //       return total + record.paidAmount;
  //     }, 0);
  
  //     this.numberOfTransactions = this.dataSource.data.length;
  //   }
  // }   

  getCurrentMonthDateRange(): { startDate: Date, endDate: Date } {
    const currentDate = new Date();
    const firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    return { startDate: firstDay, endDate: lastDay };
  }  

  calculateMostUsedUPI(): void {
    const upiCounts: { [upi: string]: number } = {};
    this.dataSource.data.forEach((record) => {
      const upi = record.receivedOn;
      if (upi) {
        upiCounts[upi] = (upiCounts[upi] || 0) + 1;
      }
    });

    let maxUPI = '';
    let maxCount = 0;
    for (const upi in upiCounts) {
      if (upiCounts[upi] > maxCount) {
        maxUPI = upi;
        maxCount = upiCounts[upi];
      }
    }
    this.mostUsedUPI = maxUPI;
}

calculateTotalPaidAmount(filteredRecords: Transaction[]): number {
    return filteredRecords.reduce((total, record) => {
        return total + record.paidAmount;
    }, 0);
}

onDateRangeChange() {
    if (this.selectedStartDate !== null && this.selectedEndDate !== null) {
        const startDate = this.selectedStartDate;
        const endDate = this.selectedEndDate;

        const filteredRecords = this.dataSource.data.filter((record) => {
            const createdDate = new Date(record.createdDate);
            return createdDate >= startDate && createdDate <= endDate;
        });

        this.totalPaidAmount = this.calculateTotalPaidAmount(filteredRecords);
        this.numberOfTransactions = filteredRecords.length;
    } else {
        this.totalPaidAmount = this.calculateTotalPaidAmount(this.dataSource.data);
        this.calculateMostUsedUPI();
        this.numberOfTransactions = this.dataSource.data.length;
    }
  }

  generatePDF(transaction: string) {
    this.isPDFLoading = true;
    this.apiService.fetchTransactionData(transaction).subscribe(
      (data) => {
        const blob = new Blob([data], { type: 'application/pdf' });
        saveAs(blob, transaction + '.pdf');
        this.isPDFLoading = false;
      },
      (error) => {
        this.isPDFLoading = false;
        console.error('Error generating PDF:', error);
      }
    );
  }
}
