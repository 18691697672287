import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})

export class ProductComponent implements OnInit {

  public showLoader = true;

  ngOnInit() {
    setTimeout(() => {
      this.showLoader = false;
    }, 1000);
  }

  products = [
    {
      title: 'PG Master User App',
      description: 'This application is specially for users only.',
      keyFeatures: [
        { text: '', iconClass: '' },
        { text: 'Convenient bill payment functionality', iconClass: 'fa fa-check text-success' },
        { text: 'Control over room conditions', iconClass: 'fa fa-check text-success' },
        { text: 'Rating system for PGs', iconClass: 'fa fa-check text-success' },
        { text: 'User Convenience', iconClass: 'fa fa-check text-success' },
        { text: 'Room Conditions at Your Fingertips', iconClass: 'fa fa-check text-success' },
        { text: 'Unparalleled User Convenience', iconClass: 'fa fa-check text-success' },
      ],
      imageUrl: 'https://firebasestorage.googleapis.com/v0/b/pgmasterui.appspot.com/o/user_app_logo.png?alt=media&token=332b1be1-f1c1-4b0e-b099-7085081b1be5.jpeg',
      showFullDescription: false,
      url: 'https://drive.google.com/drive/folders/1ZIBsRRYczewb3p0wnu7qnZo_wFLMRtGr?usp=sharing'
    },
    {
      title: 'PG Master Admin App',
      description: 'This application is specially for PG owners only.',
      keyFeatures: [
        { text: '', iconClass: '' },
        { text: 'Efficient staff and manager tracking', iconClass: 'fa fa-check text-success' },
        { text: 'Payment monitoring and management', iconClass: 'fa fa-check text-success' },
        { text: 'Real-time insights into room and member activities', iconClass: 'fa fa-check text-success' },
        { text: 'Enhanced administrative control', iconClass: 'fa fa-check text-success' },
        { text: 'Real-time Reporting and Full Administrative Control', iconClass: 'fa fa-check text-success' },
      ],
      imageUrl: 'https://firebasestorage.googleapis.com/v0/b/pgmasterui.appspot.com/o/admin_app_txt.png?alt=media&token=1c84d5ab-fbb3-48a2-915f-e41c11e34e39.jpeg',
      showFullDescription: false,
      url: 'https://drive.google.com/drive/folders/1_qLNnCa37urRfPuS4R8P1BboChpeM9d-?usp=sharing'
    },
    {
      title: 'PG Master User App',
      description: 'This application is specially for PG owners only.',
      keyFeatures: [
        { text: '', iconClass: '' },
        { text: 'Efficient staff and manager tracking', iconClass: 'fa fa-check text-success' },
        { text: 'Payment monitoring and management', iconClass: 'fa fa-check text-success' },
        { text: 'Real-time insights into room and member activities', iconClass: 'fa fa-check text-success' },
        { text: 'Enhanced administrative control', iconClass: 'fa fa-check text-success' },
        { text: 'Real-time Reporting and Full Administrative Control', iconClass: 'fa fa-check text-success' },
      ],
      imageUrl: 'https://firebasestorage.googleapis.com/v0/b/pgmasterui.appspot.com/o/soon_play_store.png?alt=media&token=4c1f1a80-0a1f-4bf0-b98d-4f7d99f11308.jpeg',
      showFullDescription: false,
      url: 'https://drive.google.com/drive/folders/1_qLNnCa37urRfPuS4R8P1BboChpeM9d-?usp=sharing'
    }
  ];

  toggleDescription(product: any) {
    product.showFullDescription = !product.showFullDescription;
  }
}
