import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Customer } from 'src/app/models/Customer';
import { Apiservice } from 'src/app/service/api/apiservice.service';

interface SideNavToggle {
  screenWidth: number;
  collapsed: boolean;
}

@Component({
  selector: 'app-initial-desktop',
  templateUrl: './initial-desktop.component.html',
  styleUrls: ['./initial-desktop.component.scss']
})
export class InitialDesktopComponent implements OnInit, AfterViewInit {
  
  displayedColumns: string[] = [
    'serialNumber',
    'name', 
    'mail', 
    'phoneNumber',
    'address',
    'room',
    'cardNumber',
    'paymentStatus',
    'enbDis' 
  ];

  dataSource: MatTableDataSource<Customer>;
  isLoading: boolean = false; // Flag to track loading state

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(private apiService: Apiservice) {
    this.dataSource = new MatTableDataSource<Customer>([]);
  } 

  ngOnInit(): void {
    this.fetchCustomers();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  fetchCustomers() {
    this.isLoading = true;
    const minimumLoadingTime = 500; // 0.5 seconds
  
    setTimeout(() => {
      this.apiService.fetchAllPGCustomer().subscribe(
        (response: any) => {
          const customers: Customer[] = response.recordsList;
          customers.forEach((customer) => {
            this.fetchRoomNumber(customer);
          });
          this.dataSource.data = customers;
        },
        (error) => {
          console.error('Error fetching data', error);
        },
        () => {
          this.isLoading = false;
        }
      );
    }, minimumLoadingTime);
  }
  

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getSerialNumber(row: any): number {
    const index = this.dataSource.data.indexOf(row);
    return index + 1;
  }

  fetchRoomNumber(customer: Customer) {
    if (customer.assignedRoom === 0) {
      customer.roomNumber = 'Not Assigned';
    } else {
      this.apiService.fetchRoomNumberByID(customer.assignedRoom).subscribe(
        (response: any) => {
          customer.roomNumber = response.roomNumber;
        },
        (error) => {
          console.error('Error fetching room number', error);
        }
      );
    }
  }  

  isSideNavBarCollapsed = false;
  screenWidth = 0;

  onToggleSideNav(data: SideNavToggle): void {
    this.screenWidth = data.screenWidth;
    this.isSideNavBarCollapsed = data.collapsed;
  }
}
