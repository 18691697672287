<div class="con">
    <div class="circles-container">
        <div class="room-circle" *ngFor="let room of roomsData">
            <div class="row-1">
                <span class="heart-icon"><i class="fas fa-bolt" style="color: #31aaf0;"></i></span>
                
                <div class="column-1">
                    <div class="left-icons">
                        <button mat-icon-button [matTooltip]="'Room ' + room.roomName + ' with ' + room.sharing + ' sharing\'s'">
                            <mat-icon>bed</mat-icon>
                            <span class="number-badge">0{{room.sharing}}</span>
                        </button>
                    </div>
                </div>
                
                <div class="column-2">
                    <div class="left-icons">
                        <button mat-icon-button [matTooltip]="'Click For More Details'" (click)="openDialog()">
                            <mat-icon>menu</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
            
            <div class="row-2">
                <div class="column-1-row-1">
                    <div class="left-icons">
                        <button mat-icon-button [matTooltip]="'Today Consumption ' + 2">
                            <span class="unit-txt">2</span>
                        </button>
                    </div>
                </div>
                
                <div class="column-2-row-2">
                    <div class="left-icons">
                        <button mat-icon-button [matTooltip]="'Yesterday Consumption ' + 2">
                            <span class="unit-txt">2</span>
                        </button>
                    </div>
                </div>

                <div class="column-3-row-3">
                    <div class="left-icons">
                        <button mat-icon-button [matTooltip]="'Consumption Diff ' + 0">
                            <span class="unit-txt">0</span>
                        </button>
                    </div>
                </div>
            </div>
        
        </div>            
    </div>
</div>
