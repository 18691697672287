export class PG {
    pgName: string;
    pgUserName: string;
    phoneNumber: string;
    mail: string;
    state: string;
    dist: string;
    pinCode: string;
    category: string;
    aboutPg: string;

    constructor(pgName: string, pgUserName: string, phoneNumber: string, mail: string, state: string, dist: string, pinCode: string, category: string, aboutPg: string) {
        this.pgName = pgName;
        this.pgUserName = pgUserName;
        this.phoneNumber = phoneNumber;
        this.mail = mail;
        this.state = state;
        this.dist = dist;
        this.pinCode = pinCode;
        this.category = category;
        this.aboutPg = aboutPg;
    }
}