<div class="con">
    <div class="loader-overlay" *ngIf="isLoading">
      <div class="loader-content">
        <mat-spinner diameter="50"></mat-spinner>
        <br>
        <h3>Please wait, we're fetching paid customer details..</h3>
      </div>
    </div>

    <div class="loader-overlay" *ngIf="isPDFLoading">
      <div class="loader-content">
        <mat-spinner diameter="50"></mat-spinner>
        <br>
        <h3>Please wait, we're preparing you're agrrement document..</h3>
      </div>
    </div>
  
    <div class="loader-overlay" *ngIf="isFiltering">
      <div class="loader-content">
        <mat-spinner diameter="50"></mat-spinner>
        <br>
        <h3>Please wait, we're filtering customer details..</h3>
      </div>
    </div>
  
    <mat-card class="mat-card">
      <div class="search-and-date-container" *ngIf="!isLoading">
        <mat-form-field class="search-bar" appearance="outline">
          <mat-label>Search</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Customer Name" #input>
          <span matSuffix style="padding-right: 10px;">
            <mat-icon>search</mat-icon>
          </span>
        </mat-form-field>
      
        <mat-form-field class="date-range-field" appearance="outline">
          <mat-label>Select start & end date</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input matStartDate placeholder="Start date" [(ngModel)]="selectedStartDate" (ngModelChange)="onDateRangeChange()">
            <input matEndDate placeholder="End date" [(ngModel)]="selectedEndDate" (ngModelChange)="onDateRangeChange()">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
    </mat-card>
  
    <div class="spacer"></div>
    
    <div class="mat-elevation-z8 table-container">
      <table mat-table [dataSource]="dataSource" matSort>
  
        <ng-container matColumnDef="serialNumber">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-align"> No. </th>
          <td mat-cell *matCellDef="let row" class="center-align">{{ getSerialNumber(row) }}</td>
        </ng-container>
  
        <ng-container matColumnDef="createdDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-align"> Date </th>
          <td mat-cell *matCellDef="let row" class="center-align">{{ formatDate(row.createdDate) }}</td>
        </ng-container>
  
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-align"> Name </th>
          <td mat-cell *matCellDef="let row" class="center-align">
            {{ (row.firstName + ' ' + row.lastName).toUpperCase() }}
            <mat-icon [matTooltip]="'Edit Customer'" matTooltipPosition="after" class="edit-icon" (click)="navigateToEdit(row.id)" class="hover-for-edit-icon">edit</mat-icon>
          </td>
        </ng-container>
  
        <ng-container matColumnDef="mail">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Mail </th>
          <td mat-cell *matCellDef="let row"> {{row.mail}} </td>
        </ng-container>
  
        <ng-container matColumnDef="phoneNumber">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Phone </th>
          <td mat-cell *matCellDef="let row"> {{row.phoneNumber}} </td>
        </ng-container>
  
        <ng-container matColumnDef="address">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Address </th>
          <td mat-cell *matCellDef="let row">
            {{ (row.state + ', ' + row.dist + ', ' + row.pinCode).toUpperCase() }}
          </td>
        </ng-container>
  
        <!-- <ng-container matColumnDef="room">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Room </th>
          <td mat-cell *matCellDef="let row"> 
            {{row.assignedRoom == 0 ? 'Not Assigned' : row.assignedRoom}} 
          </td>
        </ng-container> -->
  
        <ng-container matColumnDef="room">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Room </th>
          <td mat-cell *matCellDef="let row">
            <ng-container *ngIf="row.roomNumber != null; else redRoom">
              <span class="active-text hover-for-btn" [matTooltip]="'Room ' + row.roomNumber + ' Assigned'" matTooltipPosition="after" matTooltipShowDelay="500"><i class="fas fa-home"> </i> {{ row.roomNumber }}</span>
            </ng-container>
            <ng-template #redRoom>
              <span class="un-active-text hover-for-btn" [matTooltip]="'Room Not Assigned'" matTooltipPosition="after" matTooltipShowDelay="500"><i class="fas fa-home"> </i> {{ 'N/A' }}</span>
            </ng-template>
          </td>
        </ng-container>
  
        <ng-container matColumnDef="cardNumber">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Access </th>
          <td mat-cell *matCellDef="let row">
            <ng-container *ngIf="row.access == 1; else notAllot">
              <span class="active-text hover-for-btn" [matTooltip]="'Card Allot'" matTooltipPosition="after" matTooltipShowDelay="500">CARD ALLOT</span>
            </ng-container>
            <ng-template #notAllot class="button-enable">
              <span class="un-active-text hover-for-btn" [matTooltip]="'Card Not Allot'" matTooltipPosition="after" matTooltipShowDelay="500">NOT ALLOT</span>
            </ng-template>
          </td>
        </ng-container>
  
        <ng-container matColumnDef="paymentStatus">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Payment </th>
          <td mat-cell *matCellDef="let row">
            <ng-container *ngIf="row.paymentStatus == 2; else paidAmount" [matTooltip]="'Un Paid'" matTooltipPosition="after" matTooltipShowDelay="500" class="hover-for-btn">
              UN-PAID
            </ng-container>
            <ng-template #paidAmount>
              <span class="paid-amount hover-for-btn" [matTooltip]="'Paid ' + row.amount + '/-'" matTooltipPosition="after" matTooltipShowDelay="500">
                <i class="fas fa-rupee-sign"></i> {{row.amount + '/-'}}
              </span>
            </ng-template>            
          </td>
        </ng-container>
  
        <ng-container matColumnDef="enbDis">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Enable/Disable </th>
          <td mat-cell *matCellDef="let row">
            <ng-container *ngIf="row.disableOrEnable === 0">
              <button type="button" [matTooltip]="'Active'" matTooltipPosition="after" matTooltipShowDelay="500" class="button-enable hover-for-btn">ACTIVE</button>
            </ng-container>
            <ng-container *ngIf="row.disableOrEnable === 1">
              <span class="button-disable hover-for-btn" [matTooltip]="'Un Active'" matTooltipPosition="after" matTooltipShowDelay="500">UN-ACTIVE</span>
            </ng-container>
          </td>
        </ng-container>
  
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Actions </th>
          <td mat-cell *matCellDef="let row">
            <button mat-icon-button (click)="generatePDF(row.id)" [matTooltip]="'Download Aggrement'" matTooltipPosition="after" matTooltipShowDelay="500" class="hover-for-btn">
              <i class="fas fa-file-contract"></i>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  
      </table>
      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>
    </div>
  
    <!-- if there is no data then show this message -->
    <div class="no-data-message" *ngIf="!isLoading && noDataAvailable">
      <p>There is No Data to be Shown!</p>
    </div>
</div>