<div class="con">

  <mat-accordion>
    <mat-expansion-panel (opened)="togglePanel('generalSettings')" [expanded]="themesPanelOpenState" class="hover-for-panel">
      <mat-expansion-panel-header>
        <mat-panel-title>
          General Setting
        </mat-panel-title>
        <mat-panel-description>
          {{ themesPanelOpenState ? 'General Settings' : 'Click Here to Open' }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="access-control-container">
        <div class="access-column" *ngFor="let general of generalSettingsControls">
          <mat-slide-toggle [(ngModel)]="general.value"></mat-slide-toggle>
          <div class="spacer"></div>
          <div class="access-label">{{ general.label }}</div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <div class="divider"></div>

  <mat-accordion>
    <mat-expansion-panel (opened)="togglePanel('notification')" [expanded]="notificationPanelOpenState" class="hover-for-panel">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Notification Setting
        </mat-panel-title>
        <mat-panel-description>
          {{ notificationPanelOpenState ? 'You can allow Notification which you want' : 'Click Here to Open' }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="access-control-container">
        <div class="access-column" *ngFor="let access of accessControls">
          <mat-slide-toggle [(ngModel)]="access.value"></mat-slide-toggle>
          <div class="spacer"></div>
          <div class="access-label">{{ access.label }}</div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <div class="divider"></div>

  <mat-accordion>
    <mat-expansion-panel (opened)="togglePanel('language')" [expanded]="languagePanelOpenState" class="hover-for-panel">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Language Setting
        </mat-panel-title>
        <mat-panel-description>
          {{ languagePanelOpenState ? 'You can select which language you want' : 'Click Here to Open' }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="access-control-container">
        <div class="access-column" *ngFor="let lang of languageControls">
          <mat-slide-toggle [(ngModel)]="lang.value"></mat-slide-toggle>
          <div class="spacer"></div>
          <div class="access-label">{{ lang.label }}</div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <div class="divider"></div>

  <mat-accordion>
    <mat-expansion-panel (opened)="togglePanel('theme')" [expanded]="themesPanelOpenState" class="hover-for-panel">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Theme's Setting
        </mat-panel-title>
        <mat-panel-description>
          {{ themesPanelOpenState ? 'You can select theme for web site' : 'Click Here to Open' }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="access-control-container">
        <div class="access-column" *ngFor="let theme of themeControls">
          <mat-slide-toggle [(ngModel)]="theme.value"></mat-slide-toggle>
          <div class="spacer"></div>
          <div class="access-label">{{ theme.label }}</div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>