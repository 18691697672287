import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root',
})
export class JwtResolverService {
  constructor() {}

  private jwtHelper = new JwtHelperService();

  getExpiry(token: string): Date {
    const decodedToken = this.jwtHelper.decodeToken(token);
    if (!decodedToken || !decodedToken.exp) {
      throw new Error('Invalid JWT token');
    }
    return new Date(decodedToken.exp * 1000);
  }

  isValidToken(token: string): boolean {
    return !this.jwtHelper.isTokenExpired(token);
  }

  getPayloads(token: string): any {
    const decodedToken = this.jwtHelper.decodeToken(token);
    if (!decodedToken) {
      throw new Error('Invalid JWT token');
    }
    return decodedToken;
  }

  getAddAccess(token: string): boolean {
    const payload = this.getPayloads(token);
    return payload.add_access === true;
  }

  getUpdateAccess(token: string): boolean {
    const payload = this.getPayloads(token);
    return payload.update_access === true;
  }

  getDeleteAccess(token: string): boolean {
    const payload = this.getPayloads(token);
    return payload.delete_access === true;
  }

  getEnableAccess(token: string): boolean {
    const payload = this.getPayloads(token);
    return payload.delete_access === true;
  }

  getDisableAccess(token: string): boolean {
    const payload = this.getPayloads(token);
    return payload.delete_access === true;
  }

  getFirstName(token: string): string | undefined {
    const payload = this.getPayloads(token);
    return payload.firstName;
  }

  getLastName(token: string): string | undefined {
    const payload = this.getPayloads(token);
    return payload.lastName;
  }

  getEmail(token: string): string | undefined {
    const payload = this.getPayloads(token);
    return payload.userMail;
  }

  getRole(token: string): string | undefined {
    const payload = this.getPayloads(token);
    return payload.userRoles;
  }

  getPhoneNumber(token: string): string | undefined {
    const payload = this.getPayloads(token);
    return payload.phoneNumber;
  }

  getPGName(token: string): string | undefined {
    const payload = this.getPayloads(token);
    return payload.pgName;
  }

  getPGNumber(token: string): string | undefined {
    const payload = this.getPayloads(token);
    return payload.pgPhoneNumber;
  }

  getPGMail(token: string): string | undefined {
    const payload = this.getPayloads(token);
    return payload.pgMail;
  }

  getPGID(token: string): string | undefined {
    const payload = this.getPayloads(token);
    return payload.pgId;
  }
}
