<div class="loader-overlay" *ngIf="loading">
    <div class="loader-content">
      <mat-spinner diameter="50"></mat-spinner>
      <br>
      <h3>Please wait, we're fetching profile details....</h3>
    </div>
  </div>

<div class="container">
    <div class="row">
        <div class="col-lg-12 mb-4 mb-sm-5">
            <div class="card card-style1 border-0">
                <div class="card-body p-1-9 p-sm-2-3 p-md-6 p-lg-7">
                    <div class="row align-items-center">
                        <div class="col-lg-6 mb-4 mb-lg-0">
                            <img src="../../../assets/flags/default-user.png" alt="...">
                        </div>
                        <div class="col-lg-6 px-xl-10">
                            <h1 class="h2 text-black mb-0"><b>{{firstName + ' ' + lastName}}</b></h1>
                            <br>
                            <ul class="list-unstyled mb-1-9">
                                <li class="mb-2 mb-xl-3 display-28"><span
                                        class="display-26 text-secondary me-2 font-weight-600">Role:</span>
                                    {{userRoles}}</li>
                                <li class="mb-2 mb-xl-3 display-28"><span
                                        class="display-26 text-secondary me-2 font-weight-600">Experience:</span> Joined
                                    Date</li>
                                <li class="mb-2 mb-xl-3 display-28"><span
                                        class="display-26 text-secondary me-2 font-weight-600">Email:</span> {{email}}
                                </li>
                                <li class="mb-2 mb-xl-3 display-28"><span
                                        class="display-26 text-secondary me-2 font-weight-600">Phone:</span>
                                    {{phoneNumber}}</li>
                                <li class="mb-2 mb-xl-3 display-28"><span
                                        class="display-26 text-secondary me-2 font-weight-600">PG Name:</span>
                                    {{pgName}}</li>
                                <li class="mb-2 mb-xl-3 display-28"><span
                                        class="display-26 text-secondary me-2 font-weight-600">PG Number:</span>
                                    {{pgPhone}}</li>
                                <li class="mb-2 mb-xl-3 display-28"><span
                                        class="display-26 text-secondary me-2 font-weight-600">PG Mail:</span>
                                    {{pgMail}}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>