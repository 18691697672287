<div class="con">
    
    <div class="loader-overlay" *ngIf="isLoading">
      <div class="loader-content">
        <mat-spinner diameter="50"></mat-spinner>
        <br>
        <h3>Please wait, we're fetching your account details..</h3>
      </div>
    </div>

    <div class="loader-overlay" *ngIf="isPDFLoading">
      <div class="loader-content">
        <mat-spinner diameter="50"></mat-spinner>
        <br>
        <h3>Please wait, we're preparing you're receipt document..</h3>
      </div>
    </div>

    <div class="loader-overlay" *ngIf="isFiltering">
      <div class="loader-content">
        <mat-spinner diameter="50"></mat-spinner>
        <br>
        <h3>Please wait, we're filtering customer details..</h3>
      </div>
    </div>

    <mat-card class="mat-card">
      <div class="search-and-date-container" *ngIf="!isLoading">
        <mat-form-field class="search-bar" appearance="outline">
          <mat-label>Search</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Customer Name" #input>
          <span matSuffix style="padding-right: 10px;">
            <mat-icon>search</mat-icon>
          </span>
        </mat-form-field>
      
        <mat-form-field class="date-range-field" appearance="outline">
          <mat-label>Select start & end date</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input matStartDate placeholder="Start date" [(ngModel)]="selectedStartDate" (ngModelChange)="onDateRangeChange()">
            <input matEndDate placeholder="End date" [(ngModel)]="selectedEndDate" (ngModelChange)="onDateRangeChange()">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
    </mat-card>

    <div class="spacer"></div>

     <!-- Card to display account summary data -->
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" class="hover-for-panel" [matTooltip]="'All Account Summary Data'" matTooltipPosition="below">
      <mat-expansion-panel-header>
        <mat-panel-title> Open to check account summary </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="summary-columns">
        <div class="summary-column">
          <div class="summary-row">
            <div class="summary-item" id="account-owner" [matTooltip]="'Account Owner'" matTooltipPosition="after" matTooltipShowDelay="500">
              <mat-icon id="owner-icon" color="primary">person</mat-icon>
              <p><b>Account Owner: </b><span class="owner-name" id="owner-name">Owner Name</span></p>
            </div>
            <div class="summary-item" id="total-balance" [matTooltip]="'Revenue ' + totalPaidAmount + '/-'" matTooltipPosition="after" matTooltipShowDelay="500">
              <mat-icon id="balance-icon" color="primary">account_balance</mat-icon>
              <p><b>Total Balance/Revenue: </b><span class="balance-amount">{{ totalPaidAmount | currency: 'INR' }}</span></p>
            </div>
            <div class="summary-item" id="last-update" [matTooltip]="'Last Seen ' + currentTime" matTooltipPosition="after" matTooltipShowDelay="500">
              <mat-icon id="update-icon" color="primary">schedule</mat-icon>
              <p><b>Last Seen: </b> <span class="update-time" id="update-time">{{ currentTime }}</span></p>
            </div>
          </div>
        </div>

        <div class="summary-column">
          <div class="summary-row">
            <div class="summary-item" id="account-status">
              <mat-icon id="active-icon" color="primary">check_circle</mat-icon>
              <p><b>Account Status: </b><span class="active-text" id="account-status">Active</span></p>
            </div>
            <div class="summary-item" id="transaction-count" [matTooltip]="numberOfTransactions + ' Transactions Found'" matTooltipPosition="after" matTooltipShowDelay="500">
              <mat-icon id="transaction-icon" color="primary">receipt</mat-icon>
              <p><b>Number of Transactions: </b><span class="transaction-count">{{ numberOfTransactions }}</span></p>
            </div>
            <div class="summary-item" id="most-used-upi" [matTooltip]="'Most Used Method ' + mostUsedUPI" matTooltipPosition="after" matTooltipShowDelay="500">
              <mat-icon id="upi-icon" color="primary">payment</mat-icon>
              <p><b>Most Used Methods: </b><span class="upi-id">{{ mostUsedUPI }}</span></p>
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>

    <!--*ngIf="!isLoading && !noDataAvailable"-->
    <div class="mat-elevation-z8 table-container">
      <table mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="serialNumber">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-align"> No. </th>
          <td mat-cell *matCellDef="let row" class="center-align">{{ getSerialNumber(row) }}.</td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-align"> Customer Name </th>
          <td mat-cell *matCellDef="let row" class="center-align">
            {{ (row.payeeName || ' ').toUpperCase() }}
          </td>
        </ng-container>

        <ng-container matColumnDef="transactionId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Transaction ID </th>
          <td mat-cell *matCellDef="let row">
            {{ (row.transactionNumber || ' ').toUpperCase() }}
          </td>
        </ng-container>

        <ng-container matColumnDef="paidAmount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Paid Amount </th>
          <td mat-cell *matCellDef="let row">
            <span class="amount">
              {{ row.paidAmount }} <i class="fas fa-rupee-sign"></i>
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="paidDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Paid Date </th>
          <td mat-cell *matCellDef="let row"> {{getFormattedDate(row.createdDate)}} </td>
        </ng-container>

        <ng-container matColumnDef="nextPayDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Next Payment</th>
          <td mat-cell *matCellDef="let row"> {{ getFormattedDate(row.nextPayment) }} </td>
        </ng-container>

        <!-- <ng-container matColumnDef="daysLeft"> 
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Days Left</th>
          <td mat-cell *matCellDef="let row"> {{ getFormattedDate(row.nextPayment) }} </td>
        </ng-container> -->

        <ng-container matColumnDef="daysLeft">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Days Left</th>
          <td mat-cell *matCellDef="let row" [ngStyle]="{ 'color': isTextRed(row.nextPayment) ? 'red' : 'green' }">
            {{ getDaysUntilNextPayment(row.nextPayment) }} Days
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
          <td mat-cell *matCellDef="let row">
            <ng-container *ngIf="row.paymentStatus == 1;">
              <span class="paid-amount">
                <i class="fas fa-check"></i> PAID
              </span>
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="upi">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Received On </th>
          <td mat-cell *matCellDef="let row"> {{row.receivedOn}} </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Actions </th>
          <td mat-cell *matCellDef="let row">
            <button mat-icon-button (click)="generatePDF(row.transactionNumber)" [matTooltip]="'Download Transaction Receipt'" matTooltipPosition="after" matTooltipShowDelay="500" class="hover-for-btn">
              <i class="fas fa-receipt"></i>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      </table>
      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>
    </div>

    <!-- if there is no data then show this message -->
    <div class="no-data-message" *ngIf="!isLoading && noDataAvailable">
      <p>There is No Data to be Shown!</p>
    </div>
    
</div>