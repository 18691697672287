<div class="sidenav" 
        [ngClass]="collapsed ? 'sidenav-collapsed' : '' " 
        (mouseenter)="toggleCollapse()" 
        (mouseleave)="closeSidenav()">
    
        <div class="logo-container">
        <button class="logo" (mouseenter)="toggleCollapse()">P</button>
        <div class="logo-text" *ngIf="collapsed">PGMaster</div>
        <button class="btn-close" *ngIf="collapsed" (mouseenter)="closeSidenav()">
            <i class="fal fa-times close-icon"></i>
        </button>
    </div>
    <div class="sidenav-content">
        <ul class="sidenav-nav">
            <li class="sidenav-nav-item" *ngFor="let data of navData">
                <a class="sidenav-nav-link" 
                    [routerLink]="[data.routeLink]" 
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}" 
                    [matTooltip]="data.label" matTooltipPosition="right">
                    
                    <i class="sidenav-nav-link-icon" [class]="data.icon"></i>
                    <span class="sidenav-link-text" *ngIf="collapsed"> {{data.label}} </span>
                </a>
            </li>
        </ul>
    </div>
</div>

<!-- <ul class="sidenav-nav">
        <li class="sidenav-nav-item" *ngFor="let data of navData">
            <a class="sidenav-nav-link" (click)="handleClick(data)"
                *ngIf="data.items && data.items.length > 0"
            >
                <i class="sidenav-nav-link-icon" [class]="data.icon"></i>
                <span class="sidenav-link-text" *ngIf="collapsed">{{data.label}}</span>
                <i *ngIf="data.items && collapsed" class="menu-collapse-icon"
                [ngClass]="!data.expanded ? 'fal fa-angle-right' : 'fal fa-angle-down'"
                ></i>
            </a>
            <a class="sidenav-nav-link" 
                *ngIf="!data.items || (data.items && data.items.length === 0)"
                [routerLink] = "[data.routeLink]"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}" >
                <i class="sidenav-nav-link-icon" [class]="data.icon"></i>
                <span class="sidenav-link-text" *ngIf="collapsed">
                    {{data.label}}
                </span>
            </a>
            <div *ngIf="collapsed && data.items && data.items.length > 0">
                <app-sublevel-menu
                    [data]="data"
                    [collapsed]="collapsed"
                    [multiple]="multiple"
                    [expanded]="data.expanded"
                ></app-sublevel-menu>
            </div>
        </li>
    </ul> -->