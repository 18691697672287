import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Employees } from 'src/app/models/users/Employees';
import { Apiservice } from 'src/app/service/api/apiservice.service';

@Component({
  selector: 'app-ac-employees',
  templateUrl: './ac-employees.component.html',
  styleUrls: ['./ac-employees.component.scss'],
  providers: [DatePipe],
})
export class AcEmployeesComponent implements OnInit, AfterViewInit {

  selectedStartDate: Date | null = null;
  selectedEndDate: Date | null = null;

  displayedColumns: string[] = [ 
    'serialNumber',
    'createdDate',
    'name',
    'mobile', 
    'mail',
    'address',
    'joinDate',
    'birth',
    'occupation'
  ]; 

  dataSource: MatTableDataSource<Employees>;
  isLoading: boolean = false;
  isFiltering: boolean = false;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(private apiService: Apiservice, private datePipe: DatePipe) {
    this.dataSource = new MatTableDataSource<Employees>([]);
  } 

  ngOnInit(): void {
    this.fetchData();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  fetchData() {
    this.isLoading = true;
    const minimumLoadingTime = 500;
  
    setTimeout(() => {
      this.apiService.fetchAllPGEmployees().subscribe(
        (response: any) => {
          console.log(response);
          const employees: Employees[] = response.recordsList;
          console.log(employees);
          this.dataSource.data = employees;
        },
        (error) => {
          console.error('Error fetching data', error);
        },
        () => {
          this.isLoading = false;
        }
      );
    }, minimumLoadingTime);
  }

  getSerialNumber(row: any): number {
    const index = this.dataSource.data.indexOf(row);
    return index + 1;
  }

  getFormattedDate(timestamp: number): string {
    const date = new Date(timestamp);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    return day + '/' + month + '/' + year;
  }

  formatDate(date: string | null): string {
    if (date) {
      const parsedDate = new Date(date);
      return this.datePipe.transform(parsedDate, 'dd-MM-yyyy') || '';
    } else {
      return '';
    }
  }  

  onDateRangeChange() {
    if (this.selectedStartDate !== null && this.selectedEndDate !== null) {
      this.isFiltering = true;
      const startDate = this.selectedStartDate;
      const endDate = this.selectedEndDate;
  
      const filteredData = this.dataSource.data.filter((customer) => {
        const createdDate = new Date(customer.createdDate);
        return createdDate >= startDate && createdDate <= endDate;
      });
  
      setTimeout(() => {
        this.dataSource.data = filteredData;
        this.isFiltering = false;
      }, 500);
    }
  }
}
