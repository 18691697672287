<div class="con">
  <div class="loader-overlay" *ngIf="isLoading">
    <div class="loader-content">
      <mat-spinner diameter="50"></mat-spinner>
      <br>
      <h3>Please wait, we're fetching customer details..
      </h3>
    </div>
  </div>
  
  <div class="row" *ngIf="customer" #contentToConvert>
    <div class="col-sm-12 d-flex justify-content-center">
      <div class="card col-10">
        <div class="card-body m-5">
          <h1 class="card-title d-flex justify-content-center">
           Detailed Application Form
          </h1>
          <div class="row">
            <div class="col mt-3">
              <input type="text" class="form-control" id="firstName" [(ngModel)]="customer.firstName"
                placeholder="First Name" aria-label="First Name">
            </div>
            <div class="col mt-3">
              <input type="text" class="form-control" id="lastName" [(ngModel)]="customer.lastName"
                placeholder="Last Name" aria-label="Last Name">
            </div>
          </div>
          
          <div class="mb-3 mt-3">
            <input type="text" class="form-control" id="inputAddress" [(ngModel)]="customer.dist  + ', ' + customer.state + ', ' + customer.pinCode">
          </div>

          <div class="mb-3">
            <div class="row">
              <div class="col-sm-7">
                <input type="text" class="form-control" id="email" [(ngModel)]="customer.mail" placeholder="Email" aria-label="Email">
              </div>
              <div class="col-sm-5">
                <input type="text" class="form-control" id="phoneNumber" [(ngModel)]="customer.phoneNumber" placeholder="Phone Number" aria-label="Phone Number">
              </div>
            </div>
          </div>

          <div class="mb-3">
            <div class="row">
              <div class="col-sm-7">
                <input type="text" class="form-control" id="roomNumber" [(ngModel)]="customer.roomNumber" placeholder="Room Number" aria-label="Room Number">
              </div>
              <div class="col-sm-5">
                <input type="text" class="form-control" id="createdDate" value="{{formatDate(customer.createdDate)}}" placeholder="Admission Date" aria-label="Admission Date">
              </div>
            </div>
          </div>

          <p class="text-center mt-5">
            <a class="btn btn-primary" (click)="generatePDF()">
              <i class="fas fa-download"></i> Download PDF
            </a>          
          </p>
        </div>
      </div>
    </div>
  </div>
  
</div>