import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Enquire } from 'src/app/models/enq/Enquire';
import { Apiservice } from 'src/app/service/api/apiservice.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})

export class ContactComponent implements OnInit {

  form: FormGroup | any;
  isModalOpen: boolean = false;

  ngOnInit() {
    this.isModalOpen = false;
  }

  constructor(
    private apiService: Apiservice,
    private router: Router,
    private toastr: ToastrService,
    private snack: MatSnackBar,
    private formBuilder: FormBuilder
  ) { }

  send(): void {
    const { name, email, message } = this.form.value;
    alert(`Name: ${name}, Email: ${email}, Message: ${message} `);
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      name: this.formBuilder.control(null),
      email: this.formBuilder.control(null),
      message: this.formBuilder.control(null),
    });
  }
  
  onSubmit(contactForm: NgForm) {
    if (contactForm.invalid) {
      this.openSnackBar('This all Fields are required to be filled', 'Close');
    } else {
      const { name, mail, phoneNumber, message } = contactForm.value;
      const enqRequest = new Enquire(name, mail, phoneNumber, message);
      this.apiService.enqurie(enqRequest)
        .subscribe({
          next: (response) => this.handleLoginSuccess(response),
          error: (error) => this.handleLoginError()
        });
    }
  }

  private handleLoginSuccess(response: any) {
    this.snack.open('Thank You for Your Interest, we will contact you soon', 'Close', {
      duration: 3000,
      panelClass: 'custom-snackbar'
    });
    this.router.navigate(['']);
  }

  private handleLoginError() {
    this.router.navigate(['/forbidden']);
  }

  private handleErrorDialog() {
    const snackBarRef = this.snack.open('This all Fields are required to be filled', 'Close');
    snackBarRef.onAction().subscribe(() => {
      snackBarRef.dismiss();
    });
  }

  // private handleErrorDialog() {
  //   this.snack.open('This all Fields are required to filled', 'Close', {
  //     duration: 1000
  //   });
  // }

  closeModal() {
    this.isModalOpen = false;
    this.router.navigate(['']);
  }

  openSnackBar(message: string, action: string) {
    this.snack.open(message, action);
  }
}
