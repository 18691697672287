import { Component } from '@angular/core';

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.scss']
})
export class TopnavComponent {
  showChildOptions: boolean = false;

  toggleChildOptions() {
    this.showChildOptions = !this.showChildOptions;
  }
}
