import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Apiservice } from '../service/api/apiservice.service';
import { Customer } from '../models/Customer';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-customer-form',
  templateUrl: './customer-form.component.html',
  styleUrls: ['./customer-form.component.scss'],
  providers: [DatePipe]
})
export class CustomerFormComponent implements OnInit {

  // @ViewChild('content') content: any;
  @ViewChild('contentToConvert', { static: true }) content: ElementRef | any;

  id: number = 0;
  customer: Customer | null = null;
  isLoading: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private apiService: Apiservice,
    private datePipe: DatePipe,
  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.route.params.subscribe(params => {
      this.id = +params['id'];
      this.apiService.fetchCustomerById(this.id).subscribe(
        (response: any) => {
          this.customer = response;
          this.isLoading = false;
        },
        error => {
          this.isLoading = false;
        }
      );
    });
  }

  fetchRoomNumber(customer: number) {
    this.apiService.fetchRoomNumberByID(customer).subscribe(
      (response: any) => {
        customer = response.roomNumber;
      },
      (error) => {
        console.error('Error fetching room number', error);
      }
    );
  } 

  generatePDF() {
    const doc = new jsPDF();

    doc.text('Hello', 10, 10);
    doc.save('test.pdf')
  }

  formatDate(date: string | null): string {
    if (date) {
      const parsedDate = new Date(date);
      return this.datePipe.transform(parsedDate, 'dd-MM-yyyy MM:SS a') || '';
    } else {
      return '';
    }
  }
}
