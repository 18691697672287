<app-topnav></app-topnav>
<div class="spacer" style="padding: 5px;"></div>

<div class="con">
  <div class="loader-overlay" *ngIf="isLoading">
    <div class="loader-content">
      <mat-spinner diameter="50"></mat-spinner>
      <br>
      <h3>Please wait, we're fetching all un-paid customer details..</h3>
    </div>
  </div>
</div>

<div class="registration-form">
  <form #signUpForm="ngForm" novalidate>  <!--  (ngSubmit)="onSubmit(signUpForm)"  -->

    <h2>PG Details</h2>
    <div class="row">
      <div class="col">
        <input type="text" ngModel required name="pgName" class="form-control item" id="pgName" placeholder="PG Name"
          [ngClass]="{'is-invalid': signUpForm.submitted && !signUpForm.controls['pgName'].valid}">
      </div>
      <div class="col">
        <input type="text" ngModel required name="userName" class="form-control item" id="userName" placeholder="PG Short Name"
          [ngClass]="{'is-invalid': signUpForm.submitted && !signUpForm.controls['userName'].valid}">
      </div>
    </div>

    <div class="row">
      <div class="col">
        <input type="number" ngModel required name="pgPhoneNumber" class="form-control item" id="pgPhoneNumber" placeholder="PG Phone Number"
          [ngClass]="{'is-invalid': signUpForm.submitted && !signUpForm.controls['pgPhoneNumber'].valid}">
      </div>
      <div class="col">
        <input type="email" ngModel required name="pgMail" class="form-control item" id="pgMail" placeholder="PG Mail"
          [ngClass]="{'is-invalid': signUpForm.submitted && !signUpForm.controls['pgMail'].valid}">
      </div>
    </div>

    <div class="row">
      <div class="col">
        <input type="text" ngModel required name="state" class="form-control item" id="state" placeholder="Select State"
          [ngClass]="{'is-invalid': signUpForm.submitted && !signUpForm.controls['state'].valid}">
      </div>
      <div class="col">
        <input type="text" ngModel required name="dist" class="form-control item" id="dist" placeholder="Select District"
          [ngClass]="{'is-invalid': signUpForm.submitted && !signUpForm.controls['dist'].valid}">
      </div>
    </div>

    <div class="row">
      <div class="col">
        <input type="number" ngModel required name="pinCode" class="form-control item" id="pinCode" placeholder="Enter Pin Code"
          [ngClass]="{'is-invalid': signUpForm.submitted && !signUpForm.controls['pinCode'].valid}">
      </div>
      <div class="col">
        <input type="text" ngModel required name="category" class="form-control item" id="category" placeholder="Select Category"
          [ngClass]="{'is-invalid': signUpForm.submitted && !signUpForm.controls['category'].valid}">
      </div>
    </div>

    <div class="row">
      <div class="col">
        <textarea type="text" ngModel required name="aboutPg" class="form-control item" id="aboutPg" placeholder="Tell Good About PG!"
          [ngClass]="{'is-invalid': signUpForm.submitted && !signUpForm.controls['aboutPg'].valid}"></textarea>
      </div>
    </div>

    <h2>Admin Details</h2>
    <div class="row">
      <div class="col">
        <input type="text" ngModel required name="firstName" class="form-control item" id="firstName" placeholder="First Name"
          [ngClass]="{'is-invalid': signUpForm.submitted && !signUpForm.controls['firstName'].valid}">
      </div>
      <div class="col">
        <input type="text" ngModel required name="lastName" class="form-control item" id="lastName" placeholder="Last Name"
          [ngClass]="{'is-invalid': signUpForm.submitted && !signUpForm.controls['lastName'].valid}">
      </div>
    </div>

    <div class="row">
      <div class="col">
        <input type="number" ngModel required name="phoneNumber" class="form-control item" id="phoneNumber" placeholder="Phone Number"
          [ngClass]="{'is-invalid': signUpForm.submitted && !signUpForm.controls['phoneNumber'].valid}">
      </div>
      <div class="col">
        <input type="email" ngModel required name="mail" class="form-control item" id="mail" placeholder="Enter Mail"
          [ngClass]="{'is-invalid': signUpForm.submitted && !signUpForm.controls['mail'].valid}">
      </div>
    </div>

    <div class="row">
      <div class="col">
        <input type="password" ngModel required name="password" class="form-control item" id="password" placeholder="Enter Password"
          [ngClass]="{'is-invalid': signUpForm.submitted && !signUpForm.controls['password'].valid}">
      </div>
      <div class="col">
        <input type="text" ngModel required name="profession" class="form-control item" id="profession" placeholder="Enter Profession">
      </div>
    </div>

    <div class="row">
      <div class="col">
        <input type="submit" (click)="checkFormValidity(signUpForm)" value="Sign Up" class="btn btn-outline-primary form-control">
      </div>
      <div class="col">
        <input type="button" value="Back to Login" routerLink="/login" class="btn btn-outline-secondary form-control">
      </div>
    </div>
    
  </form>
</div>

<!-- <app-cards></app-cards>
<app-footer></app-footer> -->