export class UpdateChargesOrPenalty {
    id: number;
    chargeName1: string;
    chargeAmount1: number;
    chargeName2: string;
    chargeAmount2: number;
    chargeName3: string;
    chargeAmount3: number;
    chargeName4: string;
    chargeAmount4: number;
    chargeName5: string;
    chargeAmount5: number;
    chargeName6: string;
    chargeAmount6: number;
    chargeName7: string;
    chargeAmount7: number;
  
    constructor(
      id: number,
      chargeName1: string,
      chargeAmount1: number,
      chargeName2: string,
      chargeAmount2: number,
      chargeName3: string,
      chargeAmount3: number,
      chargeName4: string,
      chargeAmount4: number,
      chargeName5: string,
      chargeAmount5: number,
      chargeName6: string,
      chargeAmount6: number,
      chargeName7: string,
      chargeAmount7: number
    ) {
      this.id = id;
      this.chargeName1 = chargeName1;
      this.chargeAmount1 = chargeAmount1;
      this.chargeName2 = chargeName2;
      this.chargeAmount2 = chargeAmount2;
      this.chargeName3 = chargeName3;
      this.chargeAmount3 = chargeAmount3;
      this.chargeName4 = chargeName4;
      this.chargeAmount4 = chargeAmount4;
      this.chargeName5 = chargeName5;
      this.chargeAmount5 = chargeAmount5;
      this.chargeName6 = chargeName6;
      this.chargeAmount6 = chargeAmount6;
      this.chargeName7 = chargeName7;
      this.chargeAmount7 = chargeAmount7;
    }
  }
  