export class AuthRequest {
    mail: string;
    password: string;
    pgName: string;
  
    constructor(mail: string, password: string, pgName: string) {
      this.mail = mail;
      this.password = password;
      this.pgName = pgName;
    }
}
  