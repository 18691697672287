<div class="con">

    <div class="loader-overlay" *ngIf="isLoading">
      <div class="loader-content">
        <mat-spinner diameter="50"></mat-spinner>
        <br>
        <h3>Please wait, we're fetching electicity details..</h3>
      </div>
    </div>

    <div class="loader-overlay" *ngIf="isPDFLoading">
      <div class="loader-content">
        <mat-spinner diameter="50"></mat-spinner>
        <br>
        <h3>Please wait, we're preparing you're electricity bill..</h3>
      </div>
    </div>

    <div class="spacer"></div>
  
    <mat-card class="mat-card">
      <div class="search-and-date-container" *ngIf="!isLoading">
        <mat-form-field class="search-bar" appearance="outline">
          <mat-label>Search</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Customer Name" #input>
          <span matSuffix style="padding-right: 10px;">
            <mat-icon>search</mat-icon>
          </span>
        </mat-form-field>
      
        <mat-form-field class="date-range-field" appearance="outline">
          <mat-label>Select start & end date</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input matStartDate placeholder="Start date" [(ngModel)]="selectedStartDate" (ngModelChange)="onDateRangeChange()">
            <input matEndDate placeholder="End date" [(ngModel)]="selectedEndDate" (ngModelChange)="onDateRangeChange()">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
    </mat-card>
  
    <div class="spacer"></div>

    <mat-expansion-panel *ngIf="shouldExpandPanel()">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Electricity Bill Data Review From {{formatDateDate(selectedStartDate) + ' To ' + formatDateDate(selectedEndDate)}}
      </mat-panel-title>
    </mat-expansion-panel-header>
        <div class="mat-elevation table-container exp-containt">
        <table mat-table [dataSource]="dataSource" matSort>
    
          <ng-container matColumnDef="serialNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-align"> No. </th>
            <td mat-cell *matCellDef="let row" class="center-align">{{ getSerialNumber(row) }}</td>
          </ng-container>
    
          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-align"> Date </th>
            <td mat-cell *matCellDef="let row" class="center-align">{{ formatDate(row.createdDate) }}</td>
          </ng-container>
    
          <ng-container matColumnDef="unitCurrentMonth">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-align"> Unit (Current) </th>
            <td mat-cell *matCellDef="let row" class="center-align">
              {{ (row.firstName + ' ' + row.lastName).toUpperCase() }}
            </td>
          </ng-container>
    
          <ng-container matColumnDef="unitPrevMonth">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Unit (Prev) </th>
            <td mat-cell *matCellDef="let row"> {{row.mail}} </td>
          </ng-container>
    
          <ng-container matColumnDef="unitDiff">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Unit Diff </th>
            <td mat-cell *matCellDef="let row"> {{row.phoneNumber}} </td>
          </ng-container>
    
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
        </table>
        <!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator> -->
        </div>

        <button mat-raised-button color="accent" (click)="generateElecBill(1)" *ngIf="shouldExpandPanel() && !noDataAvailable && !isLoading">Generate Bill</button>
    </mat-expansion-panel>
  
    <!-- if there is no data then show this message -->
    <div class="no-data-message" *ngIf="!isLoading && noDataAvailable">
      <p>There is No Data to be Shown!</p>
    </div>

    <div class="no-data-message" *ngIf="!shouldExpandPanel()">
        <p>Please Select Start Date and End Date to see the data!</p>
      </div>
</div>