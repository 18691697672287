import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})

export class NotificationsComponent implements OnInit {
  isLoading: boolean = true;
  
  constructor() {}
  
  ngOnInit(): void {
    this.notifications;
    setTimeout(() => {
      this.isLoading = false;
    }, 1000); 
  }

  notifications = [
    {
      title: 'Payment Received',
      message: 'Kiran Jagtap Paid Rent on 06-09-2023 from room_no',
      time: 'time_stamp',
      code: 1
    },
    {
      title: 'Payment Overdue',
      message: 'Prajval Bhale Rent is Overdue on 06-09-2023 from 5 Days, K-12',
      time: 'time_stamp',
      code: 2
    },
    {
      title: 'Payment in',
      message: 'ABC Have to Pay 6000 Rs. Rent in Next 7 Days on date from room_no',
      time: 'time_stamp',
      code: 3
    },
    {
      title: 'Customer Added',
      message: 'ABC DEF Get Admission in pg_name on date',
      time: 'time_stamp',
      code: 4
    },
    {
      title: 'Details Updated',
      message: 'XYZ data_exp is Updated on updated_date, room_no',
      time: 'time_stamp',
      code: 5
    },
    {
      title: 'Delete',
      message: 'PQR details are deleted on delete_date, room_no',
      time: 'time_stamp',
      code: 6
    },
    {
      title: 'Access Enabled',
      message: 'JKL is Enabled enabled_date, from room_no',
      time: 'time_stamp',
      code: 7
    },
    {
      title: 'Access Disabled',
      message: 'JKL is Disabled disabled_date, from room_no',
      time: 'time_stamp',
      code: 8
    },
    {
      title: 'Room Changed',
      message: 'JKL is Change Room from K-12 to K-13',
      time: 'time_stamp',
      code: 9
    },
    {
      title: 'Check Out',
      message: 'JKL Checked Out from room_no, on check_out_date_time',
      time: 'time_stamp',
      code: 10
    },
    {
      title: 'Assigned',
      message: 'ABC DEF assigned to room_number',
      time: 'time_stamp',
      code: 4
    },
    {
      title: 'Room Created',
      message: 'New Room Has Created with room_no on floor_no, in pg_name',
      time: 'time_stamp',
      code: 4
    }
  ];
  
  getBackgroundColorAndIcon(code: number): { backgroundColor: string, iconClass: string } {
    switch (code) {
      case 1:
      case 7:
        return { backgroundColor: '#90EE90', iconClass: 'fa-check-circle' };
      case 2:
        return { backgroundColor: '#FFA07A', iconClass: 'fa-exclamation-circle' };
      case 3:
        return { backgroundColor: '#F0D330', iconClass: 'fa-arrow-down' };
      case 4:
        return { backgroundColor: '#90EE90', iconClass: 'fa-plus' };
      case 5:
        return { backgroundColor: '#5CEC5C', iconClass: 'fa-pencil' };
      case 6:
        return { backgroundColor: '#FFA07A', iconClass: 'fa-trash' };
      case 8:
        return { backgroundColor: '#FFA07A', iconClass: 'fa-ban' };
      case 9:
        return { backgroundColor: '#5DC9EC', iconClass: 'fa-rotate-right' };
      case 10:
        return { backgroundColor: '#FFA07A', iconClass: 'fa-location-arrow' };
      default:
        return { backgroundColor: '#D3D3D3', iconClass: 'fa-info-circle' };
    }
  }  
}
