import { Component } from '@angular/core';

@Component({
  selector: 'app-access',
  templateUrl: './access.component.html',
  styleUrls: ['./access.component.scss']
})
export class AccessComponent {
  users = [
    { id: 1, name: 'User 1' },
    { id: 2, name: 'User 2' },
    { id: 3, name: 'User 3' },
    // Add more users as needed
  ];

  selectedUserId: number | null = null;

  accessControls = [
    { label: 'CREATE ACCESS', value: false },
    { label: 'UPDATE ACCESS', value: false },
    { label: 'DELETE ACCESS', value: false },
    { label: 'ENABLE ACCESS', value: false },
    { label: 'DISABLE ACCESS', value: false },
    { label: 'NONE ACCESS', value: false },
  ];

  submitForm() {  }
}
