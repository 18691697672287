import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
  animations: [
    trigger('scroll', [
      state('start', style({
        transform: 'translateX(0%)'
      })),
      state('end', style({
        transform: 'translateX(-50%)'
      })),
      transition('start <=> end', [
        animate('1s')
      ])
    ])
  ]
})

export class AboutComponent implements AfterViewInit {
  
  @ViewChild('container') container!: ElementRef;
  @ViewChild('scrollContainer') scrollContainer!: ElementRef;
  scrollState = 'start';

  ngAfterViewInit() {
    setInterval(() => {
      this.toggleScrollState();
    }, 5000);
  }

  toggleScrollState() {
    this.scrollState = this.scrollState === 'start' ? 'end' : 'start';
  }
}
