import { DatePipe } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Customer } from 'src/app/models/Customer';
import { Apiservice } from 'src/app/service/api/apiservice.service';

@Component({
  selector: 'app-allcustomers',
  templateUrl: './allcustomers.component.html',
  styleUrls: ['./allcustomers.component.scss'],
  providers: [DatePipe],
})

export class AllcustomersComponent {

  selectedStartDate: Date | null = null;
  selectedEndDate: Date | null = null;

  displayedColumns: string[] = [ 
    'serialNumber',
    'createdDate',
    'name', 
    'mail', 
    'phoneNumber',
    'address', 
    'room',
    'cardNumber',
    'paymentStatus',
    'enbDis' 
  ];

  dataSource: MatTableDataSource<Customer>;
  isLoading: boolean = false;
  isFiltering: boolean = false;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(private apiService: Apiservice, private datePipe: DatePipe) {
    this.dataSource = new MatTableDataSource<Customer>([]);
  } 

  ngOnInit(): void {
    this.fetchCustomers(); 
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  fetchCustomers() {
    this.isLoading = true;
    const minimumLoadingTime = 500; // 0.5 seconds
  
    setTimeout(() => {
      this.apiService.fetchUnpaidAllPGCustomer().subscribe(
        (response: any) => {
          const customers: Customer[] = response.recordsList;
          customers.forEach((customer) => {
            this.fetchRoomNumber(customer);
          });
          this.dataSource.data = customers;
        },
        (error) => {
          console.error('Error fetching data', error);
        },
        () => {
          this.isLoading = false; 
        }
      );
    }, minimumLoadingTime);
  }
   

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getSerialNumber(row: any): number {
    const index = this.dataSource.data.indexOf(row);
    return index + 1;
  }

  fetchRoomNumber(customer: Customer) {
    if (customer.assignedRoom === 0) {
      customer.roomNumber = 'Not Assigned';
    } else {
      this.apiService.fetchRoomNumberByID(customer.assignedRoom).subscribe(
        (response: any) => {
          customer.roomNumber = response.roomNumber;
        },
        (error) => {
          console.error('Error fetching room number', error);
        }
      );
    }
  }    

  formatDate(date: string | null): string {
    if (date) {
      const parsedDate = new Date(date);
      return this.datePipe.transform(parsedDate, 'dd-MM-yyyy') || '';
    } else {
      return '';
    }
  }  

  onDateRangeChange() {
    if (this.selectedStartDate !== null && this.selectedEndDate !== null) {
      this.isFiltering = true;
      const startDate = this.selectedStartDate;
      const endDate = this.selectedEndDate;
  
      const filteredData = this.dataSource.data.filter((customer) => {
        const createdDate = new Date(customer.createdDate);
        return createdDate >= startDate && createdDate <= endDate;
      });
  
      setTimeout(() => {
        this.dataSource.data = filteredData;
        this.isFiltering = false;
      }, 500);
    }
  } 
}
